import React, { useState, useEffect, useMemo } from "react";
import useAxios from "axios-hooks";

import { useErrorAlert } from "hooks";
import { FilterSupplierListItem } from "types/supplier";

import API from "constants/api";

import { FilterOptions } from "types/global";

export function useSupplierList() {
  const { setError } = useErrorAlert();

  const [{ loading: isLoading, error }, refetch] = useAxios(
    {
      url: API.SUPPLIERS,
      method: "POST",
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  return useMemo(
    () => ({
      isLoading,
      refetch: (payload: FilterOptions) => refetch({ data: payload }),
      error,
    }),
    [isLoading],
  );
}

export function useFilterSupplierOption<T>() {
  const { setError } = useErrorAlert();

  const [data, setData] = useState<FilterSupplierListItem>();

  const [{ data: fetchData, loading: isLoading, error }, refetch] = useAxios(
    {
      url: API.SUPPLIERS_FILTER_OPTIONS,
      method: "GET",
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (fetchData?.data) setData(fetchData?.data);
  }, [fetchData]);

  return useMemo(
    () => ({
      data,
      isLoading,
      refetch,
      error,
    }),
    [data, isLoading],
  );
}
