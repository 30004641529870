import { useEffect, useState } from "react";
import useDebounce from "./useDebounce";

export const useSearchBar = (search = "", delay = 0) => {
  const [keyword, setKeyword] = useState("");

  const delayValue = useDebounce(keyword, delay);

  const handleChangeSearch = (e: any) => {
    setKeyword(e.target.value);
  };

  const handleClearSearch = () => {
    setKeyword("");
  };

  useEffect(() => {
    setKeyword(search);
  }, []);

  return {
    keyword: delayValue,
    setKeyword,
    changeSearch: handleChangeSearch,
    clearSearch: handleClearSearch,
  };
};
