/* eslint-disable no-nested-ternary */
import React from "react";

import { Box, styled, useTheme } from "@mui/material";

import { BasicChip, BasicChipProps } from "components/core";

import { ChangeLogType } from "types";

interface ChangeLogTypeChipProps extends BasicChipProps {
  type: ChangeLogType;
}

export default function ChangeLogTypeChip({
  fontSize = "text",
  variant = "filled",
  type,
  ...rest
}: ChangeLogTypeChipProps) {
  const theme = useTheme();

  const handleStatusConfig = (
    changeLogType: ChangeLogType,
  ): {
    label: string;
    color: string;
    background: string;
  } => {
    const config = {
      label: "",
      color: "",
      background: "",
    };

    switch (changeLogType) {
      case ChangeLogType.Add:
        config.label = "Created";
        config.color = "#6936F8";
        config.background = "#F3EFFB";
        break;

      case ChangeLogType.Create:
        config.label = "Create";
        config.color = "#6936F8";
        config.background = "#F3EFFB";
        break;

      case ChangeLogType.Update:
      case ChangeLogType.Edit:
        config.label = "Edit";
        config.color = theme.palette.warning.main;
        config.background = theme.palette.warning.lighter as string;
        break;

      case ChangeLogType.Archive:
        config.label = "Archived";
        config.color = "#D42600";
        config.background = "#F9DFD9";
        break;

      default:
        break;
    }

    return config;
  };

  const { label, color, background } = handleStatusConfig(type);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <BasicChip
        {...rest}
        sx={{
          borderRadius: "4px",
          color,
          background,
        }}
        fontSize={fontSize}
        variant={variant}
        label={label}
      />
    </Box>
  );
}
