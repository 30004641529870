import useAxios from "axios-hooks";
import { useEffect, useMemo, useState } from "react";

import { AxiosRequestConfig } from "axios";
import { useErrorAlert, useSuccessAlert } from "hooks";

import API from "constants/api";
import {
  BUYER_ROLE_KEY,
  STORAGE_EMAIL_KEY,
  STORAGE_ROLE_KEY,
  STORAGE_TOKEN_KEY,
} from "constants/global";
import ROUTES from "constants/routes";
import { useAppError } from "state/application/hooks";
import { ResetPasswordValues, UserInfo } from "types";

function useFetcher<T>({
  method = "GET",
  url = "",
}: {
  method?: AxiosRequestConfig["method"];
  url?: string;
} = {}) {
  const { setError } = useErrorAlert();

  const [data, setData] = useState<T>();

  const [{ data: fetchData, loading: isLoading, error }, refetch] = useAxios(
    {
      url: `${API.USER}${url}`,
      method,
    },
    {
      manual: true,
    },
  );

  // useEffect(() => {
  //   if (error) {
  //     setError(error);
  //   }
  // }, [error]);

  useEffect(() => {
    if (fetchData?.data) setData(fetchData?.data);
  }, [fetchData]);

  return useMemo(
    () => ({
      data,
      isLoading,
      refetch,
      error,
    }),
    [url, data, isLoading],
  );
}

export function useAuth() {
  const logout = () => {
    localStorage.removeItem(BUYER_ROLE_KEY);
    localStorage.removeItem(STORAGE_EMAIL_KEY);

    localStorage.removeItem(STORAGE_ROLE_KEY);
    localStorage.removeItem(STORAGE_TOKEN_KEY);

    window.location.href = `${window.location.origin}${ROUTES.LOGIN}`;
  };

  const isAuthenticated = !!window.localStorage.getItem(STORAGE_TOKEN_KEY);

  return { isAuthenticated, logout };
}

export function useLogin<T>() {
  const { setError } = useErrorAlert();

  const { setSuccess } = useSuccessAlert();

  const [, setAppError] = useAppError();

  const [{ data, loading: isLoading, error }, refetch] = useAxios(
    {
      url: API.LOGIN,
      method: "POST",
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  useEffect(() => {
    if (data?.code !== 200) {
      setAppError({ code: data?.code, message: data?.message });

      return;
    }

    setSuccess("Login successfully.");
  }, [data]);

  return useMemo(
    () => ({
      isLoading,
      refetch: (payload: T) => refetch({ data: payload }),
      error,
    }),
    [isLoading],
  );
}

export function useForgotPassword<T>() {
  const { setError } = useErrorAlert();

  const { setSuccess } = useSuccessAlert();

  const [, setAppError] = useAppError();

  const [{ data, loading: isLoading, error }, refetch] = useAxios(
    {
      url: `${API.USER}/password_reset_request`,
      method: "POST",
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  useEffect(() => {
    if (data?.code !== 200) {
      setAppError({ code: data?.code, message: data?.message });

      return;
    }

    setSuccess("Sent successfully.");
  }, [data]);

  return useMemo(
    () => ({
      isLoading,
      refetch: (payload: T) => refetch({ data: payload }),
      error,
    }),
    [isLoading],
  );
}

export function useResetPassword<T = ResetPasswordValues>() {
  const { setError } = useErrorAlert();

  const { setSuccess } = useSuccessAlert();

  const [, setAppError] = useAppError();

  const [{ data, loading: isLoading, error }, refetch] = useAxios(
    {
      url: `${API.USER}/password_reset`,
      method: "POST",
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  useEffect(() => {
    if (data?.code !== 200) {
      setAppError({ code: data?.code, message: data?.message });

      return;
    }

    setSuccess("reset successfully.");
  }, [data]);

  return useMemo(
    () => ({
      isLoading,
      refetch: (payload: T) => refetch({ data: payload }),
      error,
    }),
    [isLoading],
  );
}

export function useUserInfo<T = UserInfo>() {
  return useFetcher<T>({
    method: "GET",
    url: "/user_info",
  });
}
