/* eslint-disable no-nested-ternary */
import React from "react";
import { styled, Box } from "@mui/material";
import { FactoryStatus } from "types/factory";
import { FACTORY_STATUS } from "constants/factory";

import { ActiveStatusDotSize, DotWrapper } from "components/core/ActiveStatusDot";

const StyledDot = styled(Box, {
  shouldForwardProp: (props) => props !== "status" && props !== "size",
})<{
  status: FactoryStatus | null;
  size: ActiveFactoryStatusDotProps["size"];
}>(({ theme, status, size }) => ({
  display: "inline-flex",
  width:
    size === "small"
      ? ActiveStatusDotSize.Small
      : size === "medium"
      ? ActiveStatusDotSize.Medium
      : ActiveStatusDotSize.Large,
  height:
    size === "small"
      ? ActiveStatusDotSize.Small
      : size === "medium"
      ? ActiveStatusDotSize.Medium
      : ActiveStatusDotSize.Large,
  borderRadius: "100%",
  backgroundColor: [
    FactoryStatus.All,
    FactoryStatus.Active,
    FactoryStatus.ActiveWithinTwoMonths,
  ].includes(status as FactoryStatus)
    ? theme.palette.success.main
    : theme.palette.error.main,
}));

export interface ActiveFactoryStatusDotProps {
  size?: "small" | "medium" | "large";
  text?: boolean;
  status: FactoryStatus | null;
}

export default function ActiveFactoryStatusDot({
  size = "medium",
  status,
  text,
  ...rest
}: ActiveFactoryStatusDotProps) {
  return (
    <DotWrapper>
      <StyledDot size={size} status={status} {...rest} />

      {text && status && <Box sx={{ marginLeft: 1 }}>{FACTORY_STATUS[status]}</Box>}
    </DotWrapper>
  );
}
