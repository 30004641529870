/* eslint-disable no-nested-ternary */
import React from "react";

import { Box, styled } from "@mui/material";

import AutoStoriesRoundedIcon from "@mui/icons-material/AutoStoriesRounded";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import Grid3x3RoundedIcon from "@mui/icons-material/Grid3x3Rounded";

import { BasicChip, BasicChipProps } from "components/core";

interface ChangeRequestTypeChipProps extends BasicChipProps {
  type: string;
  iconDisabled?: boolean;
}

export default function ChangeRequestTypeChip({
  fontSize = "text",
  variant = "filled",
  iconDisabled = false,
  type,
  ...rest
}: ChangeRequestTypeChipProps) {
  const handleChipConfig = (selected: string) => {
    const config: {
      label: string;
      icon: React.ReactElement | null;
    } = {
      label: "",
      icon: null,
    };

    switch (selected) {
      case "test_item":
        config.label = "Test Item";
        config.icon = <AutoStoriesRoundedIcon sx={{ fontSize: "12px" }} />;
        break;
      case "test_matrix":
        config.label = "Test Matrix";
        config.icon = <LocalOfferRoundedIcon sx={{ fontSize: "12px" }} />;
        break;
      case "brand":
        config.label = "Brand";
        config.icon = <Grid3x3RoundedIcon sx={{ fontSize: "12px" }} />;
        break;
      case "sub_category":
        config.label = "Category";
        config.icon = <Grid3x3RoundedIcon sx={{ fontSize: "12px" }} />;
        break;

      default:
        break;
    }

    return config;
  };

  const { label, icon } = handleChipConfig(type);

  const iconLabel = (
    <Box
      sx={{ display: "flex", flexFlow: "row", justifyContent: "flex-start", alignItems: "center" }}
    >
      {!iconDisabled && (
        <>
          {icon}
          &nbsp;
        </>
      )}

      {label}
    </Box>
  );

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <BasicChip
        {...rest}
        sx={(theme) => ({
          borderRadius: "4px",
          color: theme.palette.common.black,
          background: theme.palette.secondary.lighter,
        })}
        fontSize={fontSize}
        variant={variant}
        label={iconLabel}
      />
    </Box>
  );
}
