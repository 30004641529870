export const downloadFile = (fileData: any, fileName: string) => {
  // other browsers
  const blob: Blob = new File([fileData], fileName);
  // const file = new Blob(fileData);
  // const a: HTMLAnchorElement = document.createElement("a") as HTMLAnchorElement;
  // const blobUrl: string = URL.createObjectURL(blob);
  //
  // a.href = blobUrl;
  // a.download = fileName;
  // document.body.appendChild(a);
  // a.click();

  const fileURL = window.URL.createObjectURL(blob);
  // Setting various property values
  const alink = document.createElement("a");
  alink.href = fileURL;
  alink.download = fileName;
  document.body.appendChild(alink);
  alink.click();
  document.body.removeChild(alink);
};

export const downloadFileV2 = (signedPath: string, fileName: string) => {
  // Setting various property values
  const alink = document.createElement("a");
  alink.href = signedPath;
  alink.target = "_blank";
  alink.download = fileName;
  document.body.appendChild(alink);
  alink.click();
  document.body.removeChild(alink);
};

export const downloadExcelFile = (fileData: any, fileName: string) => {
  // other browsers
  const blob: Blob = new File([fileData], fileName, {
    type: "application/vnd.ms-excel;charset=gb2312",
  });
  const a: HTMLAnchorElement = document.createElement("a") as HTMLAnchorElement;
  const blobUrl: string = URL.createObjectURL(blob);

  a.href = blobUrl;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
};

export const getFileUrl = (fileData: any, fileName = "Untitled") => {
  let blob = null;

  // other browsers
  // blob = new Blob([data], { type: 'application/octet-stream' });
  blob = new File([fileData], fileName, { type: "application/json" });

  const url = URL.createObjectURL(blob);
  return url;
};

export const getFileNameAndExtension = (fileName: string): [string, string] => {
  const lastDot = fileName.lastIndexOf(".");
  const name = fileName.substring(0, lastDot);
  const extension = fileName.substring(lastDot + 1);

  return [name, extension];
};

export const getFileNameDisplay = (fileName = "", size = 20): string => {
  if (!fileName) return "";

  const [name, extension] = getFileNameAndExtension(fileName);

  let result = fileName;

  if (name.length > size) {
    const middle = Math.floor(size / 2);
    result = `${name.substring(0, middle)}...${name.substring(
      name.length - middle,
      name.length,
    )}.${extension}`;
  }

  return result;
};
