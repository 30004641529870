import { createReducer } from "@reduxjs/toolkit";
import { ExportReportingState } from "types/export-reporting";
import { setAppliedTemplate, setLoadingSplash } from "./actions";

export const initialState: ExportReportingState = {
  appliedTemplate: {
    type: "",
    templateId: null,
    templateName: "",
    userTemplateId: null,
    name: "",
    customized: false,
    saved: false,
    attributes: [],
  },
  loadingSplash: {
    loading: false,
    title: "",
    message: "",
    showSuccess: false,
  },
};

export default createReducer(initialState, (builder) => {
  builder.addCase(setAppliedTemplate, (state, action) => ({
    ...state,
    appliedTemplate: {
      ...state.appliedTemplate,
      ...action?.payload,
    },
  }));

  builder.addCase(setLoadingSplash, (state, action) => ({
    ...state,
    loadingSplash: {
      ...state.loadingSplash,
      ...action?.payload,
    },
  }));
});
