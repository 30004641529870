import { useState } from "react";

export default function useToggle(defaultOpen = false): [open: boolean, toggleOpen: () => void] {
  const [open, setOpen] = useState(defaultOpen);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return [open, toggleOpen];
}
