import { styled } from "@mui/material/styles";
import React from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";

import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";

import IcwLogo from "assets/images/icw_logo_white.svg";

import { NavPageOption } from "types/global";

export const NavHeaderAppBar = styled(AppBar)({
  height: "50px",
});

export const NavHeaderToolbar = styled(Toolbar)({
  height: "50px",
  maxHeight: "50px",
  minHeight: "inherit !important",
});

export const NavLogoLink = styled(Link)({
  maxWidth: "48px",
  maxHeight: "32px",
  width: "100%",
  height: "auto",
  display: "flex",
  justifyContent: "center",
});

export const NavDesktopLink = styled(Link, {
  shouldForwardProp: (props) => props !== "active",
})<{
  active: boolean;
}>(({ theme, active }) => ({
  cursor: "pointer",
  fontSize: "0.75rem",
  padding: "15px 32px",
  color: "inherit",
  textDecoration: "none",

  "&:hover": {
    textDecoration: "none",
    backgroundColor: theme.palette.primary.dark,
  },

  borderBottom: active ? `3px solid ${theme.palette.primary.lighter}` : "0",
  backgroundColor: active ? `${theme.palette.primary.dark} !important` : "inherit",
}));

export interface NavHeaderProps {
  pages: NavPageOption[];
}

export default function NavHeader({ pages = [] }: NavHeaderProps) {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);

  const isActivePage = (route: string) => window.location.pathname === route;

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const profileMenuId = "profile-menu";

  const profileMenu = [
    {
      name: "QESD Team",
      onClick: handleMenuClose,
    },
    {
      name: "QA/QC Team",
      onClick: handleMenuClose,
    },
    {
      name: "Logout",
      onClick: handleMenuClose,
    },
  ];

  const renderProfileMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={profileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {profileMenu?.map((menuItem, index) => (
        <MenuItem key={index} onClick={menuItem.onClick}>
          {menuItem.name}
        </MenuItem>
      ))}
    </Menu>
  );

  const menuId = "app-menu";

  const renderMenu = (
    <Menu
      id={menuId}
      anchorEl={anchorElNav}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={Boolean(anchorElNav)}
      onClose={handleCloseNavMenu}
      sx={{
        display: { xs: "block", md: "none" },
      }}
    >
      {pages?.map((page) => (
        <MenuItem key={page.name} onClick={handleCloseNavMenu}>
          <Link href={page.href}>{page.name}</Link>
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <NavHeaderAppBar position="static">
        <NavHeaderToolbar>
          <NavLogoLink
            sx={{
              order: {
                xs: 1,
                md: 0,
              },
              flexGrow: 1,
            }}
          >
            <img src={IcwLogo} alt="ICW Logo" />
          </NavLogoLink>

          <Box
            sx={{
              order: 0,
              display: { xs: "flex", md: "none" },
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Box sx={{ ml: "24px", flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages?.map((page) => (
              <NavDesktopLink key={page.name} href={page.href} active={isActivePage(page.href)}>
                {page.name}
              </NavDesktopLink>
            ))}
          </Box>

          <Box sx={{ order: 1, flexGrow: 1 }} />

          <Box
            sx={{
              order: 2,
            }}
          >
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={profileMenuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
        </NavHeaderToolbar>
      </NavHeaderAppBar>

      {renderMenu}
      {renderProfileMenu}
    </Box>
  );
}
