import { useEffect, useMemo, useState } from "react";
import { AxiosRequestConfig } from "axios";
import useAxios from "axios-hooks";
import { ColumnView } from "types/global";

import API from "constants/api";
import { TableListType } from "components/core";

import { useErrorAlert, useSuccessAlert } from "./useAlert";

function useHeaderList<T>({
  method = "GET",
  api = API.USER,
  id = "",
  url = "",
}: {
  method?: AxiosRequestConfig["method"];
  api?: API;
  id?: string;
  url?: string;
}) {
  const { setError } = useErrorAlert();

  const PATH = api;

  const [data, setData] = useState<T>();

  const [{ data: fetchData, loading: isLoading, error }, refetch] = useAxios(
    {
      url: !id && !url ? PATH : `${PATH}/${id}${url}`,
      method,
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  useEffect(() => {
    if (fetchData?.data) setData(fetchData?.data);
  }, [fetchData]);

  return useMemo(
    () => ({
      data: data as T,
      isLoading,
      refetch,
      error,
    }),
    [id, url, data, isLoading],
  );
}

/**
 * hepler functions
 */
function useCustomerHeader<Req, Res = ColumnView>({
  method = "POST",
  api = API.USER,
  id = "",
  url = "",
  successAlertEnabled = false,
  successMessage = "Submitted successfully.",
}: {
  method?: AxiosRequestConfig["method"];
  api?: API;
  id?: string;
  url?: string;
  successAlertEnabled?: boolean;
  successMessage?: string;
}) {
  const { setError, setAppError } = useErrorAlert();
  const { setSuccess } = useSuccessAlert();

  const PATH = api;

  const [data, setData] = useState<Res>();

  const [{ data: fetchData, loading: isLoading, error }, refetch] = useAxios(
    {
      url: !id && !url ? PATH : `${PATH}/${id}${url}`,
      method,
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  useEffect(() => {
    if (fetchData?.code !== 200) {
      setAppError({
        code: fetchData?.code,
        message: fetchData?.message,
      });
    }

    if (successAlertEnabled && fetchData?.code === 200) setSuccess(successMessage);

    if (fetchData?.data) setData(fetchData?.data);
  }, [fetchData]);

  return useMemo(
    () => ({
      data,
      isLoading,
      refetch: (payload: Req) => refetch({ data: payload }),
      error,
    }),
    [api, id, url, successAlertEnabled, successMessage, data, isLoading],
  );
}

export function useGetCurrentCustomerHeaders(tableType: string) {
  return useCustomerHeader({
    url: `custom_header/${tableType}/current`,
    method: "GET",
  });
}

export function useGetCustomerHeadersById<T>(headId: string) {
  return useCustomerHeader<T>({
    url: `custom_header/${headId}`,
    method: "GET",
  });
}

export function useGetCustomerHeaders<T = ColumnView[]>(tableType: TableListType) {
  const type = tableType === "all_skus" ? "allSku" : "newSku";

  return useHeaderList<T>({
    method: "GET",
    url: `custom_header/list/${type}`,
  });
}

export function useCreateCustomerHeader<T>() {
  return useCustomerHeader<T>({
    method: "POST",
    url: "custom_header",
    successAlertEnabled: true,
    successMessage: "Update Table Header Successfully.",
  });
}

export function useUpdateCustomerHeaders<T>() {
  return useCustomerHeader<T>({
    url: "custom_header",
    method: "PUT",
    successAlertEnabled: true,
    successMessage: "Update Table Header Successfully.",
  });
}

export function useDeleteCustomerHeaders<T>() {
  return useCustomerHeader<T>({
    url: "custom_header",
    method: "DELETE",
    successAlertEnabled: true,
    successMessage: "Delete Table Header Successfully.",
  });
}
