import React, { useState, useEffect } from "react";
import { AxiosError } from "axios";

import { useAppError, useAppSuccess } from "state/application/hooks";

import { useAuth } from "hooks";
import ROUTES from "constants/routes";

export function useErrorAlert() {
  const { logout } = useAuth();

  //global state hook for app errors
  const [appError, setAppError] = useAppError();

  const [errorAlert, setErrorAlert] = useState<AxiosError | null>(null);

  useEffect(() => {
    const isLoginPage = window.location.pathname.includes(ROUTES.LOGIN);

    if (errorAlert?.response?.data?.message) {
      if (!isLoginPage && errorAlert?.response?.data?.code === 403) {
        logout();
      }

      setAppError({
        code: errorAlert?.response?.data?.code,
        message: `${errorAlert?.response?.data?.message}`,
      });
    } else {
      // logout if got invalid token
      if (!isLoginPage && errorAlert?.response?.status === 403) {
        logout();
      }

      setAppError({
        code: errorAlert?.response?.status,
        message: errorAlert?.message as string,
      });
    }
  }, [errorAlert]);

  const setError = (error: any | null) => {
    setErrorAlert(error);
  };

  const resetError = () => {
    const initErrorState = { code: 0, message: "" };
    setAppError(initErrorState);
  };

  return { error: appError, setError, setAppError, resetError };
}

export function useSuccessAlert() {
  //global state hook for app success
  const [appSuccess, setAppSuccess] = useAppSuccess();

  const setSuccess = (message: string) => {
    setAppSuccess({ message });
  };

  const resetSuccess = () => {
    setAppSuccess({ message: "" });
  };

  return { success: appSuccess, setSuccess, resetSuccess };
}
