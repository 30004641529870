import React, { memo } from "react";
import {
  createFilterOptions,
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
  TextField,
  TextFieldProps,
  FormHelperText,
  CircularProgress,
} from "@mui/material";

import { AutocompleteOption, AutocompleteWrapper } from "components/core/Input";

export type CreatableAutocompleteProps = Omit<
  MuiAutocompleteProps<any, false, false, false>,
  "renderInput"
>;

export interface AutocompleteCreatableProps extends CreatableAutocompleteProps {
  helperText?: React.ReactNode | string | false;
  loading?: boolean;
  error?: boolean;
  label?: TextFieldProps["label"];
  options: AutocompleteOption[];
  value: AutocompleteOption | null;
  onChange: (event: any, optionValue: AutocompleteOption | null) => void;
}

function AutocompleteCreatable({
  helperText,
  placeholder,
  loading = false,
  error = false,
  options = [],
  value,
  label = "",
  onChange,
  fullWidth = false,
  ...rest
}: AutocompleteCreatableProps) {
  const filter = createFilterOptions<AutocompleteOption>();

  return (
    <AutocompleteWrapper fullWidth={fullWidth} error={error}>
      <MuiAutocomplete
        {...rest}
        // freeSolo
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="free-solo-with-text"
        value={value}
        onChange={(event, newValue: AutocompleteOption | null) => {
          if (typeof newValue === "string") {
            onChange(event, {
              label: newValue,
              value: newValue,
            });
          } else if (newValue && newValue.inputValue) {
            onChange(event, {
              label: newValue.inputValue,
              value: newValue.inputValue,
              isNewValue: true,
            });
          } else {
            onChange(event, newValue);
          }
        }}
        filterOptions={(filterOptions, params) => {
          const filtered = filter(filterOptions, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = filterOptions?.some((option) => inputValue === option.label);

          if (inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue,
              label: `Add "${inputValue}"`,
            });
          }

          return filtered;
        }}
        options={options}
        getOptionDisabled={(option) => option?.disabled}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.label;
        }}
        renderOption={(props, option) => <li {...props}>{option.label}</li>}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            label={label}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </AutocompleteWrapper>
  );
}

export default memo(AutocompleteCreatable);
