/* eslint-disable no-nested-ternary */
import React from "react";

import { Box, BoxProps, styled } from "@mui/material";
import { BasicChipProps } from "components/core";

import { NOT_APPLICABLE } from "constants/global";
import { TASK_STATUS } from "constants/item";
import { TaskStatus } from "types/task";

enum StatusDotSize {
  SemiSmall = "4px",
  Small = "8px",
  SemiMedium = "5px",
  Medium = "10px",
  SemiLarge = "6px",
  Large = "12px",
}

const ActiveDot = styled(Box, {
  shouldForwardProp: (props) => props !== "active" && props !== "size",
})<{
  active: boolean;
  size?: "info" | "text";
}>(({ theme, active, size }) => ({
  width:
    size === "info"
      ? StatusDotSize.Small
      : size === "text"
      ? StatusDotSize.Medium
      : StatusDotSize.Large,
  height:
    size === "info"
      ? StatusDotSize.Small
      : size === "text"
      ? StatusDotSize.Medium
      : StatusDotSize.Large,
  borderRadius: "100%",
  backgroundColor: active ? theme.palette.success.main : theme.palette.info.main,
}));

export interface ActiveStatusChipProps extends BoxProps {
  size?: "info" | "text";
  active: boolean;
}

export default function ActiveStatusChip({
  size = "text",
  active,
  ...rest
}: ActiveStatusChipProps) {
  const handleStatusConfig = (
    taskStatus: TaskStatus,
  ): {
    label: (typeof TASK_STATUS)[TaskStatus] | "";
    color: BasicChipProps["color"];
  } => {
    const config: { label: (typeof TASK_STATUS)[TaskStatus] | ""; color: BasicChipProps["color"] } =
      {
        label: "",
        color: "default",
      };

    switch (taskStatus) {
      case TaskStatus.EmptyTask:
        config.label = TASK_STATUS.empty_task;
        config.color = "secondary";
        break;

      case TaskStatus.NotStarted:
        config.label = TASK_STATUS.not_started;
        config.color = "secondary";
        break;

      case TaskStatus.NotOpenForQuote:
        config.label = TASK_STATUS.not_open_for_quote;
        config.color = "info";
        break;

      case TaskStatus.AuditInProgress:
        config.label = TASK_STATUS.audit_in_progress;
        config.color = "warning";
        break;

      case TaskStatus.Fail:
        config.label = TASK_STATUS.fail;
        config.color = "error";
        break;

      case TaskStatus.Cancelled:
        config.label = TASK_STATUS.cancelled;
        config.color = "error";
        break;

      case TaskStatus.Completed:
        config.label = TASK_STATUS.completed;
        config.color = "success";
        break;

      case TaskStatus.NotApplicable:
        config.label = TASK_STATUS.notApplicable;
        config.color = "secondary";
        break;

      default:
        config.label = NOT_APPLICABLE;
        config.color = "secondary";
        break;
    }

    return config;
  };

  return <ActiveDot {...rest} size={size} active={active} />;
}
