import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";

import { Autocomplete, AutocompleteProps, AutocompleteOption } from "components/core/Input";

import { formatDate, textOrUnknown } from "utils";

import { getAutocompleteValue } from "utils/input";

import { TextProps, EditInput } from "components/core";

export interface EditAutocompleteProps
  extends Omit<AutocompleteProps, "value" | "onSubmit" | "onChange"> {
  loading?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  textProps?: Omit<TextProps, "children">;
  value: string;
  onSubmit: (selected: string) => void;
}

export default function EditAutocomplete({
  readOnly = false,
  disabled = false,
  loading = false,
  textProps,
  options = [],
  value,
  onSubmit,
  ...rest
}: EditAutocompleteProps) {
  const [inputValue, setInputValue] = useState(value);

  const handleClose = () => {
    setInputValue(value);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    option: AutocompleteOption | null,
  ) => {
    setInputValue((option?.value as string) || "");
  };

  const handleSubmit = () => {
    onSubmit(inputValue);

    handleClose();
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <EditInput
      disabled={disabled}
      readOnly={readOnly}
      textProps={textProps}
      value={textOrUnknown(getAutocompleteValue(inputValue, options)?.label) as string}
      onSubmit={handleSubmit}
      onClose={handleClose}
      inputComponent={
        <Autocomplete
          {...rest}
          options={options}
          value={getAutocompleteValue(inputValue, options)}
          disabled={loading}
          onChange={handleChange}
        />
      }
    />
  );
}
