import React from "react";

import { BasicChip, BasicChipProps } from "components/core";

export default function PrimeSkuChip({
  label = "PRIME",
  fontSize = "text",
  ...rest
}: BasicChipProps) {
  return (
    <BasicChip
      {...rest}
      sx={{
        fontWeight: "500",
        width: "max-content",
      }}
      color="success"
      dense
      square
      label={label}
      fontSize={fontSize}
    />
  );
}
