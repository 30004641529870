import Checkbox from "@mui/material/Checkbox";
import React, { forwardRef, useEffect, useRef } from "react";

interface IndeterminateCheckboxProps {
  indeterminate?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const useCombinedRefs = (...refs: any): React.MutableRefObject<any> => {
  const targetRef = useRef();

  useEffect(() => {
    refs.forEach((ref: any) => {
      if (!ref) return;

      if (typeof ref === "function") {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
};

const IndeterminateCheckbox = forwardRef<HTMLInputElement, IndeterminateCheckboxProps>(
  ({ indeterminate, disabled, onChange, ...rest }, ref: React.Ref<HTMLInputElement>) => {
    const defaultRef = useRef(null);
    const combinedRef = useCombinedRefs(ref, defaultRef);

    useEffect(() => {
      if (combinedRef?.current) {
        combinedRef.current.indeterminate = indeterminate ?? false;
      }
    }, [combinedRef, indeterminate]);

    return (
      <Checkbox
        ref={combinedRef}
        sx={{ color: "#979797", padding: "12px" }}
        inputProps={{
          "aria-label": "select cell",
        }}
        disabled={disabled}
        onClick={(event) => {
          event.stopPropagation();
        }}
        onChange={onChange}
        {...rest}
      />
    );
  },
);

export default IndeterminateCheckbox;
