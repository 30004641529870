import { createAction } from "@reduxjs/toolkit";
import { ExportReportingState } from "types/export-reporting";

export const setAppliedTemplate = createAction<ExportReportingState["appliedTemplate"]>(
  "exportReporting/appliedTemplate",
);

export const setLoadingSplash = createAction<ExportReportingState["loadingSplash"]>(
  "exportReporting/loadingSplash",
);
