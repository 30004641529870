import React from "react";
import {
  Select as MuiSelect,
  SelectProps,
  MenuItem,
  OutlinedInput,
  TextFieldProps,
  FormHelperText,
  SelectChangeEvent,
  InputLabel,
} from "@mui/material";

import { textOrUnknown } from "utils";

export interface BasicSelectOption {
  label: string;
  value: string;
  disabled?: boolean;
}

export interface BasicSelectProps
  extends Omit<SelectProps, "onChange" | "children" | "defaultValue"> {
  className?: string;
  fullWidth?: TextFieldProps["fullWidth"];
  error?: TextFieldProps["error"];
  helperText?: TextFieldProps["helperText"];
  disabled?: boolean;
  placeholder?: string;
  label?: string;
  name: string;
  value: string;
  options: BasicSelectOption[];
  onChange: (optionValue: string) => void;
}

export default function BasicSelect({
  className = "",
  fullWidth = false,
  error = false,
  helperText,
  placeholder,
  label,
  name,
  value,
  disabled = false,
  options = [],
  onChange,
  ...rest
}: BasicSelectProps) {
  const getSelectedOption = (val: string | null) =>
    options?.filter((item) => item?.value === val)[0] || null;

  return (
    <>
      <InputLabel sx={{ marginBottom: "8px", fontSize: "12px" }}>{label}</InputLabel>

      <MuiSelect
        sx={{
          "& [role='button']": {
            padding: "10px",
          },
        }}
        {...rest}
        displayEmpty
        className={className}
        disabled={disabled}
        value={value}
        onChange={(event: SelectChangeEvent) => {
          // value format is option label
          const { value: selectedLabel } = event.target;

          // get selected value option itself & pass to onChange
          const selectedOption = getSelectedOption(selectedLabel);

          // return string value to onChange
          onChange(selectedOption?.value.toString());
        }}
        input={<OutlinedInput fullWidth={fullWidth} error={error} name={name} />}
        renderValue={(renderValue) => {
          // if (!value) {
          //   return placeholder;
          // }

          const selectedOption = getSelectedOption(renderValue || value);

          return selectedOption?.label;
        }}
      >
        {options?.map((option) => {
          return (
            <MenuItem key={option?.value} disabled={option?.disabled} value={option?.value}>
              {textOrUnknown(option?.label)}
            </MenuItem>
          );
        })}
      </MuiSelect>

      {helperText && (
        <FormHelperText
          sx={(theme) => ({
            color: theme.palette.error.main,
          })}
        >
          {helperText}
        </FormHelperText>
      )}
    </>
  );
}
