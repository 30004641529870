import React from "react";
import { styled } from "@mui/material/styles";

import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from "@mui/material";

const PointerTooltip = styled(MuiTooltip)({
  cursor: "pointer",
});
export interface TooltipProps extends MuiTooltipProps {
  arrow?: MuiTooltipProps["arrow"];
  title: MuiTooltipProps["title"];
}

export default function BasicTooltip({ arrow = true, title, children, ...rest }: TooltipProps) {
  return (
    <PointerTooltip {...rest} title={title} arrow={arrow}>
      <span>{children}</span>
    </PointerTooltip>
  );
}
