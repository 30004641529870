import React, { useState, useEffect, useMemo } from "react";
import useAxios from "axios-hooks";
import { AxiosRequestConfig } from "axios";

import { useErrorAlert, useSuccessAlert } from "hooks";

import { Lab, LabUploadedReport } from "types/labs";

import API from "constants/api";
import { LAB } from "constants/global";
import { ReportResult } from "types/task";
import { TestLevel } from "types/test-item";

function useFetchLab<T>({
  method = "GET",
  api = API.LABS,
  url = "",
}: {
  method?: AxiosRequestConfig["method"];
  api?: API;
  url?: string;
} = {}) {
  const { setError } = useErrorAlert();

  const [data, setData] = useState<T>();

  const [{ data: fetchData, loading: isLoading, error }, refetch] = useAxios(
    {
      url: `${api}${url}`,
      method,
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) {
      setError(error);
    }
  }, [error]);

  useEffect(() => {
    if (fetchData?.data) setData(fetchData?.data);
  }, [fetchData]);

  return useMemo(
    () => ({
      data,
      isLoading,
      refetch,
      error,
    }),
    [url, data, isLoading],
  );
}

function useUpdateLab<Req, Res = { id?: string }>({
  method = "POST",
  api,
  id = "",
  url = "",
  successAlertEnabled = false,
  successMessage = "Submitted successfully.",
}: {
  method?: AxiosRequestConfig["method"];
  api?: API;
  id?: string;
  url?: string;
  successAlertEnabled?: boolean;
  successMessage?: string;
}) {
  const { setError, setAppError } = useErrorAlert();
  const { setSuccess } = useSuccessAlert();

  const [data, setData] = useState<Res>();

  const PATH = `/${LAB}${api}`;

  const [{ data: fetchData, loading: isLoading, error }, refetch] = useAxios(
    {
      url: !id && !url ? PATH : `${PATH}/${id}${url}`,
      method,
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  useEffect(() => {
    if (fetchData?.code !== 200) {
      setAppError({
        code: fetchData?.code,
        message: fetchData?.message,
      });
    }

    if (successAlertEnabled && fetchData?.code === 200) setSuccess(successMessage);

    if (fetchData?.data) setData(fetchData?.data);
  }, [fetchData]);

  return useMemo(
    () => ({
      data,
      isLoading,
      refetch: (payload: Req) => refetch({ data: payload }),
      error,
    }),
    [api, id, url, successAlertEnabled, successMessage, data, isLoading],
  );
}

export function useLab<T = Lab>() {
  return useFetchLab<T[]>();
}

export function useLabUploadedReports<T = LabUploadedReport[]>(submissionId: string) {
  return useFetchLab<T>({
    api: API.LAB,
    url: `/submissions/${submissionId}/uploaded_reports`,
  });
}
