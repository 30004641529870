import { createApi } from "@reduxjs/toolkit/query/react";
import useAxios from "axios-hooks";
import queryString from "query-string";
import { useEffect, useMemo } from "react";
import API from "constants/api";
import { useErrorAlert } from "hooks";
import {
  CalculateReport,
  ExportQueueItem,
  ExportReport,
  PreviewReportOptions,
  SavedTemplate,
  SaveTemplate,
  Template,
  TemplateAttribute,
} from "types/export-reporting";
import { ListData } from "types/global";
import { axiosBaseQuery } from "../axios-base-query";

export const exportReportApi = createApi({
  reducerPath: "exportReportApi",
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}${API.BUYER_REPORTS}`,
  }),
  tagTypes: [
    "getReportTemplates",
    "getReportTemplateAttributes",
    "getSavedReportTemplates",
    "getSavedReportTemplateWithAttributes",
    "calculateReport",
    "reportQueue",
    "downloadReportInQueue",
  ],
  endpoints: (builder) => ({
    getReportTemplates: builder.query<Template[], null>({
      query: () => ({
        url: "/templates",
        method: "GET",
      }),
      providesTags: ["getReportTemplates"],
    }),
    getReportTemplateAttributes: builder.query<
      TemplateAttribute[],
      { templateId: number | string; includedItem?: "true" | "false" | "" } | null
    >({
      query: (payload) => {
        const query = queryString.stringify(
          { includedItem: payload?.includedItem || "false" },
          { skipEmptyString: true, skipNull: true },
        );

        return {
          url: `/templates/${payload?.templateId}/attributes${query ? `?${query}` : ""}`,
          method: "GET",
        };
      },
      providesTags: ["getReportTemplateAttributes"],
    }),
    getSavedReportTemplates: builder.query<SavedTemplate[], null>({
      query: () => ({
        url: "/saved_templates",
        method: "GET",
      }),
      providesTags: ["getSavedReportTemplates"],
    }),
    getSavedReportTemplateWithAttributes: builder.query<
      SavedTemplate,
      { userTemplateId: number | string }
    >({
      query: (payload) => ({
        url: `/saved_templates/${payload?.userTemplateId}/attributes`,
        method: "GET",
      }),
      providesTags: ["getSavedReportTemplateWithAttributes"],
    }),
    saveReportTemplate: builder.mutation<any, SaveTemplate>({
      query: (payload) => ({
        url: "/save_template",
        method: "POST",
        body: payload,
        successAlertEnabled: true,
        successMessage: "Your saved report template submitted",
      }),
      invalidatesTags: ["getSavedReportTemplates", "getSavedReportTemplateWithAttributes"],
    }),
    deleteSavedReportTemplate: builder.mutation<any, { userTemplateId: number | string }>({
      query: (payload) => ({
        url: `/saved_template/${payload?.userTemplateId}`,
        method: "DELETE",
        successAlertEnabled: true,
        successMessage: "Your saved report template deleted",
      }),
      invalidatesTags: ["getSavedReportTemplates"],
    }),
    calculateReport: builder.query<any, CalculateReport>({
      query: (payload) => ({
        url: "/calculate",
        method: "POST",
        body: payload,
      }),
      providesTags: ["calculateReport"],
    }),
    exportReport: builder.mutation<any, ExportReport>({
      query: (payload) => ({
        url: "/export",
        method: "POST",
        body: payload,
        successAlertEnabled: true,
        successMessage: "Your report exported",
      }),
    }),
    getReportQueue: builder.query<ExportQueueItem[], null>({
      query: () => ({
        url: "/queue",
        method: "GET",
      }),
      providesTags: ["reportQueue"],
    }),
    cancelReportInQueue: builder.mutation<
      ExportQueueItem | string,
      { reportQueueId: number | string }
    >({
      query: (payload) => ({
        url: `/queue/${JSON.stringify(payload?.reportQueueId)}/cancel`,
        method: "PUT",
        successAlertEnabled: true,
        successMessage: "Your queue report cancelled",
      }),
      invalidatesTags: ["reportQueue"],
    }),
    deleteReportInQueue: builder.mutation<
      ExportQueueItem | string,
      { reportQueueId: number | string }
    >({
      query: (payload) => ({
        url: `/queue/${JSON.stringify(payload?.reportQueueId)}`,
        method: "DELETE",
        successAlertEnabled: true,
        successMessage: "Your queue report removed",
      }),
      invalidatesTags: ["reportQueue"],
    }),
    previewSkuRelationshipReport: builder.mutation<ListData<any>, PreviewReportOptions>({
      query: (payload) => ({
        url: "/sku_relationship_report/preview",
        method: "POST",
        body: payload,
      }),
    }),
    previewSkuLevelReport: builder.mutation<ListData<any>, PreviewReportOptions>({
      query: (payload) => ({
        url: "/sku_report/preview",
        method: "POST",
        body: payload,
      }),
    }),
    previewSubmissionReport: builder.mutation<ListData<any>, PreviewReportOptions>({
      query: (payload) => ({
        url: "/submission_report/preview",
        method: "POST",
        body: payload,
      }),
    }),
    previewTaskRequestReport: builder.mutation<ListData<any>, PreviewReportOptions>({
      query: (payload) => ({
        url: "/task_request_report/preview",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export function useDownloadReportInQueue() {
  const { setError } = useErrorAlert();

  const [{ data: fetchData, loading: isLoading, error }, refetch] = useAxios(
    {
      method: "GET",
      withCredentials: true,
      headers: {
        "Content-Type": "application/octet-stream",
      },
      responseType: "blob",
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  return useMemo(
    () => ({
      data: fetchData?.data,
      isLoading,
      refetch: (payload: { reportQueueId: number | string }) =>
        refetch({ url: `${API.BUYER_REPORTS}/queue/${payload?.reportQueueId}/download` }),
      error,
    }),
    [fetchData, isLoading],
  );
}

export const {
  useGetReportTemplatesQuery,
  useLazyGetReportTemplatesQuery,
  useLazyGetReportTemplateAttributesQuery,
  useGetSavedReportTemplatesQuery,
  useLazyGetSavedReportTemplatesQuery,
  useLazyGetSavedReportTemplateWithAttributesQuery,
  useSaveReportTemplateMutation,
  useDeleteSavedReportTemplateMutation,
  useLazyCalculateReportQuery,
  useExportReportMutation,
  useGetReportQueueQuery,
  useCancelReportInQueueMutation,
  useDeleteReportInQueueMutation,
  usePreviewSkuLevelReportMutation,
  usePreviewTaskRequestReportMutation,
  usePreviewSubmissionReportMutation,
  usePreviewSkuRelationshipReportMutation,
} = exportReportApi;
