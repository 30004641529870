import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";

import { CheckboxesTags, CheckboxesTagsProps, CheckboxesTagsOption } from "components/core/Input";

import { formatDate, textOrUnknown } from "utils";

import { getCheckboxTagValues, getAutocompleteOptions } from "utils/input";

import { TextProps, EditInput } from "components/core";

export interface EditCheckboxesTagsProps
  extends Omit<CheckboxesTagsProps, "value" | "onSubmit" | "onChange"> {
  loading?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  textProps?: Omit<TextProps, "children">;
  value: string;
  onSubmit: (selected: string) => void;
}

export default function EditCheckboxesTags({
  readOnly = false,
  disabled = false,
  loading = false,
  textProps,
  options = [],
  value,
  onSubmit,
  ...rest
}: EditCheckboxesTagsProps) {
  const [inputValue, setInputValue] = useState(value);

  const handleClose = () => {
    setInputValue(value);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    selectedOptions: CheckboxesTagsOption[] | null,
  ) => {
    const toArrayString = selectedOptions?.map((item) => item?.value).toString() || "";

    setInputValue(toArrayString);
  };

  const handleSubmit = async () => {
    await onSubmit(inputValue);

    handleClose();
  };

  useEffect(() => {
    setInputValue(value);
  }, [JSON.stringify(value)]);

  return (
    <EditInput
      disabled={disabled}
      readOnly={readOnly}
      textProps={textProps}
      value={textOrUnknown(inputValue) as string}
      onSubmit={handleSubmit}
      onClose={handleClose}
      inputComponent={
        <CheckboxesTags
          {...rest}
          disabled={loading}
          options={getAutocompleteOptions(options)}
          value={getCheckboxTagValues(inputValue, getAutocompleteOptions(options))}
          onChange={handleChange}
        />
      }
    />
  );
}
