import React, { useState, useEffect, useMemo } from "react";
import useAxios from "axios-hooks";

import { useErrorAlert } from "hooks";
import { SupplierInformation, SupplierFilterListItem } from "types/supplier";
import { FilterOptions } from "types/global";
import { FilterLogItem } from "types/item";

import API from "constants/api";

export function useSupplierDetail<T>(param: { id: string }) {
  const { setError } = useErrorAlert();
  const [data, setData] = useState();

  const [{ data: fetchData, loading: isLoading, error }, refetch] = useAxios(
    {
      url: `${API.SUPPLIERS}/${param.id}`,
      method: "GET",
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (fetchData?.data) setData(fetchData?.data);
  }, [fetchData]);

  return {
    data,
    isLoading,
    refetch,
    error,
  };
}

export function useSupplierFactory(supplierId: string) {
  const { setError } = useErrorAlert();

  const [{ loading: isLoading, error }, refetch] = useAxios(
    {
      url: `${API.SUPPLIERS}/${supplierId}/factories`,
      method: "POST",
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  return useMemo(
    () => ({
      isLoading,
      refetch: (payload: FilterOptions) => refetch({ data: payload }),
      error,
    }),
    [isLoading],
  );
}

export function useSupplierFilterOption(supplierId: string) {
  const { setError } = useErrorAlert();

  const [data, setData] = useState<SupplierFilterListItem>();

  const [{ data: fetchData, loading: isLoading, error }, refetch] = useAxios(
    {
      url: `${API.SUPPLIERS}/${supplierId}/factories/filter_options`,
      method: "GET",
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (fetchData?.data) setData(fetchData?.data);
  }, [fetchData]);

  return useMemo(
    () => ({
      data,
      isLoading,
      refetch,
      error,
    }),
    [data, isLoading],
  );
}

export function useSupplierInformation(supplierId: string) {
  const { setError } = useErrorAlert();

  const [data, setData] = useState<SupplierInformation>();

  const [{ data: fetchData, loading: isLoading, error }, refetch] = useAxios(
    {
      url: `${API.SUPPLIERS}/${supplierId}`,
      method: "GET",
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  useEffect(() => {
    if (supplierId) refetch();
  }, [supplierId]);

  useEffect(() => {
    if (fetchData?.data) setData(fetchData?.data);
  }, [fetchData]);

  return useMemo(
    () => ({
      data,
      isLoading,
      refetch,
      error,
    }),
    [data, isLoading],
  );
}

export function useSupplierLog(supplierId: string) {
  const { setError } = useErrorAlert();

  const [{ loading: isLoading, error }, refetch] = useAxios(
    {
      url: `${API.SUPPLIERS}/${supplierId}/logs`,
      method: "POST",
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  return useMemo(
    () => ({
      isLoading,
      refetch: (payload: FilterOptions) => refetch({ data: payload }),
      error,
    }),
    [isLoading],
  );
}

export function useFilterSupplierLog(supplierId: string) {
  const { setError } = useErrorAlert();

  const [data, setData] = useState<FilterLogItem>();

  const [{ data: fetchData, loading: isLoading, error }, refetch] = useAxios(
    {
      url: `${API.SUPPLIERS}/${supplierId}/logs/filter_options`,
      method: "GET",
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (fetchData?.data) setData(fetchData?.data);
  }, [fetchData]);

  return useMemo(
    () => ({
      data,
      isLoading,
      refetch,
      error,
    }),
    [data, isLoading],
  );
}
