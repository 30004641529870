import React from "react";

import { BasicAppAlert, BasicAppAlertProps } from "components/core";

import { AppError } from "types/global";

export interface ErrorAppAlertProps extends BasicAppAlertProps {
  open: boolean;
  error: AppError;
  onClose: () => void;
}

export default function ErrorAppAlert({
  open = false,
  error,
  onClose,
  ...rest
}: ErrorAppAlertProps) {
  return (
    <BasicAppAlert sx={{ zIndex: 919999 }} {...rest} open={open} onClose={onClose} severity="error">
      <>
        {error?.code !== 0 && (
          <>
            <b>Status Code: </b> {error?.code}
          </>
        )}{" "}
        &nbsp;
        {error?.message && (
          <>
            <b>Message: </b> {error?.message}
          </>
        )}{" "}
      </>
    </BasicAppAlert>
  );
}
