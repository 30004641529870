/* eslint-disable jsx-a11y/aria-role */
import ClearIcon from "@mui/icons-material/Clear";
import { DatePicker as MuiDatePicker, LocalizationProvider } from "@mui/lab";
// or @mui/lab/Adapter{Dayjs,Luxon,Moment} or any valid date-io adapter
import AdapterMoment from "@mui/lab/AdapterMoment";
import {
  Box,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInputProps,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import _get from "lodash/get";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { DateFormat } from "types/global";
import { getCurrentDateTime } from "utils/date";

const DatePickerWrapper = styled(Box, {
  shouldForwardProp: (props) => props !== "error",
})<{
  error: boolean;
}>(({ theme, error }) => ({
  ".MuiFormControl-root label, .MuiFormHelperText-root": {
    color: error ? theme.palette.error.main : theme.palette.text.secondary,
  },
  ".MuiOutlinedInput-root fieldset": {
    borderColor: error ? theme.palette.error.main : "rgba(0, 0, 0, 0.23)",
  },
  ".Mui-focused": {
    color: error ? theme.palette.error.main : `${theme.palette.primary.main} !important`,
  },
}));

export interface DatePickerProps {
  helperText?: React.ReactNode | string | false;
  sx?: OutlinedInputProps["sx"];
  fullWidth?: OutlinedInputProps["fullWidth"];
  format?: DateFormat;
  disabled?: boolean;
  error?: boolean;
  disableFuture?: boolean;
  inputReadOnly?: boolean;
  name?: OutlinedInputProps["name"];
  label?: string;
  value: string;
  onChange: (date: string, isError?: boolean) => void;
}

export default function DatePicker({
  helperText,
  sx,
  fullWidth = false,
  format = DateFormat.YYYY_MM_DD_HH_MM_SS,
  disabled = false,
  error = false,
  inputReadOnly = true,
  name,
  label,
  value,
  onChange,
  ...datePickerRest
}: DatePickerProps) {
  const [selected, setSelected] = useState<Date | string | null>(null);

  const datePickerRef: any = useRef(null);

  useEffect(() => {
    // set initial date value
    setSelected(value || null);
  }, [value]);

  const handleChange = (date: string | Date | null) => {
    setSelected(date);
    onChange(getCurrentDateTime(date), !moment(date).isValid());
  };

  const handleClear = () => {
    setSelected(null);
    onChange("");
  };

  return (
    <DatePickerWrapper error={error}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MuiDatePicker
          inputRef={datePickerRef}
          label={label}
          value={selected}
          disabled={disabled}
          onChange={(newValue) => handleChange(newValue)}
          renderInput={(params) => {
            const { error: datePickerError, InputProps, ...rest } = params;

            const CalendarIcon = _get(InputProps, "endAdornment.props.children");
            const restProps = {
              ...rest,
              inputProps: {
                ...rest.inputProps,
                readOnly: inputReadOnly,
              },
            };

            return (
              <TextField
                role="datepicker"
                sx={sx}
                name={name}
                error={datePickerError}
                fullWidth={fullWidth}
                InputProps={{
                  ...InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      {selected && (
                        <IconButton onClick={() => handleClear()}>
                          <ClearIcon />
                        </IconButton>
                      )}
                      {CalendarIcon}
                    </InputAdornment>
                  ),
                }}
                {...restProps}
              />
            );
          }}
          {...datePickerRest}
        />
        {helperText && (
          <FormHelperText
            sx={{
              marginTop: "3px",
              marginRight: "14px",
              marginBottom: 0,
              marginLeft: "14px",
            }}
          >
            {helperText}
          </FormHelperText>
        )}
      </LocalizationProvider>
    </DatePickerWrapper>
  );
}
