import { Brand } from "./brand";
import { Country } from "./global";
import { Subcategory } from "./subcategory";
import { Comment, TestItem, TestLevel } from "./test-item";
import { TestMatrix } from "./test-matrix";

export enum ChangeActionType {
  Approve = "approve",
  Draft = "draft",
  DeleteDraft = "delete-draft",
  Reject = "reject",
  Return = "return",
}

export enum RequestType {
  TestItemType = "test_item",
  TestMatrixType = "test_matrix",
  BrandType = "brand",
  SubcategoryType = "sub_category",
}

export enum ChangeType {
  CreateTestItem = "CREATE_TEST_ITEM",
  ArchiveTestItem = "ARCHIVE_TEST_ITEM",
  EditTestItem = "EDIT_TEST_ITEM",

  CreateMatrix = "CREATE_TEST_MATRIX",
  ArchiveMatrix = "ARCHIVE_TEST_MATRIX",
  EditMatrix = "EDIT_TEST_MATRIX",

  CreateBrand = "CREATE_BRAND",
  ArchiveBrand = "ARCHIVE_BRAND",
  EditBrand = "EDIT_BRAND",

  CreateSubcategory = "CREATE_SUB_CATEGORY",
  ArchiveSubcategory = "ARCHIVE_SUB_CATEGORY",
  EditSubcategory = "EDIT_SUB_CATEGORY",
}

export enum ChangeRequestStatus {
  Approved = "APPROVED",
  PendingApproval = "PENDING_APPROVED",
  Draft = "DRAFT",
  Published = "PUBLISHED",
  Returned = "RETURNED",
  Rejected = "REJECTED",
  Active = "ACTIVE",
}

export interface ChangeRequest {
  id: number;
  country: Country | null;
  approvedBy: string | null;
  brands: string | null;
  changeType: ChangeType;
  editor: string | null;
  requestCode: string | null;
  status: ChangeRequestStatus | null;
  subCategories: string | null;
  subCategory: string | null; // for filter options in list
  comments: Comment[];
  skuBrandRequest: Brand | null;
  skuSubCategoryRequest: Subcategory | null;
  testPlanRequest: TestMatrix | null;
  testItemRequest: TestItem | null;
  testLevel: TestLevel;
}

export interface DraftRequestPayload {
  saveDraft: boolean;
  comment: string;
}

export interface DraftRequestBrandPayload extends DraftRequestPayload {
  id: Brand["id"];
  country: Brand["country"];
  name: Brand["name"];
  remark: Brand["remark"];
}

export interface DraftRequestSubcategoryPayload extends DraftRequestPayload {
  id: Subcategory["id"];
  country: Subcategory["country"];
  brand: Subcategory["brand"];
  name: Subcategory["name"];
  productExample: Subcategory["productExample"];
  remark: Subcategory["remark"];
}

export interface DraftRequestTestMatrixPayload extends DraftRequestPayload {
  id: TestMatrix["id"];
  country: TestMatrix["country"];
  brand: Subcategory["brand"];
  subCategory: TestMatrix["subCategory"];
  remark: TestMatrix["remark"];
  testItemIds: number[];
}

export interface DraftRequestTestItemPayload extends DraftRequestPayload {
  id: TestItem["id"];
  remark: TestItem["remark"];
  testDesc: TestItem["testDesc"];
  testLine: TestItem["testLine"];
  testLevel: TestItem["testLevel"];
  subCategoryIds: number[];
}
