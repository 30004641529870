import React from "react";
import { Grid, GridProps } from "@mui/material";

import { BasicChip, BasicChipProps, Text } from "components/core";

interface TagGroupListProps {
  list: string[];
  spacing?: GridProps["spacing"];
  ChipProps?: BasicChipProps;
}

export default function TagGroupList({ list = [], spacing = 1, ChipProps }: TagGroupListProps) {
  return (
    <Grid container spacing={spacing}>
      {list?.length > 0 ? (
        list?.map((item, index) => {
          return (
            <Grid item key={index}>
              <BasicChip
                sx={{
                  borderRadius: "4px",
                }}
                label={item}
                {...ChipProps}
              />
            </Grid>
          );
        })
      ) : (
        <Grid item>
          <Text color="black">No tags found</Text>
        </Grid>
      )}
    </Grid>
  );
}
