import { useEffect, useState } from "react";
import {
  INITIAL_PAGINATION_STATE,
  INITIAL_PER_PAGE_OPTIONS,
  INITIAL_ROW_PER_PAGE,
} from "constants/table";

export function usePagination(
  page = 0,
  rowsPerPage = INITIAL_ROW_PER_PAGE,
  rowsPerPageOptions = INITIAL_PER_PAGE_OPTIONS,
  totalRecords = 0,
) {
  const [pagination, setPagination] = useState(INITIAL_PAGINATION_STATE);

  useEffect(() => {
    setPagination((state) => ({ ...state, page, rowsPerPage, rowsPerPageOptions, totalRecords }));
  }, []);

  const resetPagination = () => {
    setPagination(INITIAL_PAGINATION_STATE);
  };

  return { pagination, setPagination, resetPagination };
}
