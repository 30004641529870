import { AxiosRequestConfig } from "axios";
import useAxios from "axios-hooks";
import queryString from "query-string";
import { useEffect, useMemo, useState } from "react";

import { useErrorAlert, useSuccessAlert, useUserRoleUrl } from "hooks";

import API from "constants/api";

import { Order } from "constants/table";

import { BUYER } from "constants/global";
import { FetchOption, FilterOptions } from "types/global";
import { SubcategoryTag } from "types/subcategory";
import { CustomHeader } from "types/task";

function useFetchSubcategory<T>({
  method = "GET",
  api = API.SUB_CATEGORY,
  id = "",
  url = "",
  ...options
}: {
  method?: AxiosRequestConfig["method"];
  api?: API;
  id?: string;
  url?: string;
} & Omit<AxiosRequestConfig, "method" | "url">) {
  const { setError } = useErrorAlert();

  // const { isBuyer } = useUserRole();

  const PATH = `/${BUYER}${api}`;

  const [data, setData] = useState<T>();

  const [{ data: fetchData, loading: isLoading, error }, refetch] = useAxios(
    {
      url: !id && !url ? PATH : `${PATH}${id}${url}`,
      method,
      ...options,
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  useEffect(() => {
    if (fetchData?.data) setData(fetchData?.data);
  }, [fetchData]);

  return useMemo(
    () => ({
      data: data as T,
      isLoading,
      refetch,
      error,
    }),
    [id, url, data, isLoading],
  );
}

function useUpdateSubcategory<Req, Res = { id?: string }>({
  method = "POST",
  api = API.SUB_CATEGORY,
  id = "",
  url = "",
  successAlertEnabled = false,
  successMessage = "Submitted successfully.",
}: {
  method?: AxiosRequestConfig["method"];
  api?: API;
  id?: string;
  url?: string;
  successAlertEnabled?: boolean;
  successMessage?: string;
}) {
  const { setError, setAppError } = useErrorAlert();
  const { setSuccess } = useSuccessAlert();

  const PATH = useUserRoleUrl(api);

  const [data, setData] = useState<Res>();

  const [{ data: fetchData, loading: isLoading, error }, refetch] = useAxios(
    {
      url: !id && !url ? PATH : `${PATH}/${id}${url}`,
      method,
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  useEffect(() => {
    if (fetchData?.code !== 200) {
      setAppError({
        code: fetchData?.code,
        message: fetchData?.message,
      });
    }

    if (successAlertEnabled && fetchData?.code === 200) setSuccess(successMessage);

    if (fetchData?.data) setData(fetchData?.data);
  }, [fetchData]);

  return useMemo(
    () => ({
      data,
      isLoading,
      refetch: (payload: Req) => refetch({ data: payload }),
      error,
    }),
    [api, id, url, successAlertEnabled, successMessage, data, isLoading],
  );
}

export function useSubcategoryList() {
  return useUpdateSubcategory<FilterOptions>({ url: "list" });
}

export function useSubcategoryTagList<T = SubcategoryTag[]>({
  search = "",
  brand = "",
  activeStatus = false,
  order = "asc",
}: {
  search?: string;
  brand?: string;
  activeStatus?: boolean;
  order?: Order;
} = {}) {
  const parsed = {
    search: "",
    brand: "",
    status: "",
    order: "",
  };

  parsed.search = search ?? "";
  parsed.brand = brand ?? "";
  parsed.status = activeStatus ? "active" : "";
  parsed.order = order ? "asc" : "";

  const query = queryString.stringify(parsed, { skipEmptyString: true });

  return useFetchSubcategory<T>({ url: `?${query}` });
}

export function useSubcategoryOptions<T = FetchOption[]>(brand = "", activeStatus = false) {
  const defaultUrl = "/filter-options";

  const parsed = {
    brand: "",
    status: "",
  };

  parsed.brand = brand ?? "";
  parsed.status = activeStatus ? "active" : "";

  const query = queryString.stringify(parsed, { skipEmptyString: true });

  const urlWithQuery = `${defaultUrl}?${query}`;

  return useFetchSubcategory<T>({ url: urlWithQuery });
}

export function useSubCategoryHeaderOptions<R = CustomHeader>() {
  return useUpdateSubcategory<any, R>({
    method: "POST",
    url: "filter_options",
  });
}

export function useExportSubCategory<T = any>() {
  return useFetchSubcategory<T>({
    url: "/download",
    responseType: "blob",
  });
}
