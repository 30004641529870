import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";

import {
  AutocompleteCreatable,
  AutocompleteCreatableProps,
  AutocompleteOption,
} from "components/core/Input";

import { formatDate, textOrUnknown } from "utils";

import { getAutocompleteValue } from "utils/input";

import { TextProps, EditInput } from "components/core";

export interface EditAutocompleteCreatableProps
  extends Omit<AutocompleteCreatableProps, "value" | "onSubmit" | "onChange"> {
  loading?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  textProps?: Omit<TextProps, "children">;
  value: string;
  onSubmit: (selected: string) => void;
}

export default function EditAutocompleteCreatable({
  readOnly = false,
  disabled = false,
  loading = false,
  textProps,
  options = [],
  value,
  onSubmit,
  ...rest
}: EditAutocompleteCreatableProps) {
  const [inputValue, setInputValue] = useState("");
  const [creatableOptions, setCreatableOptions] = useState<AutocompleteOption[]>(options);

  const handleClose = () => {
    setInputValue(value);
    setCreatableOptions(options);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    option: AutocompleteOption | null,
  ) => {
    setInputValue((option?.value as string) || "");

    if (option?.isNewValue) {
      setCreatableOptions((state) => [...state, option]);
    }
  };

  const handleSubmit = async () => {
    await onSubmit(inputValue);

    handleClose();
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <EditInput
      disabled={disabled}
      readOnly={readOnly}
      textProps={textProps}
      value={textOrUnknown(value) as string}
      onSubmit={handleSubmit}
      onClose={handleClose}
      inputComponent={
        <AutocompleteCreatable
          {...rest}
          options={creatableOptions}
          value={getAutocompleteValue(inputValue, creatableOptions)}
          disabled={loading}
          onChange={handleChange}
        />
      }
    />
  );
}
