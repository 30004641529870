import React, { useState, useEffect } from "react";

import { getFetchOptions, getVendorOptions } from "utils/input";

import {
  useBuyerDescs,
  useSkuCollections,
  useSkuColorCodes,
  useSkuSizeCodes,
  useSkuCountryOfOrigins,
  useSkuDepts,
  useSkuFabrics,
  useSkuFills,
  useSkuFinishes,
  useSkuFrames,
  useSkuFunctions,
  useSkuSubDepts,
  useSkuVendors,
} from "hooks/useItem";
import { AutocompleteOption } from "components/core";
import { SkuItem, SkuSupplierInfo } from "types/item";

export function useSkuInfoOptions() {
  const [creatableOptions, setCreatableOptions] = useState<
    Partial<Record<keyof SkuItem, AutocompleteOption[]>>
  >({
    buyerDesc: [],
    deptDesc: [],
    subDeptDesc: [],
    collection: [],
    frame: [],
    colorCode: [],
    sizeCode: [],
    fabric: [],
    fill: [],
    finish: [],
    function: [],
    countryOfOrigin: [],
  });

  const {
    data: buyerDescs,
    isLoading: isBuyerDescsLoading,
    refetch: refetchBuyerDescs,
  } = useBuyerDescs();
  const { data: skuDepts, isLoading: isSkuDeptsLoading, refetch: refetchSkuDepts } = useSkuDepts();
  const {
    data: skuSubDepts,
    isLoading: isSkuSubDeptsLoading,
    refetch: refetchSkuSubDepts,
  } = useSkuSubDepts();
  const {
    data: skuCollections,
    isLoading: isSkuCollectionsLoading,
    refetch: refetchSkuCollections,
  } = useSkuCollections();
  const {
    data: skuFrames,
    isLoading: isSkuFramesLoading,
    refetch: refetchSkuFrames,
  } = useSkuFrames();
  const {
    data: skuColorCodes,
    isLoading: isSkuColorCodesLoading,
    refetch: refetchSkuColorCodes,
  } = useSkuColorCodes();
  const {
    data: skuSizeCodes,
    isLoading: isSkuSizeCodesLoading,
    refetch: refetchSkuSizeCodes,
  } = useSkuSizeCodes();
  const {
    data: skuFabrics,
    isLoading: isSkuFabricsLoading,
    refetch: refetchSkuFabrics,
  } = useSkuFabrics();
  const { data: skuFills, isLoading: isSkuFillsLoading, refetch: refetchSkuFills } = useSkuFills();
  const {
    data: skuFinishes,
    isLoading: isSkuFinishesLoading,
    refetch: refetchSkuFinishes,
  } = useSkuFinishes();
  const {
    data: skuFunctions,
    isLoading: isSkuFunctionsLoading,
    refetch: refetchSkuFunctions,
  } = useSkuFunctions();
  const {
    data: skuCountryOfOrigins,
    isLoading: isSkuCountryOfOriginsLoading,
    refetch: refetchSkuCountryOfOrigins,
  } = useSkuCountryOfOrigins();

  const handleRefetchAll = () => {
    refetchBuyerDescs();
    refetchSkuDepts();
    refetchSkuSubDepts();
    refetchSkuCollections();
    refetchSkuFrames();
    refetchSkuColorCodes();
    refetchSkuSizeCodes();
    refetchSkuFabrics();
    refetchSkuFills();
    refetchSkuFinishes();
    refetchSkuFunctions();
    refetchSkuCountryOfOrigins();
  };

  const isAllDataLoading =
    isBuyerDescsLoading ||
    isSkuDeptsLoading ||
    isSkuSubDeptsLoading ||
    isSkuCollectionsLoading ||
    isSkuFramesLoading ||
    isSkuColorCodesLoading ||
    isSkuSizeCodesLoading ||
    isSkuFabricsLoading ||
    isSkuFillsLoading ||
    isSkuFinishesLoading ||
    isSkuFunctionsLoading ||
    isSkuCountryOfOriginsLoading;

  useEffect(() => {
    setCreatableOptions((state) => ({
      ...state,
      buyerDesc: getFetchOptions(buyerDescs),
      collection: getFetchOptions(skuCollections),
      frame: getFetchOptions(skuFrames),
      colorCode: getFetchOptions(skuColorCodes),
      sizeCode: getFetchOptions(skuSizeCodes),
      fabric: getFetchOptions(skuFabrics),
      fill: getFetchOptions(skuFills),
      finish: getFetchOptions(skuFinishes),
      function: getFetchOptions(skuFunctions),
      countryOfOrigin: getFetchOptions(skuCountryOfOrigins),
    }));
  }, [
    buyerDescs,
    skuDepts,
    skuSubDepts,
    skuCollections,
    skuFrames,
    skuColorCodes,
    skuSizeCodes,
    skuFabrics,
    skuFills,
    skuFinishes,
    skuFunctions,
    skuCountryOfOrigins,
  ]);

  return {
    isLoading: {
      isBuyerDescsLoading,
      isSkuDeptsLoading,
      isSkuSubDeptsLoading,
      isSkuCollectionsLoading,
      isSkuFramesLoading,
      isSkuColorCodesLoading,
      isSkuSizeCodesLoading,
      isSkuFabricsLoading,
      isSkuFillsLoading,
      isSkuFinishesLoading,
      isSkuFunctionsLoading,
      isSkuCountryOfOriginsLoading,
    },
    data: {
      buyerDescs,
      skuDepts,
      skuSubDepts,
      skuCollections,
      skuFrames,
      skuColorCodes,
      skuSizeCodes,
      skuFabrics,
      skuFills,
      skuFinishes,
      skuFunctions,
      skuCountryOfOrigins,
    },
    isAllDataLoading,
    options: creatableOptions,
    refetch: handleRefetchAll,
  };
}

export function useSkuSupplierInfoOptions() {
  const {
    data: skuVendors,
    isLoading: isSkuVendorsLoading,
    refetch: refetchSkuVendors,
  } = useSkuVendors();

  const [creatableOptions, setCreatableOptions] = useState<
    Partial<Record<keyof SkuSupplierInfo, AutocompleteOption[]>>
  >({
    vendorNum: [],
    vendorDesc: [],
  });

  useEffect(() => {
    setCreatableOptions((state) => ({
      ...state,
      vendorNum: getVendorOptions("vendorNum")(skuVendors),
      vendorDesc: getVendorOptions("vendorDesc")(skuVendors),
    }));
  }, [skuVendors]);

  return {
    isLoading: isSkuVendorsLoading,
    data: {
      skuVendors,
    },
    options: creatableOptions,
    refetch: refetchSkuVendors,
  };
}
