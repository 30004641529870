import { CwItemDescription, SKUStatus, SkuSupplierInfo } from "types/item";
import { TestItem, TestLevel } from "types/test-item";

import { Country, File } from "./global";

export interface CustomHeader {
  [name: string]: any[];
}

export type SkuFileType =
  | "product_image"
  | "compliance_documents"
  | "all_applicable_labelling"
  | "product_specs"
  | "rh_approved_self_declarations"
  | "bom";

export enum ReportResult {
  Pass = "pass",
  Fail = "fail",
  InformationOnly = "informationOnly",
  NotApplicable = "notApplicable",
}

export enum TaskReportResult {
  Approved = "approved",
  ConditionalApproved = "concessional_approved",
  RejectedUpdateReport = "rejected_update_report",
  RejectedRequestCap = "rejected_request_cap",
  Pending = "pending_approval",
}

export enum CapReportStatus {
  Approved = "approved",
  Uploaded = "uploaded",
  Rejected = "rejected",
  Pending = "pending_approval",
  PendingUpload = "pending_upload",
  AcceptedFailResult = "accepted_fail_result",
}

export enum TaskStatus {
  NoGrouping = "no_grouping",
  EmptyTask = "empty_task",
  NotStarted = "not_started",
  NotOpenForQuote = "not_open_for_quote",
  NotOpenForQuoteRetest = "not_open_for_quote_retest",
  WaitingForLabQuote = "waiting_for_lab_quote",
  QuoteAvailable = "quote_available",

  QuoteConfirmed = "quote_confirmed",
  SampleReceived = "sample_received",
  SampleSent = "sample_sent",
  ReportUpdated = "in_progress_result_report_updated",
  AuditInProgress = "audit_in_progress",
  InProgress = "in_progress",
  Pending = "pending",

  Cancelled = "cancelled",
  Rejected = "rejected",
  Completed = "completed",
  CompletedPending = "completed_pending",
  Fail = "fail",
  // for cap status
  CapRejected = "cap_rejected",
  CapApproved = "cap_approved",
  CapAccepted = "cap_accepted",
  CapPendingApproval = "cap_pending_approval",
  // for task report result
  RejectedUpdateReport = "rejected_update_report",
  RejectedRequestCap = "rejected_request_cap",
  ConditionalApproved = "conditional_approved",
  Approved = "approved",
  // for sku grouping
  NotApplicable = "notApplicable",
}

export interface TestReportResultApproval {
  result: TaskReportResult | null;
  approvalReason: string;
  retestTestLevels: TestLevel[];
}

export interface CapTestReportApproval {
  testLevel: TestLevel | null;
  testItemIds: number[];
}

export interface SkuTaskDisableIds {
  disableRemovals: number[];
  disableSelections: number[];
}

export interface SkuTestLevel {
  color?: string;
  colorCode?: string | null;
  sizeCode?: string | null;
  result?: string | null;
  isEditing?: boolean; // for edit mode to enable to select sku test level
  isNotApplicable?: boolean;
  masterWicId: number | null;

  isCompleted: boolean;
  isPrimeSku: boolean;
  isCurrent: boolean;

  referToTaskId: number | null;
  taskId: number | null;
  taskNumber: string | null;
  taskStatus: TaskStatus | null; // tbc
  cwItemDescription: string | null;
  testLevel: TestLevel | null;
  wicNumber: string | null;
  labName: string | null;
}

export interface SelectedTestLevel {
  selectedWicNumber: string | null;
  testLevel: TestLevel;
  isEditing: boolean;
  defaultTestLevel: {
    wicNumber: string | null;
    cwItemDescription: string | null;
    isCompleted: boolean;
    isPrimeSku: boolean;
    isCurrent: boolean;
    isNotApplicable: boolean;
  } | null;
}

export interface SkuTestLevelGroup {
  testLevels: SelectedTestLevel[];
  //* for sorting only
  filters: {
    wicNumber: string;
    colorCode: string;
    sizeCode: string;
    isCurrent: boolean;
    isDeleted: boolean;
    isExistingPrime: boolean;
    isSelectedAsPrime: boolean;
  };
}

export interface CombinedTaskMarketAndTestLevel extends Task {
  taskId?: number;
  exportMarket: CwItemDescription["exportMarket"];
  skuTestLevels: SkuTestLevel[];
}

export interface Task extends SkuSupplierInfo {
  color?: string; // for sku grouping

  id?: number;
  masterWicId?: number;

  isAFLPCompleted?: boolean;
  isQaCompleted?: boolean;
  isCurrent?: boolean;
  isPrimeSku?: boolean;
  isCurrentReferring?: boolean;
  isDeleted?: boolean; // sku grouping
  isSelectAsPrime?: boolean; // sku grouping
  isNotApplicable?: boolean; // not applicable

  brand: string | null;
  subCategory: string | null;
  validityDate: string;

  wicNumber: string | null; // 10097378PYR

  collection: string | null; // PYRITE MIRRORS
  frame: string | null; // PYRITE
  colorCode: string | null; // PTR
  sizeCode: string | null;

  skuCreateDate?: string | null; // 3/5/2022
  startSellingDate: string | null; // 3/5/2022

  buyerDesc: string | null; // DECOR
  subDeptDesc: string | null; // MIRRORS

  fabric: string | null; // PYRITE
  fill: string | null;
  finish: string | null;
  function: string | null;
  bom: string | null;
  countryOfOrigin: string | null; // IND

  skuStatus: SKUStatus | null; // A
  minPoCancelDate: CwItemDescription["minPoCancelDate"]; // 3/5/2022
  countryCodeList: string | null; //1;GBR
  dcList: string | null; // 099,088

  deptNum: string | null; // 4000
  deptDesc: string | null; // HOME ACCESSORIES

  cwItemDescription: CwItemDescription["cwItemDescription"];
  exportMarket: CwItemDescription["exportMarket"];
  concept: string | null; // CORE

  skuTestLevels: SkuTestLevel[]; // for column display
  testedTestMatrix: any; // for column display

  combinedMarketAndTestLevels?: CombinedTaskMarketAndTestLevel[]; // for combined task in table
}

export interface EditTaskGrouping {
  brand: string;
  subCategory: string;
  country: Country;
  tasks: Task[];
}

export interface ReportFile {
  id: number;
  fileName: string | null;
  fileType: string | null;
  location: string | null;
  masterWICFile: File | null;
  name: string | null;
  status: string | null;
  subWicResultFile: File | null;
  file: File | null;
}

export interface TestReportFiles {
  fileId: number;
  fileName: string | null;
  id: number;
  location: string | null;
  type: string | null;
  file: ReportFile;
}

export interface ApprovalDetail {
  status: TaskReportResult;
  approver: string | null;
  reportApprovedDate: string | null;
  validityPeriod: number; //! @depreciated
  validityDate: string;
  approvalReason: string | null;
  impactedTestLevels: string | null;
  taskStatus: TaskStatus;
}

export interface TaskReportDetail {
  result: ReportResult | null;
  wicNumber: string | null;
  testLevel: TestLevel;
  isCompleted: boolean; // check if test level is completed
  isPendingReupload: boolean;
  isNotApplicable: boolean;
  approvalDetails: ApprovalDetail;
  cwItemDescription: string | null;
  issueDate: string | null;
  failedTestItems: TestItem[];
  retestTestItems: TestItem[];
  reportFiles: TestReportFiles[];
  taskStatus: TaskStatus;
  country: string;
  brand: string;
  subCategory: string;
}

export interface RetestDetail {
  isShowOpenBtn: boolean; // for current report
  isShowApprovalBtn: boolean; // for current report
  isShowResumeBtn: boolean; // for current report
  wicNumber: string;
  approvalDetails: ApprovalDetail;
  capFile: File;
  capStatus: CapReportStatus | null;
  capRejectReason: string | null;

  taskId: number;
  retestReports: TaskReportDetail[];
  retestResult: ReportResult | null;
}

// sku detail task report
export interface TaskReport {
  isShowOpenBtn: boolean; // for current report
  isShowApprovalBtn: boolean; // for current report
  isShowResumeBtn: boolean; // for current report
  wicNumber: string;
  approvalDetails: ApprovalDetail;
  capFile: File;
  capStatus: CapReportStatus | null;
  capRejectReason: string | null;
  result: ReportResult | null;
  reportDetails: TaskReportDetail[];
  retestDetails: RetestDetail[];
}

export interface TaskReportApproveData {
  wicNumber: string;
  result: ReportResult | null;
  reports: TaskReportDetail[];
  testItems: TestItem[];
}

// sku detail task sku grouping
export interface TaskSkuGrouping {
  referToTaskId: number;
  taskId: number;
  taskNumber: string;
  submissionId: number;
  submissions: {
    id: number;
    submissionNumber: string;
  }[];
  testLevel: TestLevel;
  isPrimeSku: boolean;
  isCurrent: boolean;
  isCompleted: boolean;
  isNotApplicable: boolean;
  taskStatus: TaskStatus;
  result: ReportResult;
  wicNumber: string | null;
  labName: string | null;
  cwItemDescription: CwItemDescription["cwItemDescription"];
  country: string | null;
  brand: string | null;
  subCategory: string | null;
  testedTestMatrix: any; // for column display
  skuGroupingComment?: string | null;
}

export interface TaskRequirement {
  id: number;
  exportMarket: Country;
  groupNumber: string | null;
  taskNumber: string | null;
  submissionNumber: string | null;
  cwItemDescription: string | null;
  taskStatus: TaskStatus;
  capStatus: CapReportStatus;
  completedStatus: TaskReportResult | null;
  testMatrixStatus: null;
  country: Country;
  brand: string | null;
  isPrimeSku: boolean;
  isRetest: boolean;
  subCategory: string | null;
  isCompleted: boolean; // for task remove btn
  latestTaskId?: number; // for resume task btn
  validityDate: string;
}

export interface TaskLog {
  action: string;
  content: string;
  actor: string;
  date: string;
}

export interface PoDetail {
  affectedPoNumber: string;
  poShipDate: string;
}

export interface CapFormValues {
  hasPoAffectedItemShipped: boolean;
  willProductBeLate: boolean;
  estimatedDelayInUnit: string;
  estimatedDelayInValue: number | null;
  descriptionOfFailure: string;
  poDetails: PoDetail[];
  potentialCause: string;
  proposedCorrectiveAction: string;
  reSubmissionDate: string;
  userName: string;
  attachments: Pick<File, "id">[];
  // from test report info
  productDescription: string;
  sampleSubmitterContactName: string;
  sampleSubmitterEmail: string;
  sampleSubmitterPhone: string;
}

export interface CapViewInfo {
  testingFacility: string;
  labAddress: string;
  testProgram: string;
  testReportNumber: string[];
  failedTestItems: Pick<TestItem, "id" | "testLevel" | "testLine" | "testDesc">[];
  supplierCode: string;
  supplierName: string;
  skuNumber: string;
  productName: string;
  // cap info submit info
  productDescription: string;
  sampleSubmitterContactName: string;
  sampleSubmitterEmail: string;
  sampleSubmitterPhone: string;
  // cap info form values
  hasPoAffectedItemShipped: boolean;
  poDetails: PoDetail[];
  willProductBeLate: boolean;
  estimatedDelayInUnit: string;
  estimatedDelayInValue: number;
  descriptionOfFailure: string;
  potentialCause: string;
  proposedCorrectiveAction: string;
  reSubmissionDate: string;
  userName: string;
  attachments: File[];
}
