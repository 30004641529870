import {
  ADMIN,
  BUYER,
  BUYER_ROLE_KEY,
  LAB,
  LAB_ADMIN,
  STORAGE_ROLE_KEY,
  SUPPLIER,
  SUPPLIER_AGENCY,
} from "constants/global";
import { Country } from "types/global";
import { TestLevel } from "types/test-item";

export const useUserRole = () => {
  const role = window.localStorage.getItem(STORAGE_ROLE_KEY);

  const isBuyer = role === BUYER;
  const isSupplier = role?.includes(SUPPLIER);
  const isLab = role?.includes(LAB) ?? false;
  const isLabAdmin = role === LAB_ADMIN;
  const isSupplierAgency = role === SUPPLIER_AGENCY;
  const isAdmin = role === ADMIN;

  return {
    isBuyer,
    isSupplier,
    isLab,
    isLabAdmin,
    isSupplierAgency,
    isAdmin,
    role,
  };
};

export const useBuyerRole = () => {
  const buyerRole = window.localStorage.getItem(BUYER_ROLE_KEY);

  const isComplianceUser = buyerRole === "compliance";
  const isComplianceAdmin = buyerRole === "compliance_main";
  const isQaUser = buyerRole === "quality";
  const isQaAdmin = buyerRole === "quality_main";
  const isEsg = buyerRole === "esg";

  return {
    isComplianceUser,
    isComplianceAdmin,
    isQaUser,
    isQaAdmin,
    isEsg,
    buyerRole,
  };
};

export const useGetBuyerPermission = ({
  testLevel,
  exportMarket,
}: {
  testLevel?: TestLevel;
  exportMarket?: Country;
} = {}) => {
  const { buyerRole } = useBuyerRole();

  const isQaTeam = buyerRole?.includes("quality") ?? false;
  const isComplianceTeam = buyerRole?.includes("compliance") ?? false;
  const isESGTeam = buyerRole?.includes("esg") ?? false;

  const isQualityTestLevel = testLevel === TestLevel.Q;

  const isQaMarket = exportMarket === Country.QC;

  const state = {
    isEditingTestItem: false,
    isEditingTestMatrix: false,
    isApprovalTestItem: false,
    isApprovalTestMatrix: false,
    isApprovalTask: false,
    isDownloadReport: false,
    isQaTeam,
    isComplianceTeam,
    isESGTeam,
  };

  if (!buyerRole) {
    state.isDownloadReport = true;
    return state;
  }

  // todo: logic
  // edit test item: cm, qm(q only)
  // approve test item in change request: cm, qm(q only)
  // edit test matrix in change request: c, cm
  // approve test matrix in change request: cm
  // approve task in change request: c, cm, q(q only), qm(q only)

  switch (buyerRole) {
    case "compliance_main":
      state.isEditingTestItem = true;
      state.isEditingTestMatrix = true;
      state.isApprovalTestItem = true;
      state.isApprovalTestMatrix = true;

      state.isApprovalTask = true;
      state.isDownloadReport = true;
      break;

    case "compliance":
      state.isEditingTestItem = true;
      state.isEditingTestMatrix = true;
      state.isApprovalTask = true;
      state.isDownloadReport = true;
      break;

    case "quality_main":
      if (isQaTeam && (isQualityTestLevel || isQaMarket)) {
        state.isEditingTestItem = true;
        state.isApprovalTestItem = true;
        state.isApprovalTask = true;
        state.isDownloadReport = true;
      }

      break;

    case "quality":
      if (isQaTeam && (isQualityTestLevel || isQaMarket)) {
        state.isApprovalTask = true;
        state.isDownloadReport = true;
      }

      break;

    case "esg":
    default:
      state.isDownloadReport = true;
      return state;
      break;
  }

  return state;
};

export const useUserRoleUrl = (url: string) => {
  const { isBuyer, isSupplier, isLab } = useUserRole();

  if (isBuyer) {
    return `/${BUYER}${url}`;
  }

  if (isSupplier) {
    return `/${SUPPLIER}${url}`;
  }

  if (isLab) {
    return `/${LAB}${url}`;
  }

  return url;
};
