import { styled } from "@mui/material/styles";
import React, { memo } from "react";

import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
  Box,
  CircularProgress,
  FormHelperText,
  TextField,
  TextFieldProps,
} from "@mui/material";

export const AutocompleteWrapper = styled(Box, {
  shouldForwardProp: (props) => props !== "error" && props !== "fullWidth",
})<{
  error: boolean;
  fullWidth: boolean;
}>(({ theme, error, fullWidth }) => ({
  ".MuiFormControl-root label, .MuiFormHelperText-root": {
    color: error ? theme.palette.error.main : theme.palette.text.secondary,
  },
  ".MuiOutlinedInput-root fieldset": {
    borderColor: error ? theme.palette.error.main : "rgba(0, 0, 0, 0.23)",
  },
  ".Mui-focused": {
    color: error ? theme.palette.error.main : `${theme.palette.primary.main} !important`,
  },
  width: fullWidth ? "100%" : "auto",
}));

export interface AutocompleteOption {
  label: string | null;
  value?: string | null;
  inputValue?: string;
  disabled?: boolean;
  isNewValue?: boolean;
  id?: number | null;
}

export type DefaultAutocompleteProps = Omit<
  MuiAutocompleteProps<any, false, true, false>,
  "renderInput"
>;

export interface AutocompleteProps extends DefaultAutocompleteProps {
  helperText?: string | React.ReactNode | false;
  loading?: boolean;
  error?: boolean;
  required?: TextFieldProps["required"];
  value?: AutocompleteOption | string | null;
  label?: TextFieldProps["label"];
  options: AutocompleteOption[];
  onChange: (event: any, newValue: AutocompleteOption | null) => void;
}
function Autocomplete({
  helperText,
  placeholder,
  loading = false,
  error = false,
  required = false,
  value,
  label = "",
  options = [],
  onChange,
  fullWidth = false,
  ...rest
}: AutocompleteProps) {
  return (
    <AutocompleteWrapper fullWidth={fullWidth} error={error}>
      <MuiAutocomplete
        fullWidth={fullWidth}
        {...rest}
        onChange={(event, newValue: AutocompleteOption | null) => {
          onChange(event, newValue);
        }}
        options={options}
        value={value}
        getOptionDisabled={(option) => option?.disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            required={required}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />

      {helperText && (
        <FormHelperText
          sx={{
            marginTop: "3px",
            marginRight: "14px",
            marginBottom: 0,
            marginLeft: "14px",
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </AutocompleteWrapper>
  );
}

export default memo(Autocomplete);
