import { ReportResult, TaskReportResult } from "types/task";

export const DEPT_KEY = "dept";
export const VENDOR_KEY = "vendor";

export const CAP_REPORT_RESULT = {
  uploaded: "Uploaded",
  approved: "Approved",
  rejected: "Rejected",
  pending_approval: "Pending Approval",
  pending_upload: "Pending Upload CAP",
  accepted_fail_result: "Accepted fail result",
};

export const TEST_REPORT_RESULT = {
  approved: "Approved",
  concessional_approved: "Conditional Approved",
  rejected_update_report: "Rejected - Update Report",
  rejected_request_cap: "Rejected - Request CAP",
  pending_approval: "Pending Approval",
};

export const REPORT_RESULT = {
  pass: "Pass",
  fail: "Fail",
  informationOnly: "Information Only",
  notApplicable: "Not Applicable",
};

export const REPORT_RESULT_LIST = [
  ReportResult.Fail,
  ReportResult.Pass,
  ReportResult.InformationOnly,
  ReportResult.NotApplicable,
];

export const TEST_REPORT_RESULT_OPTIONS = [
  { label: "Approved", value: TaskReportResult.Approved },
  { label: "Conditional Approved", value: TaskReportResult.ConditionalApproved },
  { label: "Rejected - Update Report", value: TaskReportResult.RejectedUpdateReport },
  { label: "Rejected - Request CAP", value: TaskReportResult.RejectedRequestCap },
];

export const QC_TEST_REPORT_RESULT_OPTIONS = [
  { label: "Rejected - Update Report", value: TaskReportResult.RejectedUpdateReport },
  { label: "Rejected - Request CAP", value: TaskReportResult.RejectedRequestCap },
];

export const REPORT_RESULT_OPTIONS = [
  { label: "Pass", value: ReportResult.Pass },
  { label: "Fail", value: ReportResult.Fail },
  { label: "Information Only", value: ReportResult.InformationOnly },
  { label: "Not Applicable", value: ReportResult.NotApplicable },
];

export const TASK_STATUS_FILTER_MAPPING: Record<string, string> = {
  cancelled: "noOfCancelled",
  conditional_approved: "noOfConcessionalApproved",
  in_progress: "noOfInProgress",
  in_progress_result_report_updated: "noOfInProgressResultReportUpdated",
  waiting_for_lab_quote: "noOfWaitingForLabQuote",
  not_open_for_quote: "noOfNotOpenForQuote",
  pending: "noOfPending",
  quote_available: "noOfQuoteAvailable",
  quote_confirmed: "noOfQuoteConfirmed",
  sample_received: "noOfSampleReceived",
  sample_sent: "noOfSampleSent",
  completed: "noOfCompleted",
  completed_pending: "noOfCompletedPending",
  approved: "noOfApproved",
  fail: "noOfFail",
  cap_pending_approval: "noOfCapPendingApproval",
  cap_approved: "noOfCapApproved",
  cap_rejected: "noOfCapRejected",
  rejected_request_cap: "noOfRejectedRequestCap",
  rejected_update_report: "noOfRejectedUpdateReport",
  not_open_for_quote_retest: "noOfNotOpenForQuoteRetest",
};
