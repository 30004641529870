import { Box, CircularProgress } from "@mui/material";
import React from "react";

interface LoadingProps {
  sx?: any;
}

export default function Loading({ sx }: LoadingProps): JSX.Element {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...sx,
      }}
    >
      <CircularProgress />
    </Box>
  );
}
