import React from "react";
import classnames from "classnames";
import { AppBar as MuiAppBar, AppBarProps, ToolbarProps } from "@mui/material";
import Divider from "@mui/material/Divider";

interface IAppBarProps {
  sx?: AppBarProps["sx"];
  position?: AppBarProps["position"];
  color?: AppBarProps["color"];
  variant?: ToolbarProps["variant"];
  divider?: boolean;
  className?: string;
  id?: string;
  children?: React.ReactChild;
}

export default function AppBar({
  divider = false,
  variant,
  className,
  children,
  ...props
}: IAppBarProps) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiAppBar className={classnames(className)} {...props}>
      {children}
      {/* </Toolbar> */}
      {divider && <Divider />}
    </MuiAppBar>
  );
}
