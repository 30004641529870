import React, { memo, useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";

import { Box, IconButton, TextField, OutlinedInputProps } from "@mui/material";

import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";

import { textOrUnknown } from "utils";
import Text, { TextProps } from "components/core/Text";

const InputWrapper = styled(Box)({
  "& >": {
    height: "14px",
  },
});

const FlexWrapper = styled(Box)({
  display: "inline-flex",
});

const ReadOnlyWrapper = styled(Box)({
  display: "inline-flex",
  alignItems: "center",
});

const IconWrapper = styled(Box)({
  marginLeft: "8px",
});

export interface EditInputProps {
  readOnly?: boolean;
  disabled?: boolean;
  loading?: boolean;
  sx?: OutlinedInputProps["sx"];
  textProps?: Omit<TextProps, "children">;
  value: string;
  inputComponent: React.ReactNode;
  onSubmit: () => void;
  onClose: () => void;
}

function EditInput({
  sx,
  readOnly = false,
  disabled = false,
  loading = false,
  value = "",
  textProps,
  inputComponent,
  onSubmit,
  onClose,
}: EditInputProps) {
  const [isEdit, setEdit] = useState<boolean>(false);

  const handleClose = () => {
    setEdit(false);
    onClose();
  };

  const handleSubmit = async () => {
    await onSubmit();

    handleClose();
  };

  return (
    <FlexWrapper sx={sx}>
      {!readOnly && isEdit ? (
        <>
          <InputWrapper>{inputComponent}</InputWrapper>

          <IconWrapper>
            <IconButton color="primary" onClick={handleSubmit} disabled={disabled || loading}>
              <DoneIcon />
            </IconButton>

            <IconButton color="primary" onClick={handleClose} disabled={disabled || loading}>
              <CloseIcon />
            </IconButton>
          </IconWrapper>
        </>
      ) : (
        <ReadOnlyWrapper>
          <Text {...textProps}>{textOrUnknown(value)}</Text>

          {!readOnly && (
            <IconWrapper>
              <IconButton
                color="primary"
                onClick={() => setEdit(true)}
                disabled={disabled || loading}
              >
                <EditIcon />
              </IconButton>
            </IconWrapper>
          )}
        </ReadOnlyWrapper>
      )}
    </FlexWrapper>
  );
}

export default memo(EditInput);
