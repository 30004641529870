import React from "react";

import { BasicChip, BasicChipProps } from "components/core";

import { ApprovalStatus } from "constants/item";

export interface ApprovalStatusChipProps extends BasicChipProps {
  fontSize?: BasicChipProps["fontSize"];
  variant?: BasicChipProps["variant"];
  approved: boolean;
}

export default function ApprovalStatusChip({
  fontSize = "text",
  variant = "filled",
  approved = false,
  ...rest
}: ApprovalStatusChipProps) {
  return (
    <BasicChip
      {...rest}
      dense
      square
      fontSize={fontSize}
      variant={variant}
      color={approved ? "success" : "error"}
      label={approved ? ApprovalStatus.Approved : ApprovalStatus.NotApproved}
    />
  );
}
