import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "state/hooks";

import { setAllSku } from "state/sku/actions";
import { SkuState } from "state/sku/reducer";

export function useAllSkuState(): [
  allSkuState: SkuState["allSku"],
  setAllSkuState: (allSku: SkuState["allSku"]) => void,
] {
  const dispatch = useAppDispatch();

  const allSkuState = useAppSelector((state) => state.sku.allSku);

  const setAllSkuState = useCallback(
    (allSku: SkuState["allSku"]) => dispatch(setAllSku(allSku)),
    [dispatch],
  );

  return [allSkuState, setAllSkuState];
}
