import React from "react";

import { Box, useTheme } from "@mui/material";

import { BasicChip, BasicChipProps } from "components/core";

export default function PendingReuploadChip({
  fontSize = "text",
  variant = "filled",
  ...rest
}: BasicChipProps) {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <BasicChip
        {...rest}
        sx={{
          borderRadius: "4px",
          color: theme.palette.warning.main,
          background: theme.palette.warning.lighter as string,
        }}
        fontSize={fontSize}
        variant={variant}
        label="Pending Re-upload"
      />
    </Box>
  );
}
