import { AutocompleteOption } from "components/core";
import { TestLevelOption } from "types/sku-group";
import { TestLevel } from "types/test-item";

export const DEFAULT_PRIME_SKU_CHECKED_STATE = {
  A: true,
  F: true,
  L: true,
  P: true,
};

export const QUALITY_PRIME_SKU_CHECKED_STATE = {
  Q: true,
  C: true,
};

export const REGULAR_TESTING_LEVEL_STATE = {
  A: false,
  F: false,
  L: false,
  P: false,
};

export const QUALITY_TESTING_LEVEL_STATE = {
  Q: false,
  C: false,
};

export const DEFAULT_TESTING_LEVEL_STATE = {
  ...REGULAR_TESTING_LEVEL_STATE,
  ...QUALITY_TESTING_LEVEL_STATE,
};

export const REGULAR_TESTLEVEL_CHECKBOX_OPTIONS: TestLevelOption[] = [
  {
    label: TestLevel.A,
    value: TestLevel.A,
  },
  {
    label: TestLevel.F,
    value: TestLevel.F,
  },
  {
    label: TestLevel.L,
    value: TestLevel.L,
  },
  {
    label: TestLevel.P,
    value: TestLevel.P,
  },
];

export const QUALITY_TESTLEVEL_CHECKBOX_OPTIONS: TestLevelOption[] = [
  {
    label: TestLevel.Q,
    value: TestLevel.Q,
  },
  {
    label: TestLevel.C,
    value: TestLevel.C,
  },
];

export const DEFAULT_GROUPING_FILTER_STATE = {
  colorCode: "",
  sizeCode: "",
  sort: null,
};

export const SORT_OPTIONS: AutocompleteOption[] = [
  {
    label: "Asc",
    value: "asc",
  },
  {
    label: "Desc",
    value: "desc",
  },
];
