import React from "react";

import { BasicChip, BasicChipProps } from "components/core";

export default function CurrentSkuChip({
  label = "CURRENT",
  fontSize = "text",
  ...rest
}: BasicChipProps) {
  return (
    <BasicChip
      {...rest}
      sx={{
        fontWeight: "500",
        width: "max-content",
        background: "#B6E3D3",
        color: "#0DA16C",
        border: "1px solid #0DA16C",
      }}
      dense
      square
      label={label}
      fontSize={fontSize}
    />
  );
}
