import moment from "moment-timezone";
import { DateFormat } from "types/global";

const PACIFIC_TIME_ZONE = "America/Los_Angeles";

export const formatDate = (
  value: string | Date | moment.Moment | null | undefined,
  { format = DateFormat.DD_MMM_YYYY } = {},
): string => {
  if (!value) return "";

  return moment(value).tz(moment.tz.guess()).format(format);
};

export const convertDateFormat = (
  value: string | Date | null,
  previousFormat?: string,
  format?: DateFormat,
): string => {
  if (!value) return "";

  return moment(value, previousFormat).format(format || DateFormat.YYYY_MM_DD);
};

export const formatTimestamp = (timestamp: number, format = DateFormat.DD_MMM_YYYY_H_MM_A) => {
  const time = moment(timestamp);

  // return time.tz(PACIFIC_TIME_ZONE).format(format);
  return time.format(format);
};

export const formatDateTime = (
  value: string | Date | null,
  format = DateFormat.DD_MMM_YYYY_H_MM_A,
) => {
  if (!value) return value;

  return formatDate(value, {
    format,
  });
};
export const formatLogDateTime = (value: string | null, format = DateFormat.DD_MMM_YYYY_H_MM_A) => {
  if (!value) return value;

  return moment(value, "YYYY-MM-DD HH-mm-ss").format(format);
};

export const formatPeriodDate = (
  value: string | null,
  period: number,
  format = DateFormat.YYYY_MM_DD,
) => {
  if (!value) return value;

  const periodDate = moment(value, format).add(period, "years");

  return formatDate(periodDate);
};

export const getCurrentDateTime = (date: string | Date | null) => {
  return moment(date).set({ hour: moment().hour(), minute: moment().minute() }).format();
};
