import { Country } from "types/global";
import { BOMType, Concept, CountryCode, DcCode, QuoteStatus, SKUStatus } from "types/item";

export enum TASK_STATUS_FILTER_TYPE {
  ImportantStatus = "importantStatus",
  OtherStatus = "moreStatus",
}

export enum DelayIn {
  Day = "day",
  Week = "week",
  Month = "month",
}

export const DELAY_IN_OPTIONS = [
  {
    label: "Day(s)",
    value: DelayIn.Day,
  },
  {
    label: "Week(s)",
    value: DelayIn.Week,
  },
  {
    label: "Month(s)",
    value: DelayIn.Month,
  },
];

export const EXPORT_MARKETS = [Country.US, Country.UK, Country.EU, Country.QC];

export const COUNTRY_OPTIONS = [
  {
    label: Country.US,
    value: Country.US,
    disabled: false,
  },
  {
    label: Country.UK,
    value: Country.UK,
    disabled: false,
  },
  {
    label: Country.EU,
    value: Country.EU,
    disabled: false,
  },
];

export const COUNTRY_OPTIONS_WITH_QC = [
  ...COUNTRY_OPTIONS,
  {
    label: Country.QC,
    value: Country.QC,
    disabled: false,
  },
];

export const CONCEPT = {
  CORE: "Core",
  MODERN: "Modern",
  BABY_AND_CHILD: "Baby & Child",
  TEEN: "Teen",
};

export const CONCEPT_OPTIONS = [
  {
    label: CONCEPT.CORE,
    value: Concept.Core,
  },
  {
    label: CONCEPT.MODERN,
    value: Concept.Modern,
  },
  {
    label: CONCEPT.BABY_AND_CHILD,
    value: Concept.BabyAndChild,
  },
  {
    label: CONCEPT.TEEN,
    value: Concept.Teen,
  },
];

export const COUNTRY_CODE = {
  US: `(${CountryCode.US}) US`,
  EU: `(${CountryCode.EU}) EU`,
  UK: `(${CountryCode.UK}) UK`,
};

export const COUNTRY_CODE_OPTIONS = [
  {
    label: COUNTRY_CODE.US,
    value: CountryCode.US,
  },
  {
    label: COUNTRY_CODE.EU,
    value: CountryCode.EU,
  },
  {
    label: COUNTRY_CODE.UK,
    value: CountryCode.UK,
  },
];

export const DC_CODE = {
  ODC: `${DcCode.ODC}: OHIO- ODC`,
  SFDC: `${DcCode.SFDC}: PATTERSON - SFDC`,
  LADC: `${DcCode.LADC}: ONTARIO - LADC`,
  MDC: `${DcCode.MDC}: NORTH EAST - MDC`,
  DC: `${DcCode.DC}: EU/UK - Belgium DC`,
};

export const DC_CODE_OPTIONS = [
  {
    label: DC_CODE.ODC,
    value: DcCode.ODC,
  },
  {
    label: DC_CODE.SFDC,
    value: DcCode.SFDC,
  },
  {
    label: DC_CODE.LADC,
    value: DcCode.LADC,
  },
  {
    label: DC_CODE.MDC,
    value: DcCode.MDC,
  },
  {
    label: DC_CODE.DC,
    value: DcCode.DC,
  },
];

export const SKU_STATUS = {
  A: "Active",
  D: "Discontinued",
  S: "Swatches",
  Q: "Pending",
  X: "Discontinued with no inventory",
  P: "",
  N: "",
};

export const SKU_STATUS_OPTIONS = [
  {
    label: `${SKUStatus.A} (${SKU_STATUS.A})`,
    value: SKUStatus.A,
  },
  {
    label: `${SKUStatus.D} (${SKU_STATUS.D})`,
    value: SKUStatus.D,
  },
  {
    label: `${SKUStatus.S} (${SKU_STATUS.S})`,
    value: SKUStatus.S,
  },
  {
    label: `${SKUStatus.Q} (${SKU_STATUS.Q})`,
    value: SKUStatus.Q,
  },
  {
    label: `${SKUStatus.X} (${SKU_STATUS.X})`,
    value: SKUStatus.X,
  },
  {
    label: `${SKUStatus.P}`,
    value: SKUStatus.P,
  },
  {
    label: `${SKUStatus.N}`,
    value: SKUStatus.N,
  },
];

export const BOM_TYPE = {
  NA: "(NA) Sold online as is",
  S: "(S) Sold sparately but also with another item",
  BS: "(BS) BOM set header, SKU sold online",
  C: "(C) Sold with other items to make a complete frame",
  B: "(B) BOM header, SKU sold online",
  H: "(H) BOM header, SKU sold online",
  M: "(M) SKUs customers can mongram",
};

export const BOM_TYPE_OPTIONS = [
  {
    label: BOM_TYPE.NA,
    value: BOMType.NA,
  },
  {
    label: BOM_TYPE.S,
    value: BOMType.S,
  },
  {
    label: BOM_TYPE.BS,
    value: BOMType.BS,
  },
  {
    label: BOM_TYPE.C,
    value: BOMType.C,
  },
  {
    label: BOM_TYPE.B,
    value: BOMType.B,
  },
  {
    label: BOM_TYPE.H,
    value: BOMType.H,
  },
  {
    label: BOM_TYPE.M,
    value: BOMType.M,
  },
];

export const TASK_STATUS = {
  no_grouping: "No Grouping",
  empty_task: "Empty Task",
  not_started: "Not Started",
  not_open_for_quote: "Task Pending",
  not_open_for_quote_retest: "Task Pending (Retest)",
  waiting_for_lab_quote: "Waiting for Lab Quote",
  quote_confirmed: "Quote Confirmed",
  quote_available: "Quote Available",
  audit_in_progress: "Test in Progress",
  in_progress_result_report_updated: "In Progress - Update Result & Reports",
  pending: "Pending",
  in_progress: "In Progress",
  sample_received: "Sample Received",
  sample_sent: "Sample Sent",
  cancelled: "Cancelled",
  completed: "Completed",
  completed_pending: "Completed - Pending",
  fail: "Completed - Fail",
  rejected: "Rejected",
  // for cap status
  cap_rejected: "CAP Rejected",
  cap_approved: "CAP Approved",
  cap_pending_upload: "Pending Upload CAP",
  cap_accepted: "CAP Accepted",
  cap_pending_approval: "CAP Pending Approval",
  // for task report result
  rejected_update_report: "Rejected - Update Report",
  rejected_request_cap: "Rejected - Request CAP",
  conditional_approved: "Complete - Conditional Approved",
  concessional_approved: "Complete - Conditional Approved",
  approved: "Complete - Approved",
  notApplicable: "Not Applicabled",
};

export enum ApprovalStatus {
  Approved = "Approved",
  NotApproved = "Not Approved",
}

export const QUOTE_STATUS: {
  [key: string]: {
    label: string;
    value: QuoteStatus;
  };
} = {
  accepted: { label: "Accepted", value: QuoteStatus.Acepted },
  pending: { label: "Pending", value: QuoteStatus.Pending },
  rejected: { label: "Rejected", value: QuoteStatus.Rejected },
};

export const TEST_REPORT_APPROVAL_OPTIONS = [
  {
    label: "Approve",
    value: "approve",
  },
  {
    label: "Conditional Approve",
    value: "concessional_approve",
  },
  {
    label: "Reject",
    value: "reject",
  },
];

export const ITEM_MAPPING_NAME: { [key: string]: string } = {
  id: "",
  masterWicFiles: "",
  taskRequirements: "",
  exportMarket: "Export Market",

  skuStatus: "SKU Status",
  wicNumber: "SKU SrcNum",

  usValidityDate: "US Validity Date",
  euValidityDate: "EU Validity Date",
  ukValidityDate: "UK Validity Date",

  usStatus: "US Status",
  euStatus: "EU Status",
  ukStatus: "UK Status",
  qcStatus: "QC Status",

  usTaskStatus: "US Task Status",
  euTaskStatus: "EU Task Status",
  ukTaskStatus: "UK Task Status",
  qcTaskStatus: "QC Task Status",

  usMinPoCancelDate: "US Min PO Cancel Date",
  euMinPoCancelDate: "EU Min PO Cancel Date",
  ukMinPoCancelDate: "UK Min PO Cancel Date",

  ukCompletedStatus: "UK Completed Status",
  euCompletedStatus: "EU Completed Status",
  usCompletedStatus: "US Completed Status",
  qcCompletedStatus: "QC Completed Status",

  skuCreateDate: "SKU Create Date",
  startSellingDate: "Start Selling Date",

  buyerDesc: "Buyer Description",

  deptNum: "Department Number",
  deptDesc: "Department Description",
  subDeptDesc: "Subdepartment Description",

  countryOfOrigin: "Country Of Origin",
  countryCodeList: "Country Code List",
  complianceDocRemark: "Compliance Doc Remark",

  concept: "Concept",
  collection: "Collection",
  frame: "Frame",
  colorCode: "ColorCode",
  sizeCode: "SizeCode",
  fabric: "Fabric",
  fill: "Fill",
  finish: "Finish",
  function: "Function",
  bom: "BOM",

  dcList: "DC List",

  productImages: "Product Images",
  attachments: "Attachments",

  vendorNum: "Vendor Number",
  vendorDesc: "Vendor Description",
  vendorItemDesc: "Vendor Item Description",
  vendorStyle: "Vendor Style",

  hasProp65: "Has Prop 65",
  prop65Remark: "Props 65 Remark",

  // submission log
  submissionNumber: "Submission Number",
  awbNumber: "AWB Number",
  sampleSubmitterContactName: "Sample Submitter Contact Name",
  sampleSubmitterEmail: "Sample Submitter Email",
  sampleSubmitterPhone: "Sample Submitter Phone",
  sampleSendingDate: "Sample Sending Date",
  trackingNumber: "Tracking Number",
  sampleCompletionDate: "Sample Completion Date",
  sampleReceiverName: "Sample Receiver Name",
  sampleReceiverEmail: "Sample Receiver Email",
  sampleReceiverPhone: "Sample Receiver Phone",
  sampleReceivedDate: "Sample Received Date",
  currency: "Currency",
  invoiceCurrency: "Invoice Currency",
  billingCurrency: "Billing Currency",
  sampleSize: "SampleSize",
  tat: "Tat",
  isPackagePrice: "Package Price",
  packagePrice: "Package Price",
  packageRemark: "Package Remark",
  remarks: "Remarks",
  payerCompanyName: "payer Company Name",
  payerCompanyAddress: "Payer Company Address",
  payerContactPerson: "Payer Contact Person",
  payerEmail: "Payer Email",
  payerPhoneNumber: "Payer Phone Number",
  newLab: "New Lab",
  report: "Report",

  moreStatus: "More Status",
  importantStatus: "Important Status",
  isPrimeSku: "Show Existing Prime SKU",
};

export const ACTION_CHANGE_LOG_OPTIONS = [
  { label: "Create", value: "CREATE" },
  { label: "Edit", value: "EDIT" },
  { label: "Archive", value: "ARCHIVE" },
];

export const CHANGE_TYPE_CHANGE_LOG_OPTIONS = [
  { label: "Test Item", value: "TEST_ITEM" },
  { label: "Test Matrix", value: "TEST_MATRIX" },
];
