import { useCallback } from "react";
import { useAppSelector, useAppDispatch } from "state/hooks";
import { AppState } from "state";

import { setError, setSuccess } from "state/application/actions";
import { ApplicationState } from "state/application/reducer";

export function useAppError(): [
  appError: ApplicationState["error"],
  setAppError: ({ code, message }: ApplicationState["error"]) => void,
] {
  const dispatch = useAppDispatch();

  const appError = useAppSelector((state: AppState) => state.application.error);

  const setAppError = useCallback(
    ({ code, message }: ApplicationState["error"]) => dispatch(setError({ code, message })),
    [dispatch],
  );

  return [appError, setAppError];
}

export function useAppSuccess(): [
  appSuccess: ApplicationState["success"],
  setAppSuccess: ({ message }: ApplicationState["success"]) => void,
] {
  const dispatch = useAppDispatch();

  const appSuccess = useAppSelector((state: AppState) => state.application.success);

  const setAppSuccess = useCallback(
    ({ message }: ApplicationState["success"]) => dispatch(setSuccess({ message })),
    [dispatch],
  );

  return [appSuccess, setAppSuccess];
}
