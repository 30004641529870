import { useCallback, useEffect, useMemo, useState } from "react";
import { usePagination } from "hooks";
import { FilterOptions, Order } from "types/global";
import { useAppError } from "state/application/hooks";
import { INITIAL_ROW_PER_PAGE } from "constants/table";
import { getMappedFilterOptions } from "utils/table";

interface FetchListPayload extends FilterOptions {
  isApplyfilter?: boolean;
  isApplySort?: boolean;
}

export function useListParams({ fetchListData, fetchListOptions }: any) {
  const [listData, setListData] = useState<any[]>([]);
  const [filterOptions, setFilterOptions] = useState<Record<string, string[]>>({});

  const [isFetchPageData, setIsFetchPageData] = useState<boolean>(false);
  const [isSearchFilter, setIsSearchFilter] = useState<boolean>(false);

  const [filterSelectInput, setFilterSelectInput] = useState<Record<string, string[]>>({});
  const [keyword, setKeyword] = useState<string>("");
  const [sort, setSort] = useState<{ order: Order; orderBy: string }>({
    order: "desc",
    orderBy: "",
  });

  const [, setAppError] = useAppError();
  const { pagination, setPagination } = usePagination();

  const getConvertedFilterOptions = useMemo(() => {
    const convertedFilters = getMappedFilterOptions(filterSelectInput);

    return convertedFilters;
  }, [filterSelectInput]);

  const handleListSort = useCallback((name: string, orderType?: Order | null) => {
    setSort(() => ({ orderBy: name, order: (orderType as Order) ?? "" }));
    setIsSearchFilter(true);
  }, []);

  const handleListFilters = (name: string, selected: string[]) => {
    setFilterSelectInput((state: any) => {
      const updated = () => {
        if (selected?.length) {
          return selected?.filter(
            (item, index, arr) => arr?.map((val) => val)?.indexOf(item) === index,
          );
        }

        return selected;
      };

      return {
        ...state,
        [name]: updated(),
      };
    });
    setIsSearchFilter(true);
  };

  const handleRowPerPageChange = useCallback((pageSize: number) => {
    setPagination((state) => ({ ...state, rowsPerPage: pageSize, page: 0 }));
    setIsSearchFilter(true);
  }, []);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    const isNewNextPage =
      newPage > pagination.page && pagination.rowsPerPage * newPage >= listData.length;

    setPagination((state) => ({ ...state, page: newPage }));

    if (isNewNextPage) setIsFetchPageData(true);
  }, []);

  const fetchPOListData = async ({
    currentPage = 1,
    rowsPerPage = INITIAL_ROW_PER_PAGE,
    filters = [],
    order = sort.order,
    orderBy = sort.orderBy,
    search = keyword,
    isPage = true,
    isApplyfilter = false,
  }: FetchListPayload) => {
    // todo: get header filter options

    await fetchListData({
      currentPage,
      filters,
      isPage,
      order,
      orderBy,
      rowsPerPage,
      search,
    }).then((res: any) => {
      if (res?.data?.code !== 200) {
        setAppError({ code: res?.data?.code, message: res?.data?.message });

        return;
      }

      if (currentPage === 1 || currentPage === pagination.page) {
        fetchListOptions({
          currentPage,
          filters,
          isPage,
          order,
          orderBy,
          rowsPerPage,
          search: "",
        }).then((optionsRes: any) => {
          if (optionsRes?.data?.code !== 200) {
            setAppError({ code: optionsRes?.data?.code, message: optionsRes?.data?.message });
            return;
          }

          const { data: optionsData } = optionsRes.data;
          setFilterOptions(optionsData);
        });
      }

      const { list, page } = res.data.data;

      if (isSearchFilter || isApplyfilter) {
        setPagination((state) => ({ ...state, page: 0 }));
        setListData(list);
      } else {
        setListData(listData.concat(list));
      }

      setPagination((state) => ({ ...state, totalRecords: page?.totalRecords }));

      setIsFetchPageData(false);
      setIsSearchFilter(false);
    });
  };

  const initialFetchListData = () => {
    fetchPOListData({
      currentPage: 1,
      rowsPerPage: INITIAL_ROW_PER_PAGE,
      filters: [],
      order: sort.order,
      orderBy: sort.orderBy,
      search: "",
    });
  };

  return {
    initialPayload: {
      currentPage: 1,
      rowsPerPage: INITIAL_ROW_PER_PAGE,
      filters: [],
      order: sort.order,
      orderBy: sort.orderBy,
      search: "",
    },
    isSearchFilter,
    listData,
    keyword,
    isFetchPageData,
    pagination,
    filterSelectInput,
    sort,
    getConvertedFilterOptions,
    filterOptions,
    setIsSearchFilter,
    fetchPOListData,
    handleListFilters,
    handleListSort,
    handleRowPerPageChange,
    initialFetchListData,
    handleChangePage,
  };
}
