import { History } from "types";
import { Country } from "./global";
import { Subcategory, SubcategoryTag } from "./subcategory";
import { ReportResult } from "./task";

export enum TestLevel {
  A = "A",
  F = "F",
  L = "L",
  P = "P",
  Q = "Q",
  C = "C",
}

export enum TestItemStatus {
  Draft = "DRAFT",
  Active = "ACTIVE",
  PendningApproved = "PENDING_APPROVED",
  Archived = "ARCHIVED",
}

export interface TestItem {
  id: number;
  testLevel: TestLevel | null;
  testLine: string | null;
  testDesc: string | null;
  itemCode: string | null;
  status: TestItemStatus | null;
  comment: string | null;
  remark: string | null;
  brand: string | null;
  country: string | null;
  lastUpdated: string | null;
  // for change request edit
  changeRequestId: number;
  isEditing: boolean;
  // for listing
  subCategory: string | null;
  // for change request
  subCategories: Subcategory[];
  // for change request detail
  subCategoryIds: number[];
  subcategoryTags: SubcategoryTag[];

  action?: string; // for action button
}

export interface CreateTestMatrixData {
  saveDraft: boolean;
  brand: string;
  subCategory: string;
  country: string; // Country
  remark: string;
  testItemIds: number[];
}

export interface Comment {
  id: number;
  user: string | null;
  createdTime: string | null;
  comment: string | null;
}

export type TestItemHistory = History<TestItem>;
