import React from "react";
import { styled } from "@mui/material/styles";

import { TableCell, TableCellProps } from "@mui/material";

const CustomBorderCell = styled(TableCell, {
  shouldForwardProp: (props) => props !== "color" && props !== "collapseX" && props !== "collapseY",
})<{
  color: "primary" | "secondary";
  collapseX?: "left" | "right" | "both";
  collapseY?: "top" | "bottom" | "both";
}>(({ theme, color, collapseX, collapseY }) => ({
  border: `1px solid ${
    color === "secondary" ? theme.palette.text.secondary : theme.palette.text.primary
  }`,
  borderCollapse: "collapse",
  borderLeft: collapseX && ["left", "both"].includes(collapseX) ? 0 : "normal",
  borderRight: collapseX && ["right", "both"].includes(collapseX) ? 0 : "normal",
  borderTop: collapseY && ["top", "both"].includes(collapseY) ? 0 : "normal",
  borderBottom: collapseY && ["bottom", "both"].includes(collapseY) ? 0 : "normal",
  alignItems: "center",
  padding: "10px",
}));

export interface TableBorderCellProps extends TableCellProps {
  children?: React.ReactElement | React.ReactNode;
  color?: "primary" | "secondary";
  collapseX?: "left" | "right" | "both";
  collapseY?: "top" | "bottom" | "both";
}

export default function TableBorderCell({
  children,
  color = "secondary",
  ...rest
}: TableBorderCellProps) {
  return (
    <CustomBorderCell color={color} {...rest}>
      {children}
    </CustomBorderCell>
  );
}
