/* eslint-disable no-nested-ternary */
import React from "react";
import { styled, Box } from "@mui/material";
import { ActiveStatus } from "types/global";
import { ACTIVE_STATUS } from "constants/global";

export enum ActiveStatusDotSize {
  Small = "8px",
  Medium = "10px",
  Large = "12px",
}

export const DotWrapper = styled("span")({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "flex-start",
});

const StyledDot = styled(Box, {
  shouldForwardProp: (props) => props !== "status" && props !== "size",
})<{
  status: ActiveStatus | null;
  size: ActiveStatusDotProps["size"];
}>(({ theme, status, size }) => ({
  display: "inline-flex",
  width:
    size === "small"
      ? ActiveStatusDotSize.Small
      : size === "medium"
      ? ActiveStatusDotSize.Medium
      : ActiveStatusDotSize.Large,
  height:
    size === "small"
      ? ActiveStatusDotSize.Small
      : size === "medium"
      ? ActiveStatusDotSize.Medium
      : ActiveStatusDotSize.Large,
  borderRadius: "100%",
  backgroundColor:
    status === ActiveStatus.Active
      ? theme.palette.success.main
      : status === ActiveStatus.InProgress
      ? theme.palette.warning.main
      : theme.palette.error.main,
}));

export interface ActiveStatusDotProps {
  size?: "small" | "medium" | "large";
  text?: boolean;
  status: ActiveStatus | null;
}

export default function ActiveStatusDot({
  size = "medium",
  status,
  text,
  ...rest
}: ActiveStatusDotProps) {
  return (
    <DotWrapper>
      <StyledDot size={size} status={status} {...rest} />

      {text && status && <Box sx={{ marginLeft: 1 }}>{ACTIVE_STATUS[status]}</Box>}
    </DotWrapper>
  );
}
