/* eslint-disable no-nested-ternary */
import { Box, styled, useTheme } from "@mui/material";
import React from "react";

import { BasicChip, BasicChipProps } from "components/core";

import { TestItemStatus } from "types/test-item";

import { TEST_ITEM_STATUS } from "constants/test-item";

enum StatusDotSize {
  SemiSmall = "4px",
  Small = "8px",
  SemiMedium = "5px",
  Medium = "10px",
  SemiLarge = "6px",
  Large = "12px",
}

enum StatusDotTriangleSize {
  SemiSmall = "6px",
  Small = "10px",
  SemiMedium = "7.5px",
  Medium = "15px",
  SemiLarge = "8px",
  Large = "18px",
}

const StyledDot = styled(Box, {
  shouldForwardProp: (props) =>
    props !== "triangle" && props !== "status" && props !== "size" && props !== "dotReversed",
})<{
  triangle: boolean;
  status: TestItemStatus | null;
  size: BasicChipProps["fontSize"];
  dotReversed: boolean;
}>(({ theme, status, size, dotReversed, triangle }) => ({
  display: "inline-flex",
  flex: dotReversed ? 1 : 2,

  borderLeft: triangle
    ? `${
        size === "info"
          ? StatusDotSize.SemiSmall
          : size === "text"
          ? StatusDotSize.SemiMedium
          : StatusDotSize.SemiLarge
      } solid transparent`
    : "unset",
  borderRight: triangle
    ? `${
        size === "info"
          ? StatusDotSize.SemiSmall
          : size === "text"
          ? StatusDotSize.SemiMedium
          : StatusDotSize.SemiLarge
      } solid transparent`
    : "unset",
  borderBottom: triangle
    ? `${
        size === "info"
          ? StatusDotTriangleSize.SemiSmall
          : size === "text"
          ? StatusDotTriangleSize.SemiMedium
          : StatusDotTriangleSize.SemiLarge
      } solid ${theme.palette.grey[400]}`
    : "unset",

  width:
    size === "info"
      ? StatusDotSize.Small
      : size === "text"
      ? StatusDotSize.Medium
      : StatusDotSize.Large,
  height:
    size === "info"
      ? StatusDotSize.Small
      : size === "text"
      ? StatusDotSize.Medium
      : StatusDotSize.Large,
  borderRadius: triangle ? "unset" : "100%",
  backgroundColor: triangle
    ? "unset"
    : [TestItemStatus.Draft, TestItemStatus.Archived].includes(status as TestItemStatus)
    ? theme.palette.grey[400]
    : [TestItemStatus.PendningApproved].includes(status as TestItemStatus)
    ? theme.palette.warning.main
    : [TestItemStatus.Active].includes(status as TestItemStatus)
    ? theme.palette.primary.light
    : theme.palette.error.main,
}));
export interface TestItemStatusChipProps extends BasicChipProps {
  triangle?: boolean;
  dot?: boolean;
  dotReversed?: boolean;
  labelDisabled?: boolean;
  labelColorDisabled?: boolean;
  status: TestItemStatus;
}

export default function TestItemStatusChip({
  fontSize = "text",
  variant = "outlined",
  triangle = false,
  dot = false,
  dotReversed = false,
  status,
  labelDisabled = false,
  labelColorDisabled = false,
  ...rest
}: TestItemStatusChipProps) {
  const theme = useTheme();

  const handleStatusConfig = (
    itemStatus: TestItemStatus,
  ): {
    label: typeof TEST_ITEM_STATUS[TestItemStatus] | "";
    color: string;
    background: string;
  } => {
    const config: {
      label: typeof TEST_ITEM_STATUS[TestItemStatus] | "";
      color: string;
      background: string;
    } = {
      label: "",
      color: "",
      background: "",
    };

    switch (itemStatus) {
      case TestItemStatus.Draft:
        config.label = TEST_ITEM_STATUS.DRAFT;
        config.color = "#6936F8";
        config.background = "#F3EFFB";
        break;

      case TestItemStatus.Active:
        config.label = TEST_ITEM_STATUS.ACTIVE;
        config.color = "#0DA16C";
        config.background = "#E7F9F2";
        break;

      case TestItemStatus.PendningApproved:
        config.label = TEST_ITEM_STATUS.PENDING_APPROVED;
        config.color = theme.palette.secondary.main;
        config.background = theme.palette.secondary.lighter as string;
        break;

      case TestItemStatus.Archived:
        config.label = TEST_ITEM_STATUS.ARCHIVED;
        config.color = theme.palette.secondary.main;
        config.background = theme.palette.secondary.lighter as string;
        break;

      default:
    }

    return config;
  };

  const { label, color, background } = handleStatusConfig(status);

  return (
    <Box sx={{ display: labelDisabled ? "block" : "flex", alignItems: "center" }}>
      {!labelDisabled && (
        <BasicChip
          {...rest}
          sx={{
            borderRadius: "4px",
            color,
            background,
          }}
          fontSize={fontSize}
          variant={variant}
          label={label}
        />
      )}

      {dot && (
        <StyledDot triangle={triangle} dotReversed={dotReversed} size={fontSize} status={status} />
      )}
    </Box>
  );
}
