import { styled, useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";

import AccountCircle from "@mui/icons-material/AccountCircle";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";

import IcwLogo from "assets/images/icw_logo_white.svg";

import { NavDesktopLink, NavLogoLink, Text } from "components/core";
import {
  BUYER_ROLE_KEY,
  DRAWER_HEADER_WIDTH,
  STORAGE_EMAIL_KEY,
  STORAGE_ROLE_KEY,
  STORAGE_TOKEN_KEY,
} from "constants/global";
import ROUTES from "constants/routes";

export interface HeaderPage {
  name: string;
  href: ROUTES | string;
  icon: React.ReactNode;
}
export interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${DRAWER_HEADER_WIDTH}px)`,
    marginLeft: `${DRAWER_HEADER_WIDTH}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const StyledToolbar = styled(Toolbar)({
  height: "50px",
  maxHeight: "50px",
  minHeight: "inherit !important",
});

const StlyedDrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export const DrawerListItem = styled(ListItem, {
  shouldForwardProp: (props: any) => props !== "active",
})<{
  active: boolean;
}>(({ theme, active }) => ({
  textDecoration: "none",
  cursor: "pointer",
  backgroundColor: active ? "rgba(0, 0, 0, 0.04) !important" : "inherit",
}));

export interface DrawerHeaderProps {
  open: boolean;
  pages: HeaderPage[];
  onDrawerOpen: () => void;
  onDrawerClose: () => void;
}

export default function DrawerHeader({
  open = false,
  pages,
  onDrawerOpen,
  onDrawerClose,
}: DrawerHeaderProps) {
  const navigate = useNavigate();
  const theme = useTheme();

  const profileMenuId = "profile-menu";

  const [anchorElProfile, setAnchorElProfile] = React.useState<null | HTMLElement>(null);

  const isProfileMenuOpen = Boolean(anchorElProfile);
  const [userEmail, setUserEmail] = useState<string | null>();

  useEffect(() => {
    setUserEmail(localStorage.getItem(STORAGE_EMAIL_KEY));
  }, [localStorage.getItem(STORAGE_EMAIL_KEY)]);

  const isActivePage = (route: string) => {
    // // set sku list as active if in sku profile
    // if (window.location.pathname.includes(ROUTES.SKU_PROFILE) && route === ROUTES.SKU_NEW_LIST)
    //   return true;

    return window.location.pathname === route;
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElProfile(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorElProfile(null);
  };

  const handleLogout = () => {
    localStorage.removeItem(STORAGE_EMAIL_KEY);
    localStorage.removeItem(STORAGE_TOKEN_KEY);
    localStorage.removeItem(BUYER_ROLE_KEY);
    localStorage.removeItem(STORAGE_ROLE_KEY);
    navigate(ROUTES.LOGIN);
  };

  const handleDrawerLink = (href: string) => {
    onDrawerClose();
    navigate(href);
  };

  const profileMenu = [
    {
      name: "Logout",
      onClick: handleLogout,
    },
  ];

  const renderProfileMenu = (
    <Menu
      anchorEl={anchorElProfile}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={profileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isProfileMenuOpen}
      onClose={handleProfileMenuClose}
    >
      {profileMenu?.map((menuItem, index) => (
        <MenuItem key={index} onClick={menuItem.onClick}>
          {menuItem.name}
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <>
      <StyledAppBar position="fixed" open={open}>
        <StyledToolbar>
          <NavLogoLink
            href="/"
            sx={{
              order: {
                xs: 1,
                md: 0,
              },
              flexGrow: 1,
            }}
          >
            <img src={IcwLogo} alt="ICW Logo" />
          </NavLogoLink>

          <Box
            sx={{
              order: 0,
              display: { xs: "flex", md: "none" },
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={onDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Box sx={{ ml: "24px", flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <NavDesktopLink key={page.name} href={page.href} active={isActivePage(page.href)}>
                {page.name}
              </NavDesktopLink>
            ))}
          </Box>

          <Box sx={{ order: 1, flexGrow: 1 }} />
          <Box
            sx={{
              order: 2,
            }}
          >
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={profileMenuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
              <Text bold size="text" color="white" sx={{ marginLeft: "10px" }}>
                {userEmail}
              </Text>
            </IconButton>
          </Box>
        </StyledToolbar>
      </StyledAppBar>

      <Drawer
        sx={{
          width: DRAWER_HEADER_WIDTH,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: DRAWER_HEADER_WIDTH,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <StlyedDrawerHeader>
          <IconButton onClick={onDrawerClose}>
            {theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </StlyedDrawerHeader>

        <Divider />

        <List>
          {pages.map((page) => (
            <DrawerListItem
              key={page.href}
              active={isActivePage(page.href)}
              onClick={() => handleDrawerLink(page.href)}
            >
              <ListItemIcon>{page.icon}</ListItemIcon>
              <ListItemText primary={page.name} />
            </DrawerListItem>
          ))}
        </List>
      </Drawer>

      {renderProfileMenu}
    </>
  );
}
