import { createTheme, alpha, getContrastRatio } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    violet: Palette["primary"];
    orange: Palette["primary"];
  }

  interface PaletteOptions {
    violet?: PaletteOptions["primary"];
    orange?: PaletteOptions["primary"];
  }
}

const violetBase = "#521B93";
const violetMain = "#521B93";
export default createTheme({
  components: {
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          shrink: true,
        },
      },
    },
  },
  typography: {
    fontSize: 12,
  },
  palette: {
    primary: {
      lighter: "#686869",
      light: "#686869",
      main: "#25282A",
      dark: "#25282A",
      contrastText: "#fff",
    },
    secondary: {
      lighter: "#F2F2F2",
      light: "#CCCCCC",
      main: "#777777",
      contrastText: "#fff",
    },
    warning: {
      lighter: "#FFF8E0",
      main: "#F9B300",
      contrastText: "#fff",
    },
    success: {
      main: "#B0D400",
      lighter: "#0DA16C",
      contrastText: "#fff",
    },
    error: {
      lighter: "#F9DFD9",
      main: "#D42600",
      contrastText: "#fff",
    },
    text: {
      primary: "#686869",
      secondary: "#232B3A",
    },
    common: {
      black: "#25282A",
      white: "#FFFFFF",
    },
    violet: {
      main: "#521B93",
      light: "#521B93",
      dark: "#521B93",
      // contrastText: "#521B93",
    },
    orange: {
      main: "#FC8D00FF",
      light: "#FC8D00FF",
      dark: "#FC8D00FF",
    },
    // background: {
    //   paper: "#F9F9F9",
    // },
  },
  shape: {
    borderRadius: 4,
  },
});
