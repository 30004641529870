import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";

import application from "./application/reducer";
import sku from "./sku/reducer";
import skuGrouping from "./skuGrouping/reducer";
import retestSkuGrouping from "./retestSkuGrouping/reducer";
import exportReporting from "./features/exportReporting/reducer";
import { exportReportApi } from "./services/exportReportApi";
import { userSurveyApi } from "./services/userSurveyApi";
import { rtkQueryErrorMiddleware } from "./rtk-query-error-middleware";

const store = configureStore({
  devTools: process.env.NODE_ENV === "development",
  reducer: {
    [exportReportApi.reducerPath]: exportReportApi.reducer,
    [userSurveyApi.reducerPath]: userSurveyApi.reducer,
    //* insert reducer here *//
    application,
    sku,
    skuGrouping,
    retestSkuGrouping,
    exportReporting,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: true }).concat([
      rtkQueryErrorMiddleware,
      exportReportApi.middleware,
      userSurveyApi.middleware,
    ]),
});

setupListeners(store.dispatch);

export default store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
