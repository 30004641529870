import React from "react";
import { styled } from "@mui/material/styles";

import IconButton, { IconButtonProps } from "@mui/material/IconButton";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
  icon?: React.ReactElement;
}

const ExpandMore = styled(({ expand, icon, ...other }: ExpandMoreProps) => {
  return <IconButton {...other}>{icon || <ExpandMoreIcon />}</IconButton>;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default ExpandMore;
