/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import { Container, ContainerProps, AppBarProps } from "@mui/material";

import AppBar from "components/core/AppBar";

export interface BasicSubHeaderProps {
  sx?: AppBarProps["sx"];
  containerProps?: ContainerProps;
  className?: string;
  containerClassName?: string;
  title?: string;
  divider?: boolean;
  boxShadow?: boolean;
  containerDisabled?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

export default function BasicSubHeader({
  className,
  containerProps,
  containerClassName,
  title,
  divider = false,
  boxShadow = false,
  containerDisabled = false,
  children,
  ...props
}: BasicSubHeaderProps) {
  const classes = useStyles();

  return (
    <AppBar
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      className={classnames(classes.root, className, {
        [classes.appBarBoxShadow]: boxShadow,
        [classes.boxShadowNone]: !boxShadow,
        [classes.divider]: divider,
      })}
      color="primary"
      position="sticky"
    >
      {!containerDisabled ? (
        <Container
          {...containerProps}
          maxWidth="xl"
          className={classnames(classes.container, containerClassName)}
        >
          {title && <li className={classes.tabHeading}>{title}</li>}

          {children}
        </Container>
      ) : (
        <>
          {title && <li className={classes.tabHeading}>{title}</li>}

          {children}
        </>
      )}
    </AppBar>
  );
}

const useStyles = makeStyles({
  divider: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
  },
  appBarBoxShadow: {
    boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.1)",
  },
  boxShadowNone: {
    boxShadow: "none",
  },
  root: {
    minHeight: "80px",
    color: "#000000  !important",
    backgroundColor: "#FFFFFF !important",
    padding: "12px 0",
  },
  container: {
    display: "flex !important",
    // justifyContent: "space-between",
    // alignItems: "center",
    margin: "auto",
    maxWidth: "100% !important",
  },
  tabHeading: {
    fontSize: "16px",
    flex: "0 0 auto",
    marginRight: "32px",
    fontWeight: 500,
    listStyleType: "none",
  },
  menu: {
    marginTop: "16px",
    li: {
      letterSpacing: "0.44px",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "24px",
      a: {
        color: "#232b3a !important",
      },
    },
  },
});
