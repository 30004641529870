import * as React from "react";
import classnames from "classnames";
import {
  styled,
  Checkbox,
  TextField,
  TextFieldProps,
  AutocompleteProps as MuiAutocompleteProps,
  FormHelperText,
  Autocomplete,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { NOT_APPLICABLE } from "constants/global";
import { AutocompleteWrapper } from "../Autocomplete";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme: Theme) => ({
  denseTag: {
    "& .MuiButtonBase-root": {
      height: "24px",

      "& .MuiChip-label": {
        padding: "0 8px",
      },

      "& .MuiSvgIcon-root": {
        fontSize: "18px",
      },
    },
  },
  autocompleteRoot: {
    minWidth: "150px",
  },
}));

export interface CheckboxesTagsOption {
  label: string | null;
  value: string | null;
  disabled?: boolean;
}

export interface CheckboxesTagsProps {
  sx?: TextFieldProps["sx"];
  placeholder?: string;
  helperText?: string | false;
  limitTags?: number;
  fullWidth?: boolean;
  loading?: boolean;
  error?: boolean;
  denseTag?: boolean;
  name?: TextFieldProps["name"];
  required?: TextFieldProps["required"];
  disabled?: TextFieldProps["disabled"];
  size?: TextFieldProps["size"];
  label?: TextFieldProps["label"];
  value: CheckboxesTagsOption[];
  options: CheckboxesTagsOption[];
  onChange: (event: any, value: CheckboxesTagsOption[]) => void;
}

export default function CheckboxesTags({
  helperText,
  placeholder = "Select one",
  limitTags = 3,
  loading = false,
  disabled = false,
  denseTag = false,
  error = false,
  size,
  value,
  label = "",
  options = [],
  onChange,
  fullWidth = false,
  ...rest
}: CheckboxesTagsProps) {
  const classes = useStyles();

  const isSelectedTag = (option: CheckboxesTagsOption) =>
    value?.some((item) => item?.value === option?.value);

  return (
    <AutocompleteWrapper fullWidth={fullWidth} error={error}>
      <Autocomplete
        {...rest}
        className={classnames(classes.autocompleteRoot, { [classes.denseTag]: denseTag })}
        multiple
        disableCloseOnSelect
        loading={loading}
        limitTags={limitTags}
        size={size}
        id="checkboxes-tags"
        options={options}
        value={value}
        getOptionLabel={(option) => option?.label || NOT_APPLICABLE}
        onChange={(event, values: CheckboxesTagsOption[]) => {
          const duplicateValues = values?.filter(
            (val: CheckboxesTagsOption, index: number, arr: CheckboxesTagsOption[]) => {
              return arr?.findIndex((curVal) => curVal?.value === val?.value) !== index;
            },
          );

          // for uncheck value
          const filteredSelectedList = values?.filter(
            (item) => !duplicateValues?.map((dup) => dup.value).includes(item.value),
          );

          onChange(event, filteredSelectedList);
        }}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Checkbox
                style={{ marginRight: 8 }}
                icon={icon}
                checkedIcon={checkedIcon}
                value={option?.value}
                checked={!!isSelectedTag(option)}
              />
              {option.label}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            disabled={disabled}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      />

      {helperText && (
        <FormHelperText
          sx={{
            marginTop: "3px",
            marginRight: "14px",
            marginBottom: 0,
            marginLeft: "14px",
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </AutocompleteWrapper>
  );
}
