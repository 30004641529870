import { createReducer } from "@reduxjs/toolkit";
import { setAllSku } from "./actions";

export interface SkuState {
  allSku: {
    currentHeader: string;
  };
}

const initialState: SkuState = {
  allSku: {
    currentHeader: "",
  },
};

export default createReducer(initialState, (builder) =>
  builder.addCase(setAllSku, (state, action) => ({
    ...state,
    allSku: {
      currentHeader: action.payload.currentHeader || "",
    },
  })),
);
