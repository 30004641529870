import { AutocompleteOption, BasicSelectOption, CheckboxesTagsOption } from "components/core";
import { SkuDeptOption, SkuVendorOption } from "hooks/useItem";
import { CommonCountry, FetchOption } from "types/global";
import { SkuSupplierInfo } from "types/item";

export function sortBy<T>(list: T[], filter: keyof T, order: "asc" | "desc" = "asc") {
  list?.sort((a, b) => {
    const isDesc = order === "desc";

    const ascOrder = a[filter] < b[filter] ? -1 : a[filter] > b[filter] ? 1 : 0;
    const descOrder = a[filter] > b[filter] ? -1 : a[filter] < b[filter] ? 1 : 0;

    return isDesc ? descOrder : ascOrder;
  });

  return list || [];
}

export const onlySpaces = (str: string) => {
  return str?.trim().length === 0;
};

export const toArray = (str: string) => {
  return str?.length ? str?.split(",") : [];
};

export const getAutocompleteValue = (formValue: string, options: AutocompleteOption[]) => {
  if (!formValue) return null;

  const result = options?.filter((item) => (item?.value as string) === formValue);

  return result?.length
    ? result[0]
    : {
        label: formValue,
        value: formValue,
      };
};

export const getCheckboxTagValues = (
  arrayStringValues: string,
  options: CheckboxesTagsOption[],
) => {
  if (!arrayStringValues) return [];

  const toArrayValues = toArray(arrayStringValues);

  return options?.reduce((acc: CheckboxesTagsOption[], cur: CheckboxesTagsOption) => {
    if (toArrayValues?.includes(cur.value as string)) {
      acc.push(cur);
    }

    return acc;
  }, []);
};

export const getFetchOptions = (dataOptions: FetchOption[]) => {
  const filtered = dataOptions?.filter((item) => item?.name);

  return (
    filtered?.map((item) => ({
      label: item?.name,
      value: item?.name,
    })) || []
  );
};

export const getFetchDeptOptions = (dataOptions: SkuDeptOption[], showNumLabel?: boolean) => {
  const filtered = dataOptions?.filter((item) => item?.deptNum && item?.deptDesc);

  const orderedOptions = sortBy(filtered, "deptNum");

  const mappedOptions =
    orderedOptions?.map((item) => {
      const labelWithNum = item?.deptNum?.toString()
        ? `${item?.deptNum} - ${item?.deptDesc}`
        : item?.deptDesc;

      return {
        label: showNumLabel ? labelWithNum : item?.deptDesc,
        value: item?.deptNum,
      };
    }) || [];

  return mappedOptions;
};

export const getFetchDeptDescOptions = (dataOptions: SkuDeptOption[]) => {
  const filtered = dataOptions?.filter((item) => item?.deptDesc);

  return (
    filtered?.map((item) => ({
      label: item?.deptDesc,
      value: item?.deptDesc,
    })) || []
  );
};

export const getFetchDeptNumOptions = (dataOptions: SkuDeptOption[], showNumLabel?: boolean) => {
  const filtered = dataOptions?.filter((item) => item?.deptNum && item?.deptDesc);

  return (
    filtered?.map((item) => {
      const labelWithNum = item?.deptNum?.toString()
        ? `${item?.deptDesc} (${item?.deptNum})`
        : item?.deptDesc;

      return {
        label: showNumLabel ? labelWithNum : item?.deptNum,
        value: item?.deptNum,
      };
    }) || []
  );
};

export const getBasicSelectOptions = (dataOptions: BasicSelectOption[]) => {
  return (
    dataOptions?.map((item) => ({
      label: item?.label,
      value: item?.value as string,
    })) || []
  );
};

export const getAutocompleteOptions = (dataOptions: AutocompleteOption[]) => {
  return (
    dataOptions?.map((item) => ({
      label: item?.label,
      value: item?.value as string,
    })) || []
  );
};

export const getDeptDesc = (dataOptions: SkuDeptOption[], selectedNum: string) => {
  return dataOptions?.filter((item) => item?.deptNum === selectedNum)[0]?.deptDesc ?? "";
};

export const getVendorDesc = (dataOptions: SkuVendorOption[], selectedNum: string) => {
  return dataOptions?.filter((item) => item?.vendorNum === selectedNum)[0]?.vendorDesc ?? "";
};

export const getVendorOptions =
  (name: keyof Pick<SkuSupplierInfo, "vendorDesc" | "vendorNum">) =>
  (dataOptions: SkuVendorOption[]) => {
    const filteredNum = dataOptions?.filter((item) => !onlySpaces(item?.vendorNum));
    const filteredDesc = dataOptions?.filter((item) => !onlySpaces(item?.vendorDesc));

    const isVendorDesc = name === "vendorDesc";

    const filtered = isVendorDesc ? filteredDesc : filteredNum;

    return filtered?.map((item) => {
      return {
        label: isVendorDesc ? item?.vendorDesc : item?.vendorNum,
        value: isVendorDesc ? item?.vendorDesc : item?.vendorNum,
      };
    });
  };

export const getFetchVendorOptions = (dataOptions: SkuVendorOption[], showNumLabel?: boolean) => {
  const orderedOptions = sortBy(dataOptions, "vendorDesc");

  return orderedOptions?.map((item) => {
    const labelWithNum = item?.vendorNum?.toString()
      ? `${item?.vendorDesc} (${item?.vendorNum})`
      : item?.vendorDesc;

    return {
      label: showNumLabel ? labelWithNum : item?.vendorDesc,
      value: item?.vendorNum,
    };
  });
};

export const getCommonCountryOptions = (
  commonCountries: CommonCountry[],
  options?: { sortname: false },
) => {
  return [...new Set(commonCountries)]?.map((item) => {
    const label = options?.sortname ? `${item?.name}` : `${item?.name} (${item?.sortname})` ?? "";

    return {
      label,
      value: item?.id,
    };
  });
};

export const getCommonStateCityOptions = (commonStatesOrCities: FetchOption[]) => {
  return [...new Set(commonStatesOrCities)]?.map((item) => {
    const labelStr = typeof item?.name === "number" ? `${item?.name}` : item?.name;

    return {
      label: labelStr ?? "",
      value: item?.id,
    };
  });
};
