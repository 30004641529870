/* eslint-disable no-nested-ternary */
import React from "react";

import { Box, useTheme } from "@mui/material";

import { BasicChip, BasicChipProps } from "components/core";

import { CHANGE_REQUEST_STATUS } from "constants/change-request";
import { NOT_APPLICABLE } from "constants/global";
import { ChangeRequestStatus } from "types/change-request";

interface ChangeRequestStatusChipProps extends BasicChipProps {
  status: ChangeRequestStatus;
}

export default function ChangeRequestStatusChip({
  fontSize = "text",
  variant = "filled",
  status,
  ...rest
}: ChangeRequestStatusChipProps) {
  const theme = useTheme();

  const handleStatusConfig = (
    changeRequestStatus: ChangeRequestStatus,
  ): {
    label: typeof CHANGE_REQUEST_STATUS[ChangeRequestStatus] | "";
    color: string;
    background: string;
  } => {
    const config: {
      label: typeof CHANGE_REQUEST_STATUS[ChangeRequestStatus] | "";
      color: string;
      background: string;
    } = {
      label: "",
      color: "",
      background: "",
    };

    switch (changeRequestStatus) {
      case ChangeRequestStatus.PendingApproval:
        config.label = CHANGE_REQUEST_STATUS.PENDING_APPROVED;
        config.color = "#6936F8";
        config.background = "#F3EFFB";
        break;

      case ChangeRequestStatus.Draft:
        config.label = CHANGE_REQUEST_STATUS.DRAFT;
        config.color = theme.palette.warning.main;
        config.background = theme.palette.warning.lighter as string;
        break;

      case ChangeRequestStatus.Active:
        config.label = CHANGE_REQUEST_STATUS.ACTIVE;
        config.color = "#0DA16C";
        config.background = "#E7F9F2";
        break;

      case ChangeRequestStatus.Approved:
        config.label = CHANGE_REQUEST_STATUS.APPROVED;
        config.color = "#0DA16C";
        config.background = "#E7F9F2";
        break;

      case ChangeRequestStatus.Published:
        config.label = CHANGE_REQUEST_STATUS.PUBLISHED;
        config.color = "#196EFF";
        config.background = "#E5EFFF";
        break;

      case ChangeRequestStatus.Returned:
        config.label = CHANGE_REQUEST_STATUS.RETURNED;
        config.color = theme.palette.error.main;
        config.background = theme.palette.error.lighter as string;
        break;

      case ChangeRequestStatus.Rejected:
        config.label = CHANGE_REQUEST_STATUS.REJECTED;
        config.color = theme.palette.secondary.main;
        config.background = "#F2F2F2";
        break;

      default:
        config.label = NOT_APPLICABLE;
        config.color = theme.palette.secondary.main;
        config.background = "#F2F2F2";
        break;
    }

    return config;
  };

  const { label, color, background } = handleStatusConfig(status);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <BasicChip
        {...rest}
        sx={{
          borderRadius: "4px",
          color,
          background,
        }}
        fontSize={fontSize}
        variant={variant}
        label={label}
      />
    </Box>
  );
}
