import { styled } from "@mui/material/styles";
import React from "react";

import { Chip, ChipProps } from "@mui/material";

const StyledChip = styled(Chip, {
  shouldForwardProp: (props) =>
    props !== "square" && props !== "text" && props !== "dense" && props !== "fontSize",
})<{
  square: boolean;
  text: boolean;
  fontSize: "info" | "text" | "title";
  dense: boolean;
}>(({ theme, square, text, dense, fontSize }) => ({
  display: "flex",
  borderRadius: square ? "4px" : "auto",
  border: text ? "none !important" : "inherit",
  borderColor: text ? "inherit !important" : "inherit",
  height: dense ? "20px" : "initial",
  fontSize: fontSize === "info" ? "10px" : fontSize === "text" ? "12px" : "16px",

  "& > *:not(svg)": {
    fontWeight: text ? 600 : "inherit",
    fontSize: fontSize === "info" ? "10px" : fontSize === "text" ? "12px" : "16px",
    padding: "4px 8px",
  },

  "& > svg": {
    fontSize: fontSize === "info" ? "10px" : fontSize === "text" ? "12px" : "16px",
  },
}));

export interface BasicChipProps extends ChipProps {
  square?: boolean;
  text?: boolean;
  dense?: boolean;
  fontSize?: "info" | "text" | "title";
}

export default function BasicChip({
  square = false,
  text = false,
  dense = false,
  fontSize = "text",
  ...rest
}: BasicChipProps) {
  return <StyledChip square={square} fontSize={fontSize} text={text} dense={dense} {...rest} />;
}
