import { Country, Order } from "./global";
import { SkuTestLevel, SkuTestLevelGroup, Task } from "./task";
import { TestLevel } from "./test-item";

export type Direction = "up" | "down" | "left" | "right";

export interface FillingActionData {
  selectedGroupIndex: number;
  editMode: boolean;
  direction: Direction;
  selectedTestLevel: TestLevel;
  selectedOptionWicNumber: string;
  selectedGroups: SkuTestLevelGroup[];
  filteredSelectedGroups: SkuTestLevelGroup[];
  skuGroupingFilters: SkuGroupingFilters;
  skuGroupMappingTable: SkuGroupMappingTable;
  primeSkuTestLevelCheckboxes: PrimeTestLevelCheckboxState;
}

export interface SkuGroupMapTestLevelId {
  taskAId: number;
  taskFId: number;
  taskLId: number;
  taskPId: number;
  taskQId: number;
  taskCId: number;
}

export interface SkuGroupMap extends Partial<SkuGroupMapTestLevelId> {
  isPrimeSku: boolean;
  taskId: number;
  wicNumber?: string;
  exportMarket?: string;
  // regular
  isANotApplicable?: boolean;
  isFNotApplicable?: boolean;
  isLNotApplicable?: boolean;
  isPNotApplicable?: boolean;
  // qc
  isQNotApplicable?: boolean;
  isCNotApplicable?: boolean;
}

export interface SkuGroupingPayload {
  brand?: string | null;
  subCategory?: string | null;
  skuGroupingComment?: string | null;
  country?: Country | null;
  skuGroupMaps: SkuGroupMap[];
}

export interface CombinedTaskByWicNumber {
  [wicNumber: string]: number[];
}

export interface ExistingSku extends Pick<Task, "id"> {
  testRequests: Task[];
}

export interface ApplicableMarketTestLevel {
  A: boolean;
  F: boolean;
  L: boolean;
  P: boolean;
  Q: boolean;
  C: boolean;
}
export interface ApplicableTestLevel {
  EU: Partial<ApplicableMarketTestLevel>;
  UK: Partial<ApplicableMarketTestLevel>;
  US: Partial<ApplicableMarketTestLevel>;
  QC: Partial<ApplicableMarketTestLevel>;
}

//* revamp@aug2023
export interface SkuGroupMappingSkuTestLevel {
  [testLevel: string]: SkuTestLevel;
}
export interface SkuGroupMappingSku {
  //* sku card detail from task
  wicNumber: string;
  colorCode: string;
  sizeCode: string;
  cwItemDescription: string;
  //* for prime task identity
  color: string;
  //* flags for sku group checking
  isCurrent: boolean;
  isExistingPrime: boolean;
  isSelectedAsPrime: boolean;
  isDeleted: boolean;
  isMultipleMarkets: boolean;
  markets: {
    [market: string]: SkuGroupMappingSkuTestLevel;
  };
  originalTask: Task;
}
export interface SkuGroupMappingTable {
  [wicNumber: string]: SkuGroupMappingSku;
}

export enum SkuGroupingStepKey {
  TestMatrix = 0,
  SelectPrimeSku = 1,
  SkuGrouping = 2,
}

export interface GroupingFilter {
  colorCode: string;
  sizeCode: string;
  sort: Order | null;
}

export type SkuGroupingFilters = {
  filters: GroupingFilter;
  keyword: string;
};

export interface GroupActionState {
  groupIndex: number | null;
  action: ("up" | "down" | "left" | "right" | "delete") | null;
  clickedTestLevelIndex: number | null;
}

export interface TestingLevelState {
  A: boolean | null;
  F: boolean | null;
  L: boolean | null;
  P: boolean | null;
  Q: boolean | null;
  C: boolean | null;
}

export interface TestLevelOption {
  label: TestLevel;
  value: TestLevel;
  disabled?: boolean;
}

export interface TaskCheckboxStateTask {
  taskId: string;
  exportMaket: Country;
  checkboxState: Partial<TestingLevelState>;
}

export interface PrimeSkuMarketCheckbox {
  exportMaket: Country;
  testLevelCheckbox: Partial<TestingLevelState>;
}

export interface PrimeTestLevelMarketCheckboxState {
  [exportMarket: string]: Partial<TestingLevelState>;
}

export interface PrimeTestLevelCheckboxState {
  [masterWicId: string]: PrimeTestLevelMarketCheckboxState;
}

export enum TestLevelOptionStatus {
  Prime = 2,
  Pim = 1,
  NotApplicable = 0,
}

//turple: [market, enum(number)]
export type SkuMarketTestLevelOptionStatus = [string, TestLevelOptionStatus];
