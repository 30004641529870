import React from "react";

import {
  TablePagination as MuiTablePagination,
  TablePaginationProps as MuiTablePaginationProps,
} from "@mui/material";

export default function TablePagination({ ...props }: MuiTablePaginationProps) {
  const labelDisplayedRows: MuiTablePaginationProps["labelDisplayedRows"] = ({
    from: rowFrom,
    to: rowTo,
    count: rowCount,
  }) => {
    return `Showing items ${rowFrom}–${rowTo} of ${
      rowCount !== -1 ? rowCount : `more than ${rowTo}`
    }`;
  };

  return <MuiTablePagination {...props} component="div" labelDisplayedRows={labelDisplayedRows} />;
}
