import React, { useState, useEffect, useMemo } from "react";
import useAxios from "axios-hooks";

import { useErrorAlert, useUserRole } from "hooks";

import API from "constants/api";

import { FilterOptions } from "types/global";
import { FilterListItem } from "types/item";
import { BUYER, SUPPLIER } from "constants/global";

export function useTaskList() {
  const { setError } = useErrorAlert();

  const { isBuyer, isSupplier } = useUserRole();

  const PATH = isBuyer ? `/${BUYER}${API.SKU}` : isSupplier ? `/${SUPPLIER}${API.SKU}` : API.SKU;

  const [{ loading: isLoading, error }, refetch] = useAxios(
    {
      url: `${PATH}/new_skus`,
      method: "POST",
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  return useMemo(
    () => ({
      isLoading,
      refetch: (payload: FilterOptions) => refetch({ data: payload }),
      error,
    }),
    [isLoading],
  );
}
