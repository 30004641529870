import React from "react";

import { BasicChip, BasicChipProps } from "components/core";

export default function DeleteSkuChip({
  label = "DELETED",
  fontSize = "text",
  ...rest
}: BasicChipProps) {
  return (
    <BasicChip
      {...rest}
      sx={{
        fontWeight: "500",
        width: "max-content",
        background: "#FF5555",
        color: "#fff",
        border: "1px solid #FF5555",
      }}
      dense
      square
      label={label}
      fontSize={fontSize}
    />
  );
}
