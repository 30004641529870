import React, { useEffect, useState } from "react";
import { BoxProps, styled } from "@mui/material";

import profileImage from "assets/images/no_image.png";

import { useDownloadFile, useDownloadFileV2 } from "hooks";

import { SkeletonWrapper } from "../index";

export default function ProductImage({
  sx,
  location,
  width = 50,
  height = 50,
  onClick,
}: {
  sx?: BoxProps["sx"];
  location: string;
  width?: number;
  height?: number;
  onClick?: () => void;
}) {
  const [image, setImage] = useState<any>();

  const { isLoading: isDownloadFileLoading, refetch: fetchDownloadFile } = useDownloadFileV2();

  useEffect(() => {
    if (location && location !== "") {
      fetchDownloadFile(location).then((data): any => {
        setImage(data.data);
      });
    }
  }, [location]);

  return (
    <SkeletonWrapper
      sx={{ margin: "10px 10px 10px 0px" }}
      variant="rectangular"
      display="float"
      width={width}
      height={height}
      loading={isDownloadFileLoading}
    >
      <Image
        sx={sx}
        // src={image ? URL.createObjectURL(image) : profileImage}
        src={image || profileImage}
        style={{ width, height, cursor: onClick && "pointer" }}
        onClick={onClick}
      />
    </SkeletonWrapper>
  );
}

const Image = styled("img")({
  objectFit: "contain",
  margin: "12px 12px 12px 0px",
});
