import React from "react";
import { styled } from "@mui/material/styles";

import Alert, { AlertProps } from "@mui/material/Alert";
import Snackbar, { SnackbarProps } from "@mui/material/Snackbar";
import Container from "@mui/material/Container";

const StyledSnackbar = styled(Snackbar)({
  width: "calc(100% - 48px)",
  zIndex: 9,
});

const DEFAULT_DURATION = 10000;

export interface BasicAppAlertProps extends AlertProps {
  autoHideDuration?: SnackbarProps["autoHideDuration"];
  severity?: AlertProps["severity"];
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
}

export default function BasicAppAlert({
  autoHideDuration = DEFAULT_DURATION,
  open = false,
  severity,
  onClose,
  children,
  ...rest
}: BasicAppAlertProps) {
  return (
    <StyledSnackbar {...rest} autoHideDuration={autoHideDuration} open={open} onClose={onClose}>
      <Container>
        <Alert
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
          severity={severity}
          onClose={onClose}
        >
          {children}
        </Alert>
      </Container>
    </StyledSnackbar>
  );
}
