import { useState } from "react";

export default function useTogglePopup() {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const openPopup = () => {
    setOpen(true);
  };

  const closePopup = () => {
    setOpen(false);
  };

  return { open, openPopup, closePopup, toggleOpen };
}
