/* eslint-disable @typescript-eslint/no-use-before-define */
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";

import { Box, Button, ButtonProps, Grid, Tab, Tabs } from "@mui/material";

import { BasicSelectOption, CheckmarkSelect, Text } from "components/core";
import BasicSubHeader from "components/core/Header/BasicSubHeader";
import SearchBar from "components/core/SearchBar";

import { useSearchBar } from "hooks";
import { SubHeaderButtonList } from "types";

export interface SelectInputListItem {
  placeholder?: string;
  disabled?: boolean;
  name: string;
  value?: string[];
  options: BasicSelectOption[];
  onChange: (value: string[]) => void;
}

export interface SubHeaderProps {
  title?: string;
  selectInputList?: SelectInputListItem[];
  buttonList?: SubHeaderButtonList[];
  searchBar?: boolean;
  searchDense?: boolean;
  searchPlaceholder?: string;
  divider?: boolean;
  boxShadow?: boolean;
  action?: boolean;
  search?: string;
  actionText?: string | React.ReactElement;
  actionVariant?: ButtonProps["variant"];
  onSearch?: (keyword: string) => void;
  onClearSearch?: () => void;
  onClickAction?: () => void;
  onClickSelectInputList?: () => void;
  children?: React.ReactElement | React.ReactChild | React.ReactChildren;
}

export default function SubHeader({
  title,
  selectInputList = [],
  buttonList = [],
  search = "",
  searchBar = false,
  searchDense = false,
  searchPlaceholder = "",
  divider = false,
  boxShadow = false,
  action = false,
  actionText = "Reset",
  actionVariant = "text",
  onSearch,
  onClearSearch,
  onClickAction,
  onClickSelectInputList,
  children,
}: SubHeaderProps) {
  const classes = useStyles();

  const { keyword, setKeyword, changeSearch, clearSearch } = useSearchBar(search);

  const handleClearSearch = () => {
    if (onClearSearch) onClearSearch();

    clearSearch();
  };

  const handleSearch = () => {
    if (onSearch) onSearch(keyword);
  };

  useEffect(() => {
    if (searchBar && !onSearch) {
      throw new Error("Missing function(s) for SearchBar in SubHeader");
    }
  }, []);

  useEffect(() => {
    setKeyword(search);
  }, [search]);

  return (
    <BasicSubHeader
      containerProps={{
        sx: {
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          flexFlow: "column",
        },
      }}
      divider={divider}
      boxShadow={boxShadow}
    >
      <Grid sx={{ display: "flex", flexFlow: "row" }} container columnSpacing={1}>
        <Grid item sx={{ flexGrow: 1 }}>
          <Grid container columnSpacing={2}>
            {title && (
              <Grid item sx={{ flex: "0 0 auto", display: "flex", alignItems: "center" }}>
                <Text
                  sx={{ display: "flex", alignItems: "center", marginRight: "8px" }}
                  bold
                  color="black"
                  size="title"
                >
                  {title}
                </Text>
              </Grid>
            )}

            {!!selectInputList?.length && (
              <Grid
                item
                sx={{
                  maxWidth: { xs: "90%", sm: "80%", md: "60%" },
                  // marginRight: "auto"
                }}
              >
                <Tabs
                  sx={{
                    ".MuiTabScrollButton-root.Mui-disabled": {
                      opacity: "0.2 !important",
                    },
                    ".Mui-selected": {
                      color: "unset",
                    },
                    ".MuiTabs-indicator": {
                      display: "none",
                    },
                  }}
                  value={0}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  aria-label="scrollable auto tabs"
                >
                  {selectInputList?.map((selectInput, index) => {
                    return (
                      <Tab
                        key={selectInput.name}
                        value={index}
                        sx={{
                          padding: "12px 4px",
                        }}
                        disableFocusRipple
                        disableRipple
                        label={
                          <CheckmarkSelect
                            sx={{
                              width: "180px",
                              "& > [role='button']": {
                                textAlign: "left",
                              },
                            }}
                            placeholder={selectInput?.placeholder}
                            name={selectInput.name}
                            value={selectInput?.value}
                            options={selectInput.options}
                            onChange={selectInput.onChange}
                            onOpen={onClickSelectInputList}
                            disabled={selectInput?.disabled}
                          />
                        }
                      />
                    );
                  })}
                </Tabs>
              </Grid>
            )}

            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                flexFlow: "row",
              }}
            >
              {searchBar && onSearch && (
                <SearchBar
                  sx={{
                    display: "flex",
                  }}
                  className={classes.searchBar}
                  subHeader
                  dense={searchDense}
                  placeholder={searchPlaceholder}
                  onChangeSearch={changeSearch}
                  onSearch={handleSearch}
                  onClear={handleClearSearch}
                  value={keyword ?? ""}
                />
              )}

              {action && (
                <Button variant={actionVariant} sx={{ marginLeft: "8px" }} onClick={onClickAction}>
                  {actionText}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid sx={{ alignSelf: "center", justifyContent: "flex-end" }} item>
          {buttonList?.length > 0 ? (
            <SelfEndBox>
              <Grid container spacing={1}>
                {buttonList?.map((item) => (
                  <Grid key={item.id} item>
                    {item.component}
                  </Grid>
                ))}
              </Grid>
            </SelfEndBox>
          ) : null}
        </Grid>
      </Grid>

      {children && (
        <Grid container>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      )}
    </BasicSubHeader>
  );
}

const SelfEndBox = styled(Box)({
  justifySelf: "flex-end",
  textAlign: "end",

  "> button": {
    height: 36,
    width: "max-content",
  },
});

const useStyles = makeStyles({
  columnFlowMenuList: {
    flexFlow: "column",
    alignItems: "flex-start",
  },
  menuListBox: {
    width: "100%",
    height: "auto",
    display: "flex",
    // alignItems: "center",
    marginRight: 8,
  },
  menu: {
    marginTop: "16px",
    li: {
      letterSpacing: "0.44px",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "24px",
      a: {
        color: "#232b3a !important",
      },
    },
  },
  headerContainer: {
    justifyContent: "flex-start !important",
  },
  searchBar: {
    boxShadow: "none !important",
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
  autoCompletext: {
    minWidth: 160,
    width: "100%",
    height: 36,

    "& .MuiAutocomplete-inputRoot": {
      padding: "1px 9px!important",
    },
  },
});
