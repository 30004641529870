/* eslint-disable @typescript-eslint/no-use-before-define */
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import React from "react";

import { Box, BoxProps, Button, IconButton, InputBase } from "@mui/material";
import Paper from "@mui/material/Paper";

import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";

export interface SearchBarProps {
  sx?: BoxProps["sx"];
  loading?: boolean;
  searchIconDisabled?: boolean;
  searchIconStart?: boolean;
  subHeader?: boolean;
  fullWidth?: boolean;
  dense?: boolean;
  className?: string;
  subHeaderClass?: string;
  placeholder?: string;
  value: string;
  onClear?: () => void;
  onSearch?: () => void;
  onChangeSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function SearchBar({
  sx,
  loading = false,
  searchIconDisabled = false,
  searchIconStart = false,
  subHeader = false,
  fullWidth = false,
  dense = false,
  className,
  subHeaderClass,
  placeholder = "Search keywords",
  value,
  onClear,
  onSearch,
  onChangeSearch,
}: SearchBarProps) {
  const classes = useStyles();

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();

      if (onSearch) onSearch();
    }
  };

  return (
    <Box sx={sx} className={classnames(classes.subHeaderWrapper, subHeaderClass)}>
      <Paper
        sx={{
          width: !fullWidth && subHeader ? "250px !important" : "100%",
          maxWidth: subHeader ? "100%" : "auto",
          borderTopRightRadius: subHeader ? 0 : "4px",
          borderBottomRightRadius: subHeader ? 0 : "4px",
          borderRightWidth: subHeader ? 0 : "1px",
        }}
        className={classnames(classes.root, className)}
      >
        <InputBase
          sx={{
            ml: searchIconStart ? 0 : 1,
            flex: 1,
            fontSize: "12px",
            order: searchIconStart || subHeader ? 2 : 1,
          }}
          placeholder={placeholder}
          inputProps={{ "aria-label": "search" }}
          onKeyUp={handleKeyUp}
          onChange={onChangeSearch}
          value={value}
          disabled={loading}
        />

        {onClear && value?.length > 0 && (
          <IconButton
            sx={{
              p: dense ? "5px" : "6px",
              order: searchIconStart || subHeader ? 2 : 1,
            }}
            aria-label="clear"
            onClick={onClear}
          >
            <ClearIcon />
          </IconButton>
        )}

        {!searchIconDisabled && (
          <IconButton
            disabled={loading || subHeader}
            sx={{
              p: dense ? "4px" : "6px",
              order: searchIconStart || subHeader ? 1 : 2,
              pointerEvents: searchIconStart || subHeader ? "none" : "auto",
              placeholder: {
                fontSize: "inherit",
              },
            }}
            aria-label="search"
            onClick={onSearch}
          >
            <SearchIcon />
          </IconButton>
        )}
      </Paper>

      {subHeader && (
        <Button
          sx={{ height: dense ? "43px" : "48px" }}
          className={classes.searchButton}
          aria-label="search"
          variant="contained"
          onClick={onSearch}
          disabled={loading}
        >
          Search
        </Button>
      )}
    </Box>
  );
}

const useStyles = makeStyles({
  subHeaderWrapper: {
    display: "flex",
    alignItems: "center",
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "36px !important",
  },
  searchButton: {
    height: "36px !important",
    borderTopLeftRadius: "0 !important",
    borderBottomLeftRadius: "0 !important",
  },
});
