import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import {
  ApplicableTestLevel,
  CombinedTaskByWicNumber,
  SkuGroupMappingTable,
  PrimeTestLevelCheckboxState,
  SkuGroupingFilters,
  FillingActionData,
} from "types/sku-group";
import { SkuTestLevelGroup, Task } from "types/task";
import {
  assignSelectedGroupTestLevel,
  convertMappingTableToSelectAsPrimeCheckboxes,
  convertMappingTableToSelectedGroups,
  removeAllSelectedPrimeInSelectedGroupTestLevel,
  sortDefaultSelectedGroup,
  updatePrimeSkuTestLevelCheckbox,
} from "services/skuGroupingService";
import { convertMappingTableToPrimeTestLevelCheckboxes } from "services/retestSkuService";
import { TestLevel } from "types/test-item";

export interface SkuGroupingState {
  skuGroupingFilters: SkuGroupingFilters;
  combinedTasksByWicNumber: CombinedTaskByWicNumber; // for combining multiple market in grouping list
  editMode: boolean;
  isDeletedGrouping: boolean; // for delete a sku in edit mode
  isQualityFlow: boolean;
  applicableTestLevel: ApplicableTestLevel | null; // applicable test level data from api
  skuGroupMappingTable: SkuGroupMappingTable; // logic checking table
  selectedGroups: SkuTestLevelGroup[]; // update base on skuGroupMappingTable changes
  filteredSelectedGroups: SkuTestLevelGroup[]; // record current filtered selected groups state for vertical filling actions
  primeSkuTestLevelCheckboxes: PrimeTestLevelCheckboxState; // update base on skuGroupMappingTable changes
  selectAsPrimeCheckboxes: Record<string, boolean>;
}

const initialState: SkuGroupingState = {
  skuGroupingFilters: {
    filters: {
      colorCode: "",
      sizeCode: "",
      sort: null,
    },
    keyword: "",
  },
  combinedTasksByWicNumber: {},
  editMode: false,
  isDeletedGrouping: false,
  isQualityFlow: false,
  applicableTestLevel: null,
  skuGroupMappingTable: {},
  selectedGroups: [],
  filteredSelectedGroups: [],
  primeSkuTestLevelCheckboxes: {},
  selectAsPrimeCheckboxes: {},
};

const retestskugroupingSlice = createSlice({
  name: "retestSkuGrouping",
  initialState,
  reducers: {
    setSkuGroupingFilters: (state, action) => ({
      ...state,
      skuGroupingFilters: action.payload,
    }),
    setCombinedTaskByWicNumber: (state, action) => ({
      ...state,
      combinedTasksByWicNumber: action.payload,
    }),
    setSelectedGroupData: (state, action: PayloadAction<SkuTestLevelGroup[]>) => ({
      ...state,
      selectedGroups: action.payload,
    }),
    setFilteredSelectedGroupData: (state, action: PayloadAction<SkuTestLevelGroup[]>) => ({
      ...state,
      filteredSelectedGroups: action.payload,
    }),
    //* set applicable test level from api
    setApplicableTestLevel: (state, action: PayloadAction<ApplicableTestLevel | null>) => ({
      ...state,
      applicableTestLevel: action.payload,
    }),
    //* set mapping table for logic checking
    setSkuGroupMappingTable: (state, action: PayloadAction<SkuGroupMappingTable>) => {
      const mappedSelectedGroups = convertMappingTableToSelectedGroups({
        isDeletedGrouping: state.isDeletedGrouping,
        editMode: state.editMode,
        skuGroupMappingTable: action.payload,
      });

      const sortedInitialSelectedGroups = sortDefaultSelectedGroup(mappedSelectedGroups);

      //* set prime sku test level checkbox here
      const initialPrimeSkuTestLevelCheckboxes = convertMappingTableToPrimeTestLevelCheckboxes({
        isQualityFlow: state.isQualityFlow,
        skuGroupMappingTable: action.payload,
      });

      return {
        ...state,
        skuGroupMappingTable: action.payload,
        selectedGroups: sortedInitialSelectedGroups,
        primeSkuTestLevelCheckboxes: initialPrimeSkuTestLevelCheckboxes,
        selectAsPrimeCheckboxes: state?.editMode
          ? convertMappingTableToSelectAsPrimeCheckboxes({ skuGroupMappingTable: action.payload })
          : state?.selectAsPrimeCheckboxes,
      };
    },
    //* set prime sku masterWicId to selected group test level
    setAssignSelectedGroupTestLevel: (
      state,
      action: PayloadAction<{
        testLevel: TestLevel;
        optionWicNumber: string;
        selectedWicNumber: string;
      }>,
    ) => {
      const isSelectedCheckboxPrimeSku = Object.keys(state.primeSkuTestLevelCheckboxes).includes(
        action.payload.selectedWicNumber,
      );
      const targetSelectedGroup = state.selectedGroups.find(
        (group) => group.filters.wicNumber === action.payload.selectedWicNumber,
      );
      const isPrimeItself =
        targetSelectedGroup?.filters.wicNumber === action.payload.optionWicNumber;

      return {
        ...state,
        selectedGroups: assignSelectedGroupTestLevel({
          testLevel: action?.payload?.testLevel,
          optionWicNumber: action?.payload?.optionWicNumber,
          selectedGroupWicNumber: action?.payload?.selectedWicNumber,
          selectedGroups: state?.selectedGroups,
        }),
        //* check prime sku test level if selected itself
        primeSkuTestLevelCheckboxes: isSelectedCheckboxPrimeSku
          ? updatePrimeSkuTestLevelCheckbox({
              testLevel: action?.payload?.testLevel,
              selectedWicNumber: action?.payload?.selectedWicNumber,
              checked: isPrimeItself,
              primeSkuTestLevelCheckboxesState: state.primeSkuTestLevelCheckboxes,
            })
          : state.primeSkuTestLevelCheckboxes,
      };
    },
    //* remove prime sku masterWicId in selected group test level
    setRemoveSelectedGroupTestLevel: (
      state,
      action: PayloadAction<{
        testLevel: TestLevel;
        selectedWicNumber: string;
      }>,
    ) => {
      const isSelectedCheckboxPrimeSku = Object.keys(state?.primeSkuTestLevelCheckboxes).includes(
        action?.payload?.selectedWicNumber,
      );

      return {
        ...state,
        selectedGroups: removeAllSelectedPrimeInSelectedGroupTestLevel({
          editMode: state?.editMode,
          testLevel: action?.payload?.testLevel,
          selectedGroupWicNumber: action?.payload?.selectedWicNumber,
          selectedGroups: state?.selectedGroups,
        }),
        //* uncheck prime sku test level if selected itself
        primeSkuTestLevelCheckboxes: isSelectedCheckboxPrimeSku
          ? updatePrimeSkuTestLevelCheckbox({
              testLevel: action?.payload?.testLevel,
              selectedWicNumber: action?.payload?.selectedWicNumber,
              checked: false,
              primeSkuTestLevelCheckboxesState: state.primeSkuTestLevelCheckboxes,
            })
          : state.primeSkuTestLevelCheckboxes,
      };
    },
  },
});

export const {
  setApplicableTestLevel,
  setAssignSelectedGroupTestLevel,
  setRemoveSelectedGroupTestLevel,
  setSelectedGroupData,
  setSkuGroupMappingTable,
  setSkuGroupingFilters,
  setFilteredSelectedGroupData,
} = retestskugroupingSlice.actions;

export default retestskugroupingSlice.reducer;
