import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import ROUTES from "constants/routes";
import { useAuth } from "hooks";

interface PrivateRouteProps {
  redirect?: string;
}

export default function PrivateRoute({ redirect = ROUTES.LOGIN }: PrivateRouteProps) {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? <Outlet /> : <Navigate to={redirect} />;
}
