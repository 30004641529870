import React, { useMemo, useState, useEffect } from "react";

import {
  Select as MuiSelect,
  MenuItem,
  OutlinedInput,
  TextFieldProps,
  FormHelperText,
  Checkbox,
  ListItemIcon,
  ListItemText,
  TextField,
  Box,
} from "@mui/material";

import { styled } from "@mui/styles";
import { BasicSelectOption } from "components/core";
import { BasicButton } from "components/core/Button";
import { useDebounce } from "../../../../hooks";

const StyledSelect = styled(MuiSelect)({
  "& .MuiSelect-select": {
    padding: "10px",
  },
});

const WrapperBox = styled(Box)({
  padding: "8px",
});

export interface CheckmarkSelectProps {
  sx?: TextFieldProps["sx"];
  className?: string;
  fullWidth?: TextFieldProps["fullWidth"];
  error?: TextFieldProps["error"];
  helperText?: TextFieldProps["helperText"];
  disabled?: boolean;
  placeholder?: string;
  label?: TextFieldProps["label"];
  name: string;
  value?: string[];
  options: BasicSelectOption[];
  onChange: (selectedValues: string[]) => void;
  onOpen?: () => void;
}

export default function CheckmarkSelect({
  label,
  className = "",
  fullWidth = false,
  error = false,
  helperText,
  placeholder,
  name,
  value = [],
  disabled = false,
  options = [],
  onChange,
  onOpen,
  ...rest
}: CheckmarkSelectProps) {
  const [searchValue, setSearchValue] = useState("");
  const [selected, setSelected] = useState<any[]>(value);
  const [open, setOpen] = useState<boolean>(false);

  const delayedSearchValue = useDebounce(searchValue);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: changeValue } = event.target;

    setSearchValue(changeValue);
  };

  const handleChange = (event: any) => {
    const { value: currentValues } = event.target;

    const hasAllInValue = value.includes("all");
    const hasAllInCurrent = currentValues.includes("all");

    if (hasAllInValue && !hasAllInCurrent) {
      // uncheck all values
      setSelected([]);
    } else if (!hasAllInValue && hasAllInCurrent) {
      // check all values
      setSelected(options?.map((item) => item.value.toString()));
    } else {
      // check 1 value except all
      const preventDuplicate = currentValues.filter((val: string, index: number, arr: string[]) => {
        return arr.findIndex((curVal) => curVal === val) === index;
      });

      setSelected(
        typeof preventDuplicate === "string" ? preventDuplicate.split(",") : preventDuplicate,
      );
    }
  };

  const handleClose = () => {
    setSelected(value);
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    if (onOpen) onOpen();
  };

  // minor option all
  const hasOptionAll = options?.filter((item) => item?.value === "all").length;

  const isAllSelected =
    options?.length > 0 &&
    selected?.length === options.length - (hasOptionAll ? 1 : 0) &&
    value.every((val: string) =>
      options.some((option) => option?.value?.toString() === val?.toString()),
    );

  const finalOptions = useMemo(() => {
    let tempOptions = options;

    if (searchValue && searchValue !== "") {
      tempOptions = options?.filter((option) => {
        const lowerCaseOptionLabel = option?.label?.toString()?.toLocaleLowerCase() ?? "";
        const lowerCaseOptionValue = option?.value?.toString()?.toLocaleLowerCase() ?? "";

        const lowerCaseSearchVal = delayedSearchValue?.toLowerCase() || "";

        return (
          lowerCaseOptionLabel?.includes(lowerCaseSearchVal) ||
          lowerCaseOptionValue?.includes(lowerCaseSearchVal)
        );
      });
    }
    return tempOptions;
  }, [delayedSearchValue, options]);

  const stopImmediatePropagation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleFilterSubmit = () => {
    setOpen(false);
    onChange(selected);
  };

  const handleFilterReset = () => {
    onChange([]);
    setSelected([]);
  };

  useEffect(() => {
    if (!value.length) return;
    setSelected(value);
  }, [value]);

  return (
    <>
      <StyledSelect
        {...rest}
        label={label}
        displayEmpty
        multiple
        MenuProps={{
          disableAutoFocusItem: true,
        }}
        open={open}
        className={className}
        disabled={disabled}
        value={selected}
        onOpen={handleOpen}
        onClose={handleClose}
        onChange={handleChange}
        input={<OutlinedInput fullWidth={fullWidth} error={error} name={name} />}
        renderValue={(renderValue: any) => {
          if (selected?.length === 0) {
            return placeholder;
          }

          const result = options
            .filter((item) => renderValue?.includes(item?.value?.toString()))
            .map((item) => item?.label)
            .join(" / ");

          return result;
        }}
      >
        <MenuItem onClickCapture={stopImmediatePropagation} onKeyDown={(e) => e.stopPropagation()}>
          <TextField
            fullWidth
            placeholder="Search filter option"
            name="search"
            autoFocus
            value={delayedSearchValue}
            onChange={handleSearchChange}
          />
        </MenuItem>
        {finalOptions?.map((option, index) => {
          return option?.value === "all" ? (
            <MenuItem autoFocus={false} key={`${option?.value}-${index}`} value={options[0]?.value}>
              <ListItemIcon>
                <Checkbox
                  checked={isAllSelected || !!selected?.length}
                  indeterminate={
                    !isAllSelected && !!selected?.length && selected?.length < options?.length
                  }
                />
              </ListItemIcon>

              <ListItemText primary={options[0]?.label || "Select All"} />
            </MenuItem>
          ) : (
            <MenuItem key={`${option?.value}-${index}`} value={option?.value?.toString()}>
              <Checkbox
                checked={isAllSelected || selected?.indexOf(option?.value?.toString()) > -1}
              />
              <ListItemText primary={option?.label} />
            </MenuItem>
          );
        })}
        <WrapperBox>
          <BasicButton
            sx={{ marginBottom: "8px" }}
            fullWidth
            variant="contained"
            onClick={handleFilterSubmit}
            disabled={!selected?.length}
          >
            Filter
          </BasicButton>

          <BasicButton
            fullWidth
            variant="outlined"
            onClick={handleFilterReset}
            disabled={!selected?.length}
          >
            Reset
          </BasicButton>
        </WrapperBox>
      </StyledSelect>

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </>
  );
}
