import React, { useEffect, useState } from "react";

import { DatePicker, DatePickerProps } from "components/core/Input";

import { EditInput, TextProps } from "components/core";
import { textOrUnknown } from "utils";

export interface EditDatePickerProps extends Omit<DatePickerProps, "onChange"> {
  loading?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  textProps?: Omit<TextProps, "children">;
  name?: DatePickerProps["name"];
  value: string;
  onSubmit: (selected: string) => void;
}

export default function EditDatePicker({
  readOnly = false,
  disabled = false,
  loading = false,
  textProps,
  value,
  onSubmit,
  ...rest
}: EditDatePickerProps) {
  const [inputValue, setInputValue] = useState(value);

  const handleClose = () => {
    setInputValue(value);
  };

  const handleChange = (date: string, isError?: boolean) => {
    setInputValue(date);
  };

  const handleSubmit = async () => {
    await onSubmit(inputValue);

    handleClose();
  };

  useEffect(() => {
    setInputValue(value);
  }, [JSON.stringify(value)]);

  return (
    <EditInput
      disabled={disabled}
      readOnly={readOnly}
      textProps={textProps}
      value={textOrUnknown(inputValue) as string}
      onSubmit={handleSubmit}
      onClose={handleClose}
      inputComponent={
        <DatePicker {...rest} value={inputValue} disabled={loading} onChange={handleChange} />
      }
    />
  );
}
