import React from "react";
import Skeleton, { SkeletonProps } from "@mui/material/Skeleton";

export interface SkeletonWrapperProps extends SkeletonProps {
  loading: boolean;
  display?: string;
  children?: any;
}

export default function SkeletonWrapper({
  loading = false,
  variant = "text",
  animation = "wave",
  height = 20,
  display = "block",
  children,
  ...props
}: SkeletonWrapperProps) {
  return (
    <span style={{ display: "inline-block" }}>
      {loading ? (
        <Skeleton
          sx={{ display }}
          variant={variant}
          animation={animation}
          height={height}
          {...props}
        />
      ) : (
        children
      )}
    </span>
  );
}
