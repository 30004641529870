import { AxiosRequestConfig } from "axios";
import useAxios from "axios-hooks";
import { useEffect, useMemo, useState } from "react";

import API from "constants/api";
import { useErrorAlert } from "hooks";
import { CommonCountry, FetchOption } from "types/global";

function useFetchCommonData<Res = any>({
  method = "GET",
  api = API.COMMON_DATA,
  id = "",
  url = "",
  manual = true,
}: {
  method?: AxiosRequestConfig["method"];
  api?: API;
  id?: string;
  url?: string;
  manual?: boolean;
}) {
  const { setError } = useErrorAlert();

  const [data, setData] = useState<Res>();

  const mappedUrl = !id && !url ? api : `${api}/${id}${url}`;

  const [{ data: fetchData, loading: isLoading, error }, refetch] = useAxios(
    {
      url: mappedUrl,
      method,
    },
    {
      manual,
    },
  );

  useEffect(() => {
    if (error) setError(error);
  }, [error]);

  useEffect(() => {
    if (fetchData?.data) setData(fetchData?.data);
  }, [fetchData]);

  return useMemo(
    () => ({
      data: data as Res,
      isLoading,
      refetch: ({ dataId }: { dataId?: number }) =>
        refetch({
          url: `${mappedUrl}/${dataId}`,
        }),
      error,
    }),
    [id, url, data, isLoading, refetch],
  );
}

export function useCountryOptions<Res = CommonCountry[]>() {
  return useFetchCommonData<Res>({ manual: false, url: "countries" });
}

export function useStateOptions<Res = FetchOption[]>() {
  return useFetchCommonData<Res>({ manual: true, url: "area/states" });
}

export function useCityOptions<Res = FetchOption[]>() {
  return useFetchCommonData<Res>({ manual: true, url: "area/cities" });
}
