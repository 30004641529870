/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
import React from "react";

import { Box, styled, useTheme } from "@mui/material";

import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

import { BasicChip, BasicChipProps } from "components/core";
import { NOT_APPLICABLE } from "constants/global";

import { CAP_REPORT_RESULT, REPORT_RESULT, TEST_REPORT_RESULT } from "constants/task";
import { CapReportStatus, ReportResult, TaskReportResult } from "types/task";

enum StatusDotSize {
  SemiSmall = "4px",
  Small = "8px",
  SemiMedium = "5px",
  Medium = "10px",
  SemiLarge = "6px",
  Large = "12px",
}

enum StatusDotTriangleSize {
  SemiSmall = "6px",
  Small = "10px",
  SemiMedium = "7.5px",
  Medium = "15px",
  SemiLarge = "8px",
  Large = "18px",
}

const StyledDot = styled(Box, {
  shouldForwardProp: (props) => props !== "status" && props !== "size" && props !== "dotReversed",
})<{
  status: TaskReportResult | CapReportStatus | ReportResult | null;
  size: BasicChipProps["fontSize"];
  dotReversed: boolean;
}>(({ theme, status, size, dotReversed }) => {
  const getStatusColor = () => {
    let statusColor = theme.palette.grey[200];

    switch (status) {
      case ReportResult.NotApplicable:
      case TaskReportResult.Pending:
      case CapReportStatus.Pending:
        statusColor = theme.palette.grey[400];
        break;

      case CapReportStatus.Uploaded:
      case TaskReportResult.ConditionalApproved:
        statusColor = theme.palette.warning.main;
        break;

      case ReportResult.InformationOnly:
      case ReportResult.Pass:
      case TaskReportResult.Approved:
      case CapReportStatus.Approved:
      case CapReportStatus.AcceptedFailResult:
        statusColor = "#0DA16C";
        break;

      case ReportResult.Fail:
      case TaskReportResult.RejectedRequestCap:
      case TaskReportResult.RejectedUpdateReport:
      case CapReportStatus.Rejected:
        statusColor = theme.palette.error.main;
        break;

      default:
        break;
    }

    return statusColor;
  };

  return {
    display: "inline-flex",
    flex: dotReversed ? 1 : 2,
    width:
      size === "info"
        ? StatusDotSize.Small
        : size === "text"
        ? StatusDotSize.Medium
        : StatusDotSize.Large,
    height:
      size === "info"
        ? StatusDotSize.Small
        : size === "text"
        ? StatusDotSize.Medium
        : StatusDotSize.Large,
    borderRadius: "100%",
    backgroundColor: getStatusColor(),
  };
});

interface TestReportStatusChipProps extends BasicChipProps {
  dot?: boolean;
  dotReversed?: boolean;
  labelDisabled?: boolean;
  labelColorDisabled?: boolean;
  arrow?: boolean;
  status: TaskReportResult | CapReportStatus | ReportResult;
}

export default function TestReportStatusChip({
  fontSize = "text",
  variant = "filled",
  dot = false,
  dotReversed = false,
  status,
  labelDisabled = false,
  labelColorDisabled = false,
  arrow = false,
  ...rest
}: TestReportStatusChipProps) {
  const theme = useTheme();

  const handleStatusConfig = (
    reportStatus: TaskReportResult | CapReportStatus | ReportResult,
  ): {
    label:
      | typeof TEST_REPORT_RESULT[TaskReportResult]
      | typeof CAP_REPORT_RESULT[CapReportStatus]
      | typeof REPORT_RESULT[ReportResult]
      | "";
    color: string;
    background: string;
  } => {
    const config: {
      label:
        | typeof TEST_REPORT_RESULT[TaskReportResult]
        | typeof CAP_REPORT_RESULT[CapReportStatus]
        | typeof REPORT_RESULT[ReportResult]
        | "";
      color: string;
      background: string;
    } = {
      label: "",
      color: "",
      background: "",
    };

    switch (reportStatus) {
      case CapReportStatus.Pending:
        config.label = CAP_REPORT_RESULT.pending_approval;
        config.color = theme.palette.secondary.main;
        config.background = theme.palette.secondary.lighter as string;
        break;

      case CapReportStatus.PendingUpload:
        config.label = CAP_REPORT_RESULT.pending_upload;
        config.color = theme.palette.secondary.main;
        config.background = theme.palette.secondary.lighter as string;
        break;

      case CapReportStatus.Uploaded:
        config.label = CAP_REPORT_RESULT.uploaded;
        config.color = theme.palette.secondary.main;
        config.background = theme.palette.secondary.lighter as string;
        break;

      case CapReportStatus.Rejected:
        config.label = CAP_REPORT_RESULT.rejected;
        config.color = theme.palette.error.main;
        config.background = theme.palette.error.lighter as string;
        break;

      case CapReportStatus.AcceptedFailResult:
        config.label = CAP_REPORT_RESULT.accepted_fail_result;
        config.color = "#0DA16C";
        config.background = "#E7F9F2";
        break;

      case CapReportStatus.Approved:
        config.label = CAP_REPORT_RESULT.approved;
        config.color = "#0DA16C";
        config.background = "#E7F9F2";
        break;

      case TaskReportResult.Approved:
        config.label = TEST_REPORT_RESULT.approved;
        config.color = "#0DA16C";
        config.background = "#E7F9F2";
        break;

      case TaskReportResult.Pending:
        config.label = TEST_REPORT_RESULT.pending_approval;
        config.color = theme.palette.secondary.main;
        config.background = theme.palette.secondary.lighter as string;
        break;

      case TaskReportResult.ConditionalApproved:
        config.label = TEST_REPORT_RESULT.concessional_approved;
        config.color = "#0DA16C";
        config.background = "#E7F9F2";
        break;

      case TaskReportResult.RejectedUpdateReport:
        config.label = TEST_REPORT_RESULT.rejected_update_report;
        config.color = theme.palette.error.main;
        config.background = theme.palette.error.lighter as string;
        break;

      case TaskReportResult.RejectedRequestCap:
        config.label = TEST_REPORT_RESULT.rejected_request_cap;
        config.color = theme.palette.error.main;
        config.background = theme.palette.error.lighter as string;
        break;

      case ReportResult.Pass:
        config.label = REPORT_RESULT.pass;
        config.color = "#0DA16C";
        config.background = "#E7F9F2";
        break;

      case ReportResult.Fail:
        config.label = REPORT_RESULT.fail;
        config.color = theme.palette.error.main;
        config.background = theme.palette.error.lighter as string;
        break;

      case ReportResult.InformationOnly:
        config.label = REPORT_RESULT.informationOnly;
        config.color = "#0DA16C";
        config.background = theme.palette.warning.lighter as string;
        break;

      case ReportResult.NotApplicable:
        config.label = REPORT_RESULT.notApplicable;
        config.color = theme.palette.secondary.main;
        config.background = theme.palette.secondary.lighter as string;
        break;

      default:
        config.label = NOT_APPLICABLE;
        config.color = theme.palette.secondary.main;
        config.background = theme.palette.secondary.lighter as string;
        break;
    }

    return config;
  };

  const { label, color, background } = handleStatusConfig(status);

  const chipBackgorundColor = labelColorDisabled ? "unset" : background;
  const chipColor = labelColorDisabled ? "default" : color;

  const mappedLabel = arrow ? (
    <Box
      sx={{ display: "flex", flexFlow: "row", justifyContent: "flex-start", alignItems: "center" }}
    >
      {label}
      &nbsp;
      <ArrowForwardIosRoundedIcon sx={{ fontSize: "12px" }} />
    </Box>
  ) : (
    label
  );

  return (
    <Box
      sx={{
        display: labelDisabled ? "block" : "flex",
        alignItems: "center",
        cursor: arrow ? "pointer" : "auto",
      }}
    >
      {!labelDisabled && (
        <BasicChip
          {...rest}
          sx={{
            borderRadius: "4px",
            color: chipColor,
            background: chipBackgorundColor,
            cursor: arrow ? "pointer" : "auto",
          }}
          fontSize={fontSize}
          variant={variant}
          label={mappedLabel}
        />
      )}

      {dot && <StyledDot dotReversed={dotReversed} size={fontSize} status={status} />}
    </Box>
  );
}
