import { createApi } from "@reduxjs/toolkit/query/react";
import API from "constants/api";
import { axiosBaseQuery } from "../axios-base-query";

export const userSurveyApi = createApi({
  reducerPath: "userSurveyApi",
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
  }),
  tagTypes: ["getUserSurvey", "createUserSurvey"],
  endpoints: (builder) => ({
    getUserSurvey: builder.query<any, null>({
      query: () => ({
        url: API.USER_SURVEYS,
        method: "GET",
      }),
      providesTags: ["getUserSurvey"],
    }),
    createUserSurvey: builder.mutation<any, any>({
      query: (payload) => ({
        url: API.USER_SURVEYS,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["getUserSurvey"],
    }),
  }),
});

export const { useLazyGetUserSurveyQuery, useCreateUserSurveyMutation } = userSurveyApi;
