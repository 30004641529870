import Axios, { AxiosRequestConfig } from "axios";
import { configure } from "axios-hooks";
import { STORAGE_TOKEN_KEY } from "constants/global";

const injectToken = (config: AxiosRequestConfig): AxiosRequestConfig => {
  try {
    const loacalStorageToken = localStorage.getItem(STORAGE_TOKEN_KEY);

    const token: string | null = loacalStorageToken;

    if (token != null) {
      if (config.headers) config.headers.Authorization = token;
    }
    return config;
  } catch (error: any) {
    throw new Error(error);
  }
};

const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axios.interceptors.response.use((res) => {
  const TIMEOUT = 0;

  return new Promise((resolve) => setTimeout(() => resolve(res), TIMEOUT));
});

axios.interceptors.request.use(injectToken, (error) => {
  return Promise.reject(error);
});

export function axiosAuthorizationConfig(token: string): void {
  axios.defaults.headers.common.Authorization = token;
}

export default function axiosHooksInitializer(token?: string): void {
  if (token) {
    axiosAuthorizationConfig(token);
  }

  configure({ axios });
}
