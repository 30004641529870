import React from "react";
import { styled } from "@mui/material/styles";

import {
  IconButton,
  ButtonProps,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogProps,
  Box,
} from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

import { BasicButton, Text } from "components/core";

const PopupDialogTitle = styled(DialogTitle, {
  shouldForwardProp: (props) => props !== "titleError",
})<{
  titleError: boolean;
}>(({ theme, titleError }) => ({
  fontSize: "1rem",
  fontWeight: "bold",
  color: titleError ? theme.palette.error.main : "#000",
}));

export interface PopupProps extends Partial<Omit<DialogProps, "title">> {
  dividers?: boolean;
  titleActionEnabled?: boolean;
  closeIconEnabled?: boolean;
  actionsDisabled?: boolean;
  confirmActionOnly?: boolean;
  submitDisabled?: boolean;
  disabled?: boolean;
  loading?: boolean;
  titleDisabled?: boolean;
  titleError?: boolean;
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  header?: string | React.ReactNode;
  cancelText?: string;
  submitText?: string;
  cancelVariant?: ButtonProps["variant"];
  submitVariant?: ButtonProps["variant"];
  titleAction?: React.ReactElement;
  open: boolean;
  customStyles?: any;
  onIconClose?: () => void;
  onClose?: () => void;
  onSubmit?: () => void;
  children: React.ReactNode;
}

export default function Popup({
  dividers = false,
  titleActionEnabled = false,
  closeIconEnabled = false,
  actionsDisabled = false,
  confirmActionOnly = false,
  submitDisabled = false,
  disabled = false,
  loading = false,
  titleDisabled = false,
  titleError = false,
  title,
  subtitle,
  header,
  cancelVariant = "outlined",
  submitVariant = "contained",
  cancelText = "Cancel",
  submitText = "Confirm",
  titleAction,
  open,
  customStyles = {},
  onIconClose,
  onClose,
  onSubmit,
  children,
  ...rest
}: PopupProps) {
  return (
    <Dialog
      {...rest}
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
        },
        ".MuiDialogContent-root": {
          // paddingBottom: 0,
          paddingTop: "20px",
          paddingBottom: "20px",
        },
      }}
      open={open}
    >
      {!titleDisabled && (
        <PopupDialogTitle
          sx={{
            display: "flex",
            // alignItems: "center",
            flexFlow: "column",
          }}
          titleError={titleError}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: !title ? "flex-end" : "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", flexFlow: "row", alignItems: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                {title || ""}

                {subtitle && subtitle}
              </Box>

              {titleActionEnabled && titleAction}
            </Box>

            {closeIconEnabled && (
              <IconButton
                onClick={() => (onIconClose ? onIconClose() : onClose ? onClose() : undefined)}
              >
                <ClearOutlinedIcon />
              </IconButton>
            )}
          </Box>

          {header}
        </PopupDialogTitle>
      )}

      <DialogContent
        sx={{ paddingBottom: 0, ...(customStyles?.content || {}) }}
        dividers={dividers}
      >
        {children}
      </DialogContent>

      {!actionsDisabled && (
        <DialogActions>
          {!confirmActionOnly && (
            <BasicButton
              autoFocus
              variant={cancelVariant}
              sx={{ mr: 2 }}
              disabled={disabled}
              onClick={onClose ? () => onClose() : undefined}
            >
              {cancelText}
            </BasicButton>
          )}

          <BasicButton
            variant={submitVariant}
            loading={loading}
            disabled={submitDisabled || disabled}
            onClick={onSubmit ? () => onSubmit() : undefined}
          >
            {submitText}
          </BasicButton>
        </DialogActions>
      )}
    </Dialog>
  );
}
