/* eslint-disable no-nested-ternary */
import React from "react";
import { styled } from "@mui/material/styles";

import { Box, BoxProps } from "@mui/material";

const StyledText = styled(Box, {
  shouldForwardProp: (props) =>
    props !== "size" &&
    props !== "bold" &&
    props !== "italic" &&
    props !== "color" &&
    props !== "align" &&
    props !== "strikeThrough" &&
    props !== "highlight",
})<{
  size: "info" | "text" | "subtitle" | "title" | "heading1" | "heading2";
  bold: boolean;
  italic: boolean;
  strikeThrough: boolean;
  highlight: "success" | "initial";
  color: "gray" | "black" | "white" | "error" | "primary" | "success" | "disabled" | "initial";
  align: "left" | "center" | "right" | "initial";
}>(({ theme, size, bold, italic, color, align, strikeThrough, highlight }) => ({
  color:
    color === "error"
      ? theme.palette.error.main
      : color === "black"
      ? theme.palette.text.secondary
      : color === "white"
      ? theme.palette.common.white
      : color === "primary"
      ? theme.palette.primary.main
      : color === "success"
      ? // ? theme.palette.success.main
        "#0DA16C"
      : color === "disabled"
      ? "rgba(0, 0, 0, 0.26)"
      : color === "initial"
      ? "initial"
      : theme.palette.text.primary,
  background: highlight === "success" ? "#E7F9F2" : "initial",
  padding: highlight !== "initial" ? "2px" : "",
  borderRadius: highlight !== "initial" ? "2px" : "",
  fontSize:
    size === "info"
      ? "10px"
      : size === "text"
      ? "12px"
      : size === "subtitle"
      ? "14px"
      : size === "title"
      ? "16px"
      : size === "heading2"
      ? "22px"
      : "24px",
  fontWeight: bold ? "bold" : "normal",
  fontStyle: italic ? "italic" : "normal",
  textAlign: align,
  textDecoration: strikeThrough ? "line-through" : "initial",
}));

export interface TextProps extends BoxProps {
  children: React.ReactElement | React.ReactNode;
  size?: "info" | "text" | "subtitle" | "title" | "heading1" | "heading2";
  color?: "gray" | "black" | "white" | "error" | "primary" | "success" | "disabled" | "initial";
  align?: "left" | "center" | "right" | "initial";
  bold?: boolean;
  italic?: boolean;
  strikeThrough?: boolean;
  highlight?: "success" | "initial";
}

export default function Text({
  children,
  size = "text",
  color = "gray",
  align = "initial",
  bold = false,
  italic = false,
  strikeThrough = false,
  highlight = "initial",
  ...rest
}: TextProps) {
  return (
    <StyledText
      highlight={highlight}
      strikeThrough={strikeThrough}
      size={size}
      color={color}
      bold={bold}
      italic={italic}
      align={align}
      {...rest}
    >
      {children}
    </StyledText>
  );
}
