import React from "react";

import { IconButton, IconButtonProps } from "@mui/material";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface ArrowIconButtonProps extends IconButtonProps {
  show: boolean;
}

export default function ArrowIconButton({ show = false, ...rest }: ArrowIconButtonProps) {
  return (
    <IconButton {...rest}>{show ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
  );
}
