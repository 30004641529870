import type { BaseQueryFn } from "@reduxjs/toolkit/query";
import axios from "axios";
import type { AxiosRequestConfig, AxiosError } from "axios";
import { STORAGE_TOKEN_KEY } from "constants/global";
// import { useSuccessAlert } from "hooks";

const sleep: any = (ms: number, x: any) => {
  return new Promise((resolve) => setTimeout(() => resolve(x), ms));
};

export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: "" },
  ): BaseQueryFn<
    {
      url?: string;
      method?: AxiosRequestConfig["method"] | "GET";
      body?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
      delay?: boolean;
      successAlertEnabled?: boolean;
      successMessage?: string;
    },
    unknown,
    unknown
  > =>
  async ({
    url,
    method,
    body,
    params,
    delay = false,
    successAlertEnabled = false,
    successMessage = "Submitted successfully.",
  }) => {
    // const { setSuccess } = useSuccessAlert();

    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data: body,
        params,
        headers: {
          Authorization: window.localStorage.getItem(STORAGE_TOKEN_KEY)!,
        },
      });

      // if (successAlertEnabled && result?.data?.code === 200) setSuccess(successMessage);

      return delay ? sleep(500, result.data) : result.data;
    } catch (axiosError) {
      const err = axiosError as AxiosError;

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
