import React from "react";
import { CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";

export const FullPage = styled("div")({
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export default function FullPageLoading() {
  return (
    <FullPage>
      <CircularProgress />
    </FullPage>
  );
}
