import React from "react";

import { BasicAppAlert, BasicAppAlertProps } from "components/core";

export interface SuccessAppAlertProps extends BasicAppAlertProps {
  open: boolean;
  message: string;
  onClose: () => void;
}

export default function SuccessAppAlert({
  open = false,
  message,
  onClose,
  ...rest
}: SuccessAppAlertProps) {
  return (
    <BasicAppAlert
      sx={{ zIndex: 919999 }}
      {...rest}
      open={open}
      onClose={onClose}
      severity="success"
    >
      {message}
    </BasicAppAlert>
  );
}
