/* eslint-disable no-nested-ternary */
import React from "react";

import { Box, styled, useTheme } from "@mui/material";

import { BasicChip, BasicChipProps } from "components/core";

import { NOT_APPLICABLE } from "constants/global";
import { ChangeType } from "types/change-request";

interface Config {
  label: string;
  color: string;
  background: string;
}

interface ChangeRequestActionTypeChipProps extends BasicChipProps {
  changeType: ChangeType;
}

export default function ChangeRequestActionTypeChip({
  fontSize = "text",
  variant = "filled",
  changeType,
  ...rest
}: ChangeRequestActionTypeChipProps) {
  const theme = useTheme();

  const handleConfig = (requestType: ChangeType): Config => {
    const config: Config = {
      label: "",
      color: "",
      background: "",
    };

    switch (requestType) {
      case ChangeType.CreateTestItem:
      case ChangeType.CreateMatrix:
      case ChangeType.CreateBrand:
      case ChangeType.CreateSubcategory:
        config.label = "Create Request";
        config.color = "#6936F8";
        config.background = "#F3EFFB";
        break;

      case ChangeType.EditTestItem:
      case ChangeType.EditMatrix:
      case ChangeType.EditBrand:
      case ChangeType.EditSubcategory:
        config.label = "Edit Request";
        config.color = theme.palette.warning.main;
        config.background = theme.palette.warning.lighter as string;
        break;

      case ChangeType.ArchiveTestItem:
      case ChangeType.ArchiveMatrix:
      case ChangeType.ArchiveBrand:
      case ChangeType.ArchiveSubcategory:
        config.label = "Archive Request";
        config.color = theme.palette.error.main;
        config.background = theme.palette.error.lighter as string;
        break;

      default:
        config.label = NOT_APPLICABLE;
        config.color = theme.palette.secondary.main;
        config.background = "#F2F2F2";
        break;
    }

    return config;
  };

  const { label, color, background } = handleConfig(changeType);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <BasicChip
        {...rest}
        sx={{
          borderRadius: "4px",
          color,
          background,
        }}
        fontSize={fontSize}
        variant={variant}
        label={label}
      />
    </Box>
  );
}
