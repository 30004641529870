import { REGULAR_COUNTRIES } from "constants/global";
import {
  QUALITY_TESTLEVEL_CHECKBOX_OPTIONS,
  REGULAR_TESTLEVEL_CHECKBOX_OPTIONS,
} from "constants/sku-grouping";
import _uniqueId from "lodash/uniqueId";
import _orderBy from "lodash/orderBy";
import _find from "lodash/find";
import randomColor from "randomcolor";
import { deepClone } from "utils";
import { TestLevel } from "types/test-item";
import { SkuTestLevel, CombinedTaskMarketAndTestLevel, Task } from "types/task";
import { SkuGroupMappingSku } from "types/sku-group";
import { Country } from "types/global";
import { TestingLevelStatus } from "pages/RetestSKU/components/TestingLevel";
import { TEST_LEVEL_QUALITY_LIST, TEST_LEVEL_REGULAR_LIST } from "constants/test-item";

const renderTextingLevelStatus = (status: string) => {
  switch (status) {
    case "valid":
      return TestingLevelStatus.ACTIVE;
    case "lessThan120Days":
    case "invalid":
      return TestingLevelStatus.WARNING;
    default:
      return status;
  }
};

const formatLevelData = (item: any) => {
  const { id: levelId, isPrimeSku, testLevel, remark, status, ...level } = item;
  return {
    id: levelId,
    isCheck: !level.isNotApplicable && isPrimeSku,
    disabled: level.isNotApplicable,
    level: testLevel,
    content: remark,
    status: renderTextingLevelStatus(status),
    isSelectLevel: true,
    ...level,
  };
};

const formatDetailData = (data: any, id?: any) => {
  const {
    linkedPrimes = [],
    cwItemDescription,
    vendorDesc,
    vendorNum,
    minPoCancelDate,
    retestPendingTestLevels,
    skuTestLevels,
    pos,
    ...rest
  } = data;

  return {
    id: id || _uniqueId(),
    desc: { title: rest.wicNumber, subTitle: cwItemDescription },
    testingLevel: (retestPendingTestLevels || skuTestLevels || [])
      ?.map(formatLevelData)
      .sort((a: any, b: any) => a.level.localeCompare(b.level)),
    linkedPrimes: linkedPrimes.length
      ? linkedPrimes.map((item: any) => formatDetailData(item))
      : undefined,
    testMatrix: `${rest.exportMarket} - ${rest.concept} - ${rest.brand}`,
    minPOCancelDate: minPoCancelDate,
    combinedMarketAndTestLevels: [],
    POInfo: pos.map((po: any) => ({
      poNumber: po.poNumber,
      poCancelDate: po.minPoCancelDate,
    })),
    vendor: { id: vendorNum, name: vendorDesc },
    ...rest,
  };
};

const formatPrimeData = (data: any) =>
  data.map((item: any) => {
    const {
      taskId,
      minPoCancelDate,
      vendorNum,
      vendorItemDesc,
      cwItemDescription,
      wicNumber,
      skuTestLevels,
      sizeCode,
      exportMarket,
      ...rest
    } = item;
    return {
      id: taskId,
      vendor: { id: vendorNum, name: vendorItemDesc },
      desc: { title: wicNumber, subTitle: cwItemDescription },
      minPOCancelDate: minPoCancelDate,
      testingLevel: skuTestLevels
        .map((level: any) => {
          const {
            id: levelId,
            isPrimeSku,
            testLevel,
            remark,
            isGreaterThan120Days,
            ...levelRest
          } = level;
          return {
            id: levelId,
            level: testLevel,
            content: remark,
            isCheck: isPrimeSku,
            isSelectLevel: true,
            status: isGreaterThan120Days ? "active" : "warning",
            ...levelRest,
          };
        })
        .sort((a: any, b: any) => a.level.localeCompare(b.level)),
      size: item.sizeCode,
      wicNumber: wicNumber,
      exportMarket,
      ...rest,
    };
  });

const formatToPrimeSKU = ({ data, existPrime }: any): any[] => {
  return existPrime?.length ? [data, ...existPrime] : [data];
};

const filterSkuGrouping = (selectedGroups: any[], filters: any, keyword: string) => {
  const filtered = selectedGroups.filter(
    (selected: any) =>
      [selected.colorCode, ""].includes(filters.colorCode) &&
      [selected.sizeCode, ""].includes(filters.sizeCode) &&
      selected.wicNumber?.toLowerCase().includes(keyword?.toLowerCase()),
  );

  const filteredSelectedGroups = !filters.sort
    ? _orderBy(filtered, ["isCurrent"], ["asc"])
    : _orderBy(filtered, ["wicNumber"], [filters.sort]);

  return {
    filteredSelectedGroups,
  };
};

const orderApplicableTestLevel = (arr: Partial<SkuTestLevel>[], customOrder: TestLevel[]) => {
  const orderForIndexVals = customOrder.slice(0).reverse();

  return arr.sort((a, b) => {
    const aIndex: number = -orderForIndexVals.indexOf(a?.testLevel as TestLevel);
    const bIndex: number = -orderForIndexVals.indexOf(b?.testLevel as TestLevel);
    return aIndex - bIndex;
  });
};

const mergeApplicableTestLevelsByMarket = ({
  applicableTestLevel,
  task,
}: {
  applicableTestLevel: any;
  task: any;
}): any[] => {
  const exportMarket = task?.exportMarket as Country;

  const isQC = exportMarket === Country.QC;

  const testLevelList = isQC ? TEST_LEVEL_QUALITY_LIST : TEST_LEVEL_REGULAR_LIST;

  const targetApplicableTestLevel = applicableTestLevel[exportMarket];

  const isExistingPrime = task.isPrimeSku;

  const taskTestLevels = task.skuTestLevels;

  const updated = testLevelList?.reduce(
    (acc: Partial<SkuTestLevel>[], testLevel: TestLevel, index) => {
      const isApplicableTestLevel = !targetApplicableTestLevel[
        testLevel as keyof Partial<any>
      ] as boolean;

      const targetTaskSkuTestlevel = task?.skuTestLevels?.find(
        (taskSkuTestLevel: any) => taskSkuTestLevel?.testLevel === testLevel,
      );

      // edit mode -> use isNotApplicable in existing sku test level for pen sku
      const isNotApplicable = targetTaskSkuTestlevel?.isNotApplicable;

      const taskTestLevel = taskTestLevels[index];

      // new sku does not have skuTestLevels
      if (!taskTestLevel) {
        acc.push({
          testLevel,
          isNotApplicable,
        });
      } else if (isExistingPrime) {
        acc.push(taskTestLevel);
      } else {
        acc.push({
          ...taskTestLevel,
          isNotApplicable,
        });
      }

      return orderApplicableTestLevel(acc, testLevelList);
    },
    [],
  );

  return updated as any[];
};

const groupByCombinedTaskSkuTestLevel = ({ skuTestLevels }: { skuTestLevels: SkuTestLevel[] }) => {
  const cloneSkuTestLevels = deepClone(skuTestLevels) as SkuTestLevel[];

  return cloneSkuTestLevels?.reduce((acc: any, cur) => {
    const testLevel = cur?.testLevel as string;

    if (!acc.testLevel) {
      acc[testLevel] = cur;
    }

    return acc;
  }, {});
};

const convertTasksToSkuGroupMappingTable = ({
  isDeletedGrouping,
  tasks,
  applicableTestLevel,
  testMatrix,
  selectedPrimeSkuWicNumbers,
}: {
  isDeletedGrouping: boolean;
  tasks: any[];
  applicableTestLevel: any;
  testMatrix: {
    brand: string;
    subCategory: string;
  };
  selectedPrimeSkuWicNumbers: string[];
}): any => {
  if (!tasks?.length || !testMatrix?.brand || !testMatrix?.subCategory) return {};

  const cloneTasks = deepClone(tasks) as any[];

  const mappingTable = cloneTasks.reduce((acc: any, task) => {
    const wicNumber = task.wicNumber as string;

    const currentCombinedMarketAndTestLevels = task?.combinedMarketAndTestLevels as any[];

    // get sku by market from combinedMarketAndTestLevels
    for (let i = 0; i < currentCombinedMarketAndTestLevels.length; i++) {
      //* extract default task data
      const { taskId, combinedMarketAndTestLevels, ...combinedTask } =
        currentCombinedMarketAndTestLevels[i];

      const market = combinedTask.exportMarket as string;

      //* map api applicable data
      const mappedApplicableTestLevels = mergeApplicableTestLevelsByMarket({
        applicableTestLevel,
        task: combinedTask,
      });

      const groupedTestLevel = groupByCombinedTaskSkuTestLevel({
        skuTestLevels: mappedApplicableTestLevels,
      });

      if (acc[wicNumber]) {
        //* if sku exists, assign task in markets property
        acc[wicNumber].markets[market] = groupedTestLevel;
      } else {
        //* set isSelectedAsPrime if exists & not existing prime
        const isSelectedAsPrime = !combinedTask?.isPrimeSku
          ? selectedPrimeSkuWicNumbers?.includes(combinedTask?.wicNumber as string)
          : false;

        //* if sku does not exist, set default properties
        acc[wicNumber] = {
          //* for lookup
          color: combinedTask?.isPrimeSku || isSelectedAsPrime ? randomColor() ?? "" : "",
          wicNumber: combinedTask?.wicNumber ?? "",
          colorCode: combinedTask?.colorCode ?? "",
          sizeCode: combinedTask?.sizeCode ?? "",
          cwItemDescription: combinedTask?.cwItemDescription ?? "",
          //* for checking
          isCurrent: combinedTask?.isCurrent ?? false,
          isExistingPrime: combinedTask?.isPrimeSku ?? false,
          isSelectedAsPrime: isSelectedAsPrime ?? false,
          isDeleted: !!(combinedTask?.isCurrent && isDeletedGrouping) ?? false,
          isMultipleMarkets: currentCombinedMarketAndTestLevels?.length > 1 ?? false,
          markets: {
            [market]: groupedTestLevel,
          },
          originalTask: task,
        };
      }
    }

    return acc;
  }, {});

  return mappingTable;
};

const combineGroupingListData = (listData: Task[]) => {
  const taskIdsByWicNumber: any = {};

  //* patch property combinedMarketAndTestLevels for listData
  const patchedListData =
    listData?.map((listItem) => ({
      ...listItem,
      combinedMarketAndTestLevels: [],
    })) || [];

  //* convert list data
  const list = patchedListData?.reduce((acc: Task[], cur: Task) => {
    //* check wic number if store in acc & return index
    const targetIndex = acc?.findIndex((val) => val?.wicNumber === cur?.wicNumber);

    // const masterWicId = cur.masterWicId as number;
    const wicNumber = cur.wicNumber as string;
    const taskId = cur.id as number;
    const exportMarket = cur.exportMarket as string;
    const skuTestLevels = cur.skuTestLevels as SkuTestLevel[];
    const isPrimeSku = cur?.isPrimeSku as boolean;

    const isRegularTask = REGULAR_COUNTRIES?.includes(exportMarket as Country);

    const TEST_LEVEL_LIST = isRegularTask ? TEST_LEVEL_REGULAR_LIST : TEST_LEVEL_QUALITY_LIST;

    const patchedTestLevels = TEST_LEVEL_LIST?.reduce((patchAcc: SkuTestLevel[], testLevelCur) => {
      const currentTestLevel = _find(skuTestLevels, { testLevel: testLevelCur }) as SkuTestLevel;

      const notApplicableTestLevel = {
        testLevel: testLevelCur,
        wicNumber: cur?.wicNumber,
        taskId,
        referToTaskId: taskId,
        isNotApplicable: true,
        isPrimeSku: true,
        isCompleted: false,
        isCurrent: false,
        taskNumber: null,
        taskStatus: null,
        cwItemDescription: null,
        labName: null,
      };

      patchAcc.push(currentTestLevel || notApplicableTestLevel);

      return patchAcc;
    }, []);

    const targetCombinedMarketAndTestLevels =
      (acc[targetIndex]?.combinedMarketAndTestLevels as CombinedTaskMarketAndTestLevel[]) ?? [];

    const currentMarketAndTestLevels = {
      ...cur,
      skuTestLevels: patchedTestLevels,
      taskId,
      exportMarket,
      isPrimeSku,
    };

    if (targetIndex === -1) {
      // set 1st market & test level if task hasnt stored
      cur.combinedMarketAndTestLevels = [currentMarketAndTestLevels];
      acc.push(cur);

      // set id to new wic number
      taskIdsByWicNumber[wicNumber] = [taskId];
    } else {
      // set current market & test level to existing task
      acc[targetIndex].combinedMarketAndTestLevels = [
        ...targetCombinedMarketAndTestLevels,
        currentMarketAndTestLevels,
      ];

      // set id to existing wic number
      taskIdsByWicNumber[wicNumber] = [...taskIdsByWicNumber[wicNumber], taskId];
    }

    return acc;
  }, []);

  return { list: _orderBy(list, ["isCurrent"], ["asc"]) as Task[], taskIdsByWicNumber };
};

function checkIsPrimeSku(isCurrent: boolean, isExistingPrime: boolean, isSelectedAsPrime: boolean) {
  const isPrimeSku =
    (isCurrent && isSelectedAsPrime) || (!isCurrent && isExistingPrime && isSelectedAsPrime);

  return isPrimeSku;
}

//* get default test level options for prime sku checkbox
export const getDefaultTestLevelCheckboxOptions = ({
  isQualityFlow,
}: {
  isQualityFlow: boolean;
}) => {
  return isQualityFlow ? QUALITY_TESTLEVEL_CHECKBOX_OPTIONS : REGULAR_TESTLEVEL_CHECKBOX_OPTIONS;
};

//* convert mapping table to prime checkbox state
const convertMappingTableToPrimeTestLevelCheckboxes = ({
  isQualityFlow,
  skuGroupMappingTable,
}: {
  isQualityFlow: boolean;
  skuGroupMappingTable: any;
}): any => {
  const primeSkuTestLevelCheckboxState: any = Object.entries(skuGroupMappingTable)?.reduce(
    (acc: any, mappingSku) => {
      const mappingSkuWicNumber = mappingSku?.[0] as string;

      const { markets, isCurrent, isExistingPrime, isSelectedAsPrime } = mappingSku?.[1] as any;

      const isPrimeSku = checkIsPrimeSku(isCurrent, isExistingPrime, isSelectedAsPrime);

      if (isPrimeSku) {
        const currentMappingSkuMarkets = markets as SkuGroupMappingSku["markets"];

        const DEFAULT_TEST_LEVEL_OPTIONS = getDefaultTestLevelCheckboxOptions({ isQualityFlow });

        Object.entries(currentMappingSkuMarkets)?.forEach((mappingSkuMarket) => {
          const market = mappingSkuMarket?.[0] as string;
          const skuTestLevelEntries = Object.entries(mappingSkuMarket?.[1]);

          const groupedTestLevels = DEFAULT_TEST_LEVEL_OPTIONS?.reduce(
            (grouped: Partial<any>, cur: any) => {
              const testLevel = cur?.label;

              const currentSkuTestLevel = skuTestLevelEntries?.find((stle) => {
                const entryTestLevel = stle?.[0] as TestLevel;

                return entryTestLevel === testLevel;
              })?.[1] as SkuTestLevel;

              const isNotApplicable = currentSkuTestLevel?.isNotApplicable || false;

              const isCompleted = currentSkuTestLevel.isCompleted || false;

              const isPrimeTestLevel =
                (currentSkuTestLevel?.isPrimeSku &&
                  currentSkuTestLevel?.wicNumber === mappingSkuWicNumber) ||
                false;

              const isCurrentPrimeCompletedTestLevel = isCurrent && isPrimeTestLevel && isCompleted;

              // NA test level, set Null
              // else, set TRUE:
              // selected as prime in create mode OR
              // current & prime match current wicNumber & completed OR
              // prime & match current wicNumber
              if (isNotApplicable) {
                grouped[testLevel] = null;
              } else {
                grouped[testLevel] = isCurrentPrimeCompletedTestLevel || isPrimeTestLevel;
              }

              return grouped;
            },
            {},
          );

          if (acc[mappingSkuWicNumber]) {
            acc[mappingSkuWicNumber][market] = groupedTestLevels;
          } else {
            acc[mappingSkuWicNumber] = {
              [market]: groupedTestLevels,
            };
          }
        });
      }

      return acc;
    },
    {},
  );

  return primeSkuTestLevelCheckboxState;
};

export {
  convertMappingTableToPrimeTestLevelCheckboxes,
  convertTasksToSkuGroupMappingTable,
  combineGroupingListData,
  formatLevelData,
  formatDetailData,
  formatPrimeData,
  formatToPrimeSKU,
  filterSkuGrouping,
};
