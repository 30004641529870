import { createReducer } from "@reduxjs/toolkit";
import { setError, setSuccess } from "./actions";

export interface ApplicationState {
  error: {
    code: string | number | null | undefined;
    message: string;
  };
  success: {
    message: string;
  };
}

const initialState: ApplicationState = {
  error: {
    code: 0,
    message: "",
  },
  success: {
    message: "",
  },
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setError, (state, action) => ({
      ...state,
      error: {
        code: action.payload.code || 0,
        message: action.payload.message || "",
      },
    }))
    .addCase(setSuccess, (state, action) => ({
      ...state,
      success: {
        message: action.payload.message || "",
      },
    })),
);
